import useTranslation from 'modules/localization';
import React from 'react';
import storeManager from 'store/manager';

const Strategies = () => {
    const { t } = useTranslation();

    const handleClick = () => {
        storeManager.modal.setAutomaticSystem({ visibility: true, data: null })
    }
 
    return (
        <div className="strategies" onClick={handleClick}>{t('Automatic')}</div>
    )
}

export default Strategies;