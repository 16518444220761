import storageManager from "modules/storage-manager";
import Dependencies from "shared/app/dependencies";

class OneClickTradingManager {
    private readonly _default: boolean = false;
    private readonly _storeManager;

    constructor(dependencies: Dependencies.StoreManager) {
        this._storeManager = dependencies.storeManager;

        const storageValue = this.getStorageValue();
        if (storageValue) {
            this._default = storageValue;
        }
    }

    /** Description: Returns the one click trading status. */
    get = () => {
        const oneClickTrading = this._storeManager.app.getOneClickTrading(); 
        return oneClickTrading;
    }

    /** Description: Changes the one click trading status. */
    set = (oneClickTrading: boolean) => {
        this._storeManager.app.setOneClickTrading(oneClickTrading);
        storageManager.preferences.update({ oneClickTrading });
    }

    /** Description: Changes the one click trading status as default. */
    setAsDefault = () => {
        this.set(this._default);
    }

    /** Description: Returns the stored value in local storage, if its exist. */
    private getStorageValue = () => {
        let result: boolean = false;
        
        const storageValue = storageManager.preferences.get()?.oneClickTrading;
        if (storageValue) {
            result = true;
        }

        return result;
    }
}

export default OneClickTradingManager;