import React from 'react';
import { connect } from 'react-redux';
// Components
import AccordionListItem from '../src/item';
// Types
import { IndicatorSignal } from 'store/slices/indicator-slice/types';
import { RootState } from 'store/types';
import useTranslation from 'modules/localization';
import signalHelper from 'helpers/signal-helper';

interface Props {
    data: IndicatorSignal
}

const SignalItem = (props: Props) => {
    const signal = signalHelper.parse(props.data);
    const { t } = useTranslation(); 

    const shortSignal = signal.short;
    const middleSignal = signal.middle; 
    const longSignal = signal.long; 

    return (
        <AccordionListItem id={'signal'} title={t('SignalTitle')} icon="icofont-signal">
            <table className="signal-table">
                <tbody>
                    <tr className="center-t">
                        <td>{t('Short').toUpperCase()}</td>
                        <td>{t('Middle').toUpperCase()}</td>
                        <td>{t('Long').toUpperCase()}</td>
                    </tr>
                    <tr className="center-t">
                        <td className="signal-icon"><i className={shortSignal} /></td>
                        <td className="signal-icon"><i className={middleSignal} /></td>
                        <td className="signal-icon"><i className={longSignal} /></td>
                    </tr>
                </tbody>
            </table>
        </AccordionListItem>

    )
}

const mapStateToProps = (state: RootState) => ({
    data: state.indicator.signal
})

export default connect(mapStateToProps)(SignalItem);