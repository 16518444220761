import { createSlice } from "@reduxjs/toolkit";
import orderThunk from "./middleware";
// State
import orderState from "./state";
// Types
import { OrderReducers } from "./types";

const orderSlice = createSlice({
    name: 'order',
    initialState: orderState,
    reducers: {
        setOpen(state, action) {
            state.open = action.payload            
        },
        setPending(state, action) {
            state.pending = action.payload;
        },
        setHistory(state, action) {
            state.history = action.payload;
        },
    } as OrderReducers,
    extraReducers(builder) {
        orderThunk.addCases(builder);
    },
})

export default orderSlice;