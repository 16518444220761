import { createSlice } from "@reduxjs/toolkit";
import indicatorState from "./state";
import { IndicatorReducers } from "./types";

const indicatorSlice = createSlice({
    name: 'indicator',
    initialState: indicatorState,
    reducers: {
        setLevel(state, action) {
            state.level = action.payload
        },
        setPipVario(state, action) {
            state.pipVario = action.payload
        },
        setSignal(state, action) {
            state.signal = action.payload
        },
        setTrend(state, action) {
            state.trend = action.payload
        },
        setMomentum(state, action) {
            state.momentum = action.payload
        },
        setSupportResistance(state, action) {
            state.supportResistance = action.payload
        },
    } as IndicatorReducers
})

export default indicatorSlice;