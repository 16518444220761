import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { SymbolWatchList } from 'store/slices/symbol-slice/types';
import IMAGES from 'assets/images';
import generateKey from 'shared/utils/generateKey';
import { SymbolConfigData } from 'shared/symbol/types';
import useTranslation from 'modules/localization';
import storageManager from 'modules/storage-manager';
import getAccountNo from 'shared/utils/getAccountNo';
import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';
import getPlatformCode from 'shared/utils/getPlatformCode';

interface Props {
    watchList: SymbolWatchList,
    symbolConfig: Array<SymbolConfigData>,
}

enum Tabs {
    ALL_SYMBOLS = 'all-symbols',
    WATCH_LIST = 'watch-list',
}

const SymbolListModal = (props: Props) => {
    const [sortedColumn, setSortedColumn] = React.useState<SortedColumn>(null);
    const [currentTab, setCurrentTab] = React.useState(Tabs.ALL_SYMBOLS);
    const [filter, setFilter] = React.useState('');
    const { t } = useTranslation();

    const handleClose = () => storeManager.modal.setSymbolList({ visibility: false, data: null });

    const isSymbolWatched = (symbol: string) => {
        return props.watchList.findIndex((_symbol) => _symbol === symbol) !== -1;;
    }

    const setStorageValue = (watchList: SymbolWatchList) => {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const storageData = storageManager.preferences.get()?.watchList || {};

        storageManager.preferences.update({ watchList: {
            ...storageData,
            [platformCode]: {
                ...storageData[platformCode],
                [accountNo]: watchList
            }
        }})
    }
    
    const addToWatchList = (symbol: string) => {
        const watchList = [
            ...props.watchList,
            symbol
        ];

        storeManager.symbol.setWatchList(watchList)
        setStorageValue(watchList);
    }

    const removeFromWatchList = (symbol: string) => {
        const watchList = [...props.watchList].filter((_symbol) => _symbol !== symbol);
        storeManager.symbol.setWatchList(watchList);
        setStorageValue(watchList);
    }

    const switchSymbol = (symbol: string) => {
        const isWatched = isSymbolWatched(symbol);
        if (isWatched) {
            removeFromWatchList(symbol);
        } else {
            addToWatchList(symbol);
        }
    }

    const renderConfigData = () => {
        let result: Array<SymbolConfigData> = [...props.symbolConfig];

        if (currentTab === Tabs.WATCH_LIST) {
            result = result.filter((config) => isSymbolWatched(config.symbol));
        }

        if (filter.length) {
            result = result.filter((config) => config.symbol?.toUpperCase().indexOf(filter) !== -1);
        }

        return result;
    }

    // <ColumnSortFeature>
    const data = tableColumnSortHelper.getSortedData({ sortStateGetter: sortedColumn, data: renderConfigData() || [] });

    const handleColumnClick = (key: string, type: 'number' | 'string') => tableColumnSortHelper.changeSortedColumn({ 
        columnKey: key, 
        columnType: type, 
        sortStateGetter: sortedColumn, 
        sortStateSetter: setSortedColumn 
    });

    const renderColumnSortIcon = (key: string) => tableColumnSortHelper.renderSortedColumnIconInput({
        columnKey: key,
        sortStateGetter: sortedColumn
    });
    // </ColumnSortFeature>
    
    return (
        <Modal title={t('SymbolPicker')} id="symbol-list-modal" close={handleClose}>
            <div className="head">
                <div className="tab">
                    <div className={currentTab === Tabs.ALL_SYMBOLS? 'active' : undefined} onClick={() => setCurrentTab(Tabs.ALL_SYMBOLS)}>
                        {t('AllSymbols')}
                    </div>
                    <div className={currentTab === Tabs.WATCH_LIST? 'active' : undefined} onClick={() => setCurrentTab(Tabs.WATCH_LIST)}>
                        {t('WatchList')}
                    </div>
                </div>
                <div className="controls">
                    <input type="text" placeholder={t('Search')} value={filter} onChange={(e) => setFilter(e.currentTarget.value.toUpperCase())} />
                </div>
            </div>
            <div className="content">
                <table>
                    <thead>
                        <tr>
                            <th style={{ minWidth: '80px' }}></th>
                            <th onClick={() => handleColumnClick('symbol', 'string')} style={{ minWidth: '120px' }}>{t('Symbol')} {renderColumnSortIcon('symbol')}</th>
                            <th onClick={() => handleColumnClick('typeName', 'string')} style={{ minWidth: '120px' }}>{t('Type')} {renderColumnSortIcon('typeName')}</th>
                            <th onClick={() => handleColumnClick('description', 'string')} style={{ minWidth: '200px' }}>{t('Description')} {renderColumnSortIcon('description')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((config) => {
                                const isWatched = isSymbolWatched(config.symbol);

                                return (
                                    <tr key={generateKey()}>
                                        <td onClick={() => switchSymbol(config.symbol)}>
                                            <img className="checkbox" src={isWatched? IMAGES.ELEMENTS.CHECKBOX_CHECKED : IMAGES.ELEMENTS.CHECKBOX_UNCHECKED} alt="" />
                                        </td>
                                        <td>{config.symbol}</td>
                                        <td>{config.typeName}</td>
                                        <td>{config.description}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    watchList: state.symbol.watchList,
    symbolConfig: state.symbol.config.data,
})

export default connect(mapStateToProps)(SymbolListModal);