import { createSlice } from "@reduxjs/toolkit";
import appThunk from "./middleware";
// State
import appState from "./state";
// Types
import { AppReducers } from "./types";

const appSlice = createSlice({
    name: 'app',
    initialState: appState,
    reducers: {
        setLanguage(state, action) { state.language = action.payload; },
        setLocation(state, action) { state.location = action.payload; },
        setMode(state, action) { state.mode = action.payload; },
        setTheme(state, action) { state.theme = action.payload; },
        setOneClickTrading(state, action) { state.oneClickTrading = action.payload; },
        setBottomFieldHeight(state, action) { state.bottomFieldHeight = action.payload; },
        setAppConfig(state, action) { state.config = action.payload },
    } as AppReducers,
    extraReducers(builder) {
        appThunk.addCases(builder);
    }
})

export default appSlice;