import React from 'react';

interface Props {
    message?: string,
}

const PageLoader = (props: Props) => {
    return (
        <div className={"page-loader"}>
            <div className="loader">
                <div className="inner one"></div>
                <div className="inner two"></div>
                <div className="inner three"></div>
            </div>
            
            {
                props.message &&
                    <div className="message">
                        {props.message}
                    </div>
            }
        </div>
    )
}

export default PageLoader;