import { createSlice } from "@reduxjs/toolkit";
import symbolThunk from "./middleware";
// State
import symbolState from "./state";
// Types
import { SymbolReducers } from "./types";

const symbolSlice = createSlice({
    name: 'symbol',
    initialState: symbolState,
    reducers: {
        updateData(state, action) {
            state.data = {
                ...state.data,
                [action.payload.symbol]: action.payload
            };
        },
        setWatchList(state, action) {
            state.watchList = action.payload;
        },
        setAnalysis(state, action) {
            state.analysis = action.payload;
        },
        setConfig(state, action) {
            state.config = action.payload;
        },
        setSymbolSecGroups(state, action) {
            state.secGroups = action.payload
        }
    } as SymbolReducers,
    extraReducers(builder) {
        symbolThunk.addCases(builder);
    },
})

export default symbolSlice;