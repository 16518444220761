import { appConfig } from 'app/config';
import useTranslation from 'modules/localization';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { authService } from 'services';
import storeManager from 'store/manager';
import { ApiTypes } from '../../../api';
import { RegisterInput } from '../../../api/service-proxies';
import { RegisterFormProps } from './interfaces';
import { RootState } from '../../../store/types';
import { connect } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const RegisterForm = (props: RegisterFormProps) => {

    const [confirmPassword, setConfirmPassword] = React.useState('');
    const { t, changeLanguage } = useTranslation();
 
    let useCaptchaOnRegistration: boolean = props.useCaptchaOnRegistration || false;


    const [input, setInput] = useState(new RegisterInput({
        name: '',
        surname: '',
        userName: '',
        emailAddress: '',
        password: '',
        captchaResponse: '',
        isLegalAccepted: false
    }) as any);

    const [validation, setValidation] = useState({ name: '', surname: '', userName: '', emailAddress: '', password: '', confirmPassword: '', isLegalAccepted: '' });

    const [validationSummary, setValidationSummary] = useState('');

    useEffect(() => {

        validateForm(input);

    }, [input, confirmPassword]);


    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleInputChange = (event: any) => {
        event.persist();

        if (event.target.name === 'isLegalAccepted') {

            setInput((input: any) => ({ ...input, [event.target.name]: event.target.checked }));
            setValidation(validation => ({ ...validation, [event.target.name]: '' }));
            return;
        }

        if (event.target.name === 'userName' || event.target.name === 'emailAddress' || event.target.name === 'password') {
            event.target.value = event.target.value.toString().trim();
        }

        setInput((input: any) => ({ ...input, [event.target.name]: event.target.value }));
        setValidation(validation => ({ ...validation, [event.target.name]: '' }));
    };

    const validateForm = (input: ApiTypes.RegisterInput): boolean => {

        let newMessages = {} as any;

        if (input.name.trim().length == 0) {
            newMessages.name = t('ValidationRequired', t('Firstname'));
        }
        else if (input.name.trim().length > 64) {
            newMessages.name = t('ValidationMaxLength', t('Firstname'), 64);
        }


        if (input.surname.trim().length == 0) {
            newMessages.surname = t('ValidationRequired', t('Lastname'));
        }
        else if (input.surname.trim().length > 64) {
            newMessages.surname = t('ValidationMaxLength', t('Lastname'), 64);
        }


        if (input.userName.length == 0) {
            newMessages.userName = t('ValidationRequired', t('Username'));
        }
        else if (input.userName.length > 256) {
            newMessages.userName = t('ValidationMaxLength', t('Username'), 256);
        }
        else if (input.userName.length < 6) {
            newMessages.userName = t('ValidationMinLength', t('Username'), 6);
        }

        if (input.emailAddress.length == 0) {
            newMessages.emailAddress = t('ValidationRequired', t('Email'));
        }
        else if (input.emailAddress.length > 256) {
            newMessages.emailAddress = t('ValidationMaxLength', t('Email'), 256);
        }
        else if (!/\S+@\S+\.\S+/.test(input.emailAddress)) {
            newMessages.emailAddress = t('ValidationValid', t('Email'));
        }


        if (input.password.length == 0) {
            newMessages.password = t('ValidationRequired', t('Password'));
        }
        else if (input.password.length > 32) {
            newMessages.password = t('ValidationMaxLength', t('Password'), 32);
        }
        else if (input.password.length < 6) {
            newMessages.password = t('ValidationMinLength', t('Password'), 6);
        }


        if (confirmPassword.length == 0) {
            newMessages.confirmPassword = t('ValidationRequired', t('ConfirmPassword'));
        }
        else if (input.password !== confirmPassword) {
            newMessages.confirmPassword = t('ValidationCompare', t('ConfirmPassword'));
        }

        if (!input.isLegalAccepted) {
            newMessages.isLegalAccepted = t('ValidationApproveLegalDocuments', t('ConfirmPassword'));
        }

        setValidationSummary('');
        setValidation(newMessages);

        return Object.keys(newMessages).length === 0;
    }

    /** Description: Handles the "onClick" event of the "Register" button. */
    const handleRegisterClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();

        let validateLoginResult: boolean = validateForm(input);
        if (!validateLoginResult) { return; }

        if (useCaptchaOnRegistration) {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha();
            // Do whatever you want with the token

            if (token !== undefined && token.length > 0) {
                input.captchaResponse = token;
            }
        }

        authService.register(input)
            .then((data) => {
                if (data.success) {
                    toast(t('SuccessfullyRegisteredMessage'));
                    storeManager.app.setLocation(appConfig.pages.login.path);
                }
                else {
                    setValidationSummary(data.message)
                }
            })
    }

    /** Description: Handles the "onClick" event of the "Login" button. */
    const handleLoginClick = () => {
        storeManager.app.setLocation(appConfig.pages.login.path);
    }

    React.useEffect(() => {
        const privacyPolicy = document.getElementById('privacy-policy');
        if (privacyPolicy) {
            privacyPolicy.onclick = () => window.open(appConfig.privacyPolicy);
        }

        const termOfUse = document.getElementById('terms');
        if (termOfUse) {
            termOfUse.onclick = () => window.open(appConfig.termsOfUse);
        }

    }, [props])

    return (
        <div className='register-form'>
            <h2 className="tm-title">{t('Register')}</h2>
            <form>
                <div className="form-row">
                    <div className={`form-group${validation.name ? " has-validation" : ""}`}>
                        <label htmlFor="name">{t('Firstname')}</label>
                        <div className="textbox">
                            <div className="input-group">
                                <input type="text" id="name" name="name" value={input.name} onChange={handleInputChange} placeholder={`${t('Firstname').toLowerCase()}`} />
                                <i className="icofont-ui-user"></i>
                            </div>
                            {validation.name && <div className="invalid-tooltip">
                                {validation.name}
                            </div>}
                        </div>
                    </div>
                    <div className={`form-group${validation.surname ? " has-validation" : ""}`}>
                        <label htmlFor="surname">{t('Lastname')}</label>
                        <div className="textbox">
                            <div className="input-group">
                                <input type="text" id="surname" name="surname" value={input.surname} onChange={handleInputChange} placeholder={`${t('Lastname').toLowerCase()}`} />
                                <i className="icofont-ui-user"></i>
                            </div>
                            {validation.surname && <div className="invalid-tooltip">
                                {validation.surname}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className={`form-group${validation.userName ? " has-validation" : ""}`}>
                        <label htmlFor="userName">{t('Username')}</label>
                        <div className="textbox">
                            <div className="input-group">
                                <input type="text" id="userName" name="userName" value={input.userName} onChange={handleInputChange} placeholder={`${t('Username').toLowerCase()}`} />
                                <i className="icofont-ui-email"></i>
                            </div>
                            {validation.userName && <div className="invalid-tooltip">
                                {validation.userName}
                            </div>}
                        </div>
                    </div>
                    <div className={`form-group${validation.emailAddress ? " has-validation" : ""}`}>
                        <label htmlFor="emailAddress">{t('EmailAddress')}</label>
                        <div className="textbox">
                            <div className="input-group">
                                <input type="text" id="emailAddress" name="emailAddress" value={input.emailAddress} onChange={handleInputChange} placeholder="trader@trademaster.com" />
                                <i className="icofont-ui-email"></i>
                            </div>
                            {validation.emailAddress && <div className="invalid-tooltip">
                                {validation.emailAddress}
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className={`form-group${validation.password ? " has-validation" : ""}`}>
                        <label htmlFor="password">{t('Password')}</label>
                        <div className="textbox">
                            <div className="input-group">
                                <input type="password" id="password" name="password" value={input.password} onChange={handleInputChange} placeholder='••••••••••••' autoComplete="new-password" />
                                <i className="icofont-ui-password"></i>
                            </div>
                            {validation.password && <div className="invalid-tooltip">
                                {validation.password}
                            </div>}
                        </div>
                    </div>
                    <div className={`form-group${validation.confirmPassword ? " has-validation" : ""}`}>
                        <label htmlFor="confirmPassword">{t('ConfirmPassword')}</label>
                        <div className="textbox">
                            <div className="input-group">
                                <input type="password" id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder='••••••••••••' autoComplete="new-password" />
                                <i className="icofont-ui-password"></i>
                            </div>
                            {validation.confirmPassword && <div className="invalid-tooltip">
                                {validation.confirmPassword}
                            </div>}
                        </div>
                    </div>
                </div>

                {validationSummary && <div className="form-group tm-form-val has-validation"><div className="textbox">
                    <div className="invalid-tooltip">
                        {validationSummary}
                    </div>
                </div></div>}

                <div className={`check-terms${validation.isLegalAccepted ? " has-validation" : ""}`}>
                    <div className="first-line">
                        <input type="checkbox" id="isLegalAccepted" name="isLegalAccepted" checked={input.isLegalAccepted} onChange={handleInputChange} />
                        <label className="auth-terms" htmlFor="isLegalAccepted" dangerouslySetInnerHTML={{ __html: t('TermText') }} />
                    </div>
                    <span className="tm-version-ends" dangerouslySetInnerHTML={{ __html: t('VersionEnds') }}></span>
                </div>


                <button type="submit" className="register" onClick={handleRegisterClick}>{t('CreateDemoAccount')} <i className="icofont-double-right"></i></button>
                <button type="button" className="login btn-outline" onClick={handleLoginClick}><i className="icofont-double-left"></i>{t('Close')}</button>
            </form>
            <div className="language-selector">
                <div className="language" onClick={() => changeLanguage('en')}>
                    <span>English</span>
                    <i className="flag-icon flag-icon-gb"></i>
                </div>
                |
                <div className="language" onClick={() => changeLanguage('de')}>
                    <i className="flag-icon flag-icon-de"></i>
                    <span>Deutsch</span>
                </div>
            </div>
        </div>
    )
}

export default RegisterForm;
