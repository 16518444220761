import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import { automaticDataDe, automaticDataEn } from 'shared/trade/constants';
import useTranslation from 'modules/localization';

const AutomaticSystemModal = () => {
    const [selectedSystem, setSelectedSystem] = React.useState(0);
    const { t, language } = useTranslation();
    const automaticData = language === 'en'? automaticDataEn : automaticDataDe; 

    const handleClose = () => storeManager.modal.setAutomaticSystem({ visibility: false, data: null });
    const openFactsheet = (url: string) => window.open(url, '_blank');
    const openForwardingModal = (automatic: any) => storeManager.modal.setAutomaticSystemForwarding({ visibility: true, data: automatic });
    
    return (
        <Modal title={t('AutomaticSystem')} id='automatic-system-modal' close={handleClose}>
            <div className="top-content">
                <div className="master-strategies">
                    <div className="list-title">{t('MasterStrategies')}</div>
                    {
                        automaticData.map((automatic, index) => {
                            const isSelected = selectedSystem === index;

                            return (
                                <div className={'list-item' + (isSelected? ' active' : '')} onClick={() => setSelectedSystem(index)}>
                                    <div className="list-item-content">
                                        <div className="list-item-title">
                                            {automatic.name}
                                        </div>
                                        <div className="list-item-description">
                                            {automatic.description}
                                        </div>
                                    </div>
                                    <div className="list-item-controls">
                                        <button className="factsheet" onClick={() => openFactsheet(automatic.factsheet)}>Factsheet</button>
                                        <button className="invest" onClick={() => openForwardingModal(automatic)}>Invest</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="general-information">
                    <div className="list-title">{t('GeneralInformation')}</div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieName')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].name}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieProduct')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.product}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieRiskManagement')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.riskManagement}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieRecommendedDuration')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.recommendedInvestmentPeriod}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieLiquidity')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.availability}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieMinInvestment')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.minInvestment}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieSetupFee')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.setupFee}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategiePerformanceFee')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.profitSharing}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieManagementFee')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.managementFee}</div>
                    </div>
                    <div className="list-item">
                        <div className="list-item-text">{t('StrategieFurtherFees')}</div>
                        <div className="list-item-text">{automaticData[selectedSystem].information.otherFees}</div>
                    </div>
                </div>
            </div>
            <div className="bottom-content">
                <div className="goals-and-strategies">
                    <div className="list-title">{t('GoalsAndStrategies')}</div>
                    <div className='list-content'>
                        {
                            automaticData[selectedSystem].goalStrategie.map((text) => {
                                return (
                                    <div className="list-item-text">{text}</div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="risk-information">
                    <div className="list-title">{t('RiskInformation')}</div>
                    <div className='list-content'>
                        <div className="list-item">
                            <div className="list-item-text">{t('StrategieRiskClass')}</div>
                            <div className="list-item-text">{automaticData[selectedSystem].riskInfo.class}</div>
                        </div>
                        <div className="list-item">
                            <div className="list-item-text">{t('StrategieLossTolarance')}</div>
                            <div className="list-item-text">{automaticData[selectedSystem].riskInfo.lossTolerance}</div>
                        </div>
                        <div className="list-item">
                            <div className="list-item-text">{t('StrategieRiskRating')}</div>
                            <div className="list-item-text">{automaticData[selectedSystem].riskInfo.riskRating}</div>
                        </div>
                        <div className="list-item">
                            <div className="list-item-text">{t('StrategieSuitability')}</div>
                            <div className="list-item-text">{automaticData[selectedSystem].riskInfo.suitability}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default AutomaticSystemModal;