import React from 'react';
// Components
import TradingAccounts from 'components/trading-accounts';

const TradingAccountContainer = () => {
    return (
        <div className="trading-accounts-container">
            <TradingAccounts />
        </div>
    )
}

export default TradingAccountContainer;