import Api, { ApiTypes } from "api";
import { toast } from "react-toastify";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import { DateTime } from 'luxon';
import Dependencies from "shared/app/dependencies";
import { AlarmCondition } from "api/service-proxies";
import SOUNDS from "assets/sounds";
import { ApiResponse } from "../../shared/api/constants";

class AlarmService {
    private readonly _storeManager;
    private readonly _service;

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
        this._service = new Api.AlarmsServiceProxy();
    }

    /** Description: Creates an alarm. */
    public async create() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const { name, description, symbol, price, condition, expiration } = this._storeManager.modal.getAlarmCreate().data;

        const inputDto = new Api.CreateOrEditAlarmDto({
            accountNo,
            id: undefined,
            alarmName: name,
            condition,
            description,
            expiration:  DateTime.fromJSDate(expiration),
            maxIterations: 1,
            platformCode: platformCode,
            price,
            symbol
        });

        const response: ApiResponse<ApiTypes.CreateOrEditAlarmOutput> = await this._service.createOrEdit(inputDto) as any;
        if (response.success) { 
            this._storeManager.modal.setAlarmCreate({ visibility: false, data: null });
            this.getAll();            

        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    public async edit() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const { id, alarmName, description, symbol, price, condition, expiration } = this._storeManager.modal.getAlarmModify().data;

        const inputDto = new Api.CreateOrEditAlarmDto({
            accountNo,
            id,
            alarmName,
            condition,
            description,
            expiration: DateTime.fromJSDate(expiration),
            maxIterations: 1,
            platformCode: platformCode,
            price: price,
            symbol,
        });

        const response: ApiResponse<ApiTypes.CreateOrEditAlarmOutput> = await this._service.createOrEdit(inputDto) as any;
        if (response.success) { 
            this._storeManager.modal.setAlarmModify({ visibility: false, data: null });
            this.getAll();            

        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }
    
    public async getAll() {
        let retVal: ApiTypes.AlarmDto[] = [];

        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const inputDto = new Api.GetAllAlarmsInput({
            accountNo,
            platformCode
        });

        const response: ApiResponse<ApiTypes.GetAllAlarmsOutput> = await this._service.getAll(inputDto) as any;
        if (response.success) {
            if (response.data.alarms) {
                retVal = response.data.alarms;
                this._storeManager.alarm.setData(retVal);
            }

        } else {
            toast(response.message);
        }
         
        return retVal;
    }

    public async delete() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const modalData: ApiTypes.AlarmDto = this._storeManager.modal.getAlarmDelete().data;

        const response: ApiResponse<any> = await this._service.delete(modalData.id, accountNo, platformCode) as any;
        
        if (response.success) {
            this._storeManager.modal.setAlarmDelete({ visibility: false, data: null });
            this.getAll();            

        } else {
            toast(response.message);
        }

        return Promise.resolve()
    }


    private _serviceAvailable = true;
    public checkAlarmOnStream = (streamData: { symbol: string, bid: number, ask: number }) => {
        const alarms = this._storeManager.alarm.getData();
        const { symbol } = streamData;

        alarms.forEach(async (alarm) => {
            if (alarm.symbol === symbol && this._serviceAvailable) {
                const isInLineWithCondition = this.isInLineWithCondition(streamData, alarm);
                if (isInLineWithCondition) {
                    // play the audio
                    const sound = new Audio(SOUNDS.ALARM);
                    sound.play().catch(() => {})

                    // show the notice window
                    const alarmNotices = this._storeManager.modal.getAlarmNotice().data as ApiTypes.AlarmDto[] || [];
                    this._storeManager.modal.setAlarmNotice({ visibility: true, data: [...alarmNotices, alarm] });
                    
                    // todo - delete the alarm
                    this._serviceAvailable = false;

                    const accountNo = getAccountNo();
                    const platformCode = getPlatformCode();
                    const response: ApiResponse<any> = await this._service.delete(alarm.id, accountNo, platformCode) as any;
                    if (response.success) {
                        this.getAll(); 
                        
                        
                    } else {
                        toast(response.message);
                    }

                    this._serviceAvailable = true;
                } 
            }
        });
        
    }

    private isInLineWithCondition = (streamData: { symbol: string, bid: number, ask: number }, alarmData: ApiTypes.AlarmDto) => {
        switch (alarmData.condition) {
            case AlarmCondition.BidGreatorThen:
                return streamData.bid >=  alarmData.price;
        
            case AlarmCondition.BidLessThen:
                return streamData.bid <=  alarmData.price;
                
            case AlarmCondition.AskGreaterThen:
                return streamData.ask >=  alarmData.price;
        
            case AlarmCondition.AskLessThen:
                return streamData.ask <=  alarmData.price;
        
            default:
                return false;
        }
    }

    
}

export default AlarmService;