import { createSlice } from "@reduxjs/toolkit";
// State
import chartState from "./state";
// Types
import { ChartReducers } from "./types";

const chartSlice = createSlice({
    name: 'chart',
    initialState: chartState,
    reducers: {
        setTabs(state, action) {
            state.tabs = action.payload;
        },
        setSelectedTab(state, action) {
            state.selectedTab = action.payload;
        },
        setMode(state, action) {
            state.mode = action.payload
        },
    } as ChartReducers
})

export default chartSlice;