import AccountThunkService from "./account-thunk-service";
import AppThunkService from "./app-thunk-service";
import OrderThunkService from "./order-thunk-service";

class ThunkService {
    public readonly app;
    public readonly account;
    public readonly order;

    constructor() {
        this.app = new AppThunkService();
        this.account = new AccountThunkService();
        this.order = new OrderThunkService();
    }
}

export default ThunkService;