import HubBase from "../_base";
import { IFeedHub } from "./types";
import { indicatorService, symbolService } from "services";
import { appConfig } from "app/config";

class FeedHub extends HubBase implements IFeedHub {
    constructor() {
        super(appConfig.socketServiceUrl + appConfig.hubs.feed);
    }

    // <Lifecycle>
    /** Description: Starts the FeedHub connection. */
    public start = () => {
        this.onConnectionDefine = () => {
            // Handler registrations
            this.connection?.on('receiveSymbolFeedDetail', symbolService.updateSymbolFeedData);
            this.connection?.on('receiveSymbolDetail', indicatorService.updateSymbolDetailsData);
        }

        return {
            then: (handleConnectionStart: Function) => {
                this.onConnectionStart = handleConnectionStart;
                this.connect();
            }
        };
    }

    /** Description: Updates the FeedHub subscription list.*/
    public update(subscriptionList: Array<string>) {
        if (subscriptionList !== this.subscriptions) {
            this.unsubscribe();
            this.subscriptions = subscriptionList;
            this.subscribe();
        }
    }

    /** Description: Closes the FeedHub connection. */
    public close() {
        this.disconnect();
    }
    // </Lifecycle>
}

export default FeedHub;