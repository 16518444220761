import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "api";
import { appService } from "services";
import { ApiResponse } from "../../shared/api/constants";

class AppThunkService {

    /** Description: Returns the app configurations. */
    public getAppConfig = createAsyncThunk(
        'app/fetchConfig',
        async () => {
            return new Promise(async (resolve, reject) => {
                const language = appService.language.get();
                
                const response: ApiResponse<any> = await Api.Config.getAll(language) as any;
                if (response.success) {
                    const grantedPermissions = response.data.auth.grantedPermissions;
                    const permissions = Object.keys(grantedPermissions);
    
                    const language = response.data.localization.currentCulture.name;
                    const languages = response.data.localization.languages;
                    const localization = response.data.localization.values;
                    const recaptchaSiteKey = response.data.setting.values['Recaptcha.SiteKey'];
                    const useCaptchaOnRegistration: boolean = /^true$/i.test(response.data.setting.values['App.UserManagement.UseCaptchaOnRegistration']);
                    const useCaptchaOnLogin: boolean = /^true$/i.test(response.data.setting.values['App.UserManagement.UseCaptchaOnLogin']);

                    resolve({
                        permissions,
                        language,
                        languages,
                        localization,
                        recaptchaSiteKey,
                        useCaptchaOnRegistration,
                        useCaptchaOnLogin
                    } as any)
                } else {
                    const message = response.message;
                    reject(message);
                }
            })
        }
    )
}

export default AppThunkService;