import ForgotPasswordForm from '../../../components/forms/forgot-password-form';

const ForgotPasswordContainer = () => {

    return (
        <div className="forgot-password-container">
            <ForgotPasswordForm/>
        </div>
    )
}

export default ForgotPasswordContainer;