import React from 'react';
// Modals
import OrderCreateModal from '../order-modal/create';
import OrderModifyModal from '../order-modal/modify';
import OrderCloseModal from '../order-modal/close';
import OrderDeleteModal from '../order-modal/delete';
import SymbolListModal from '../symbol-list';
import AutomaticSystemModal from '../automatic-system-modal/automatic';
import SettingsModal from '../settings-modal/settings';
import AutomaticSystemForwardingModal from '../automatic-system-modal/forwarding';
import PasswordModal from '../settings-modal/password';
import OneClickTradingDisclaimerModal from '../settings-modal/disclaimer';
import AlarmCreateModal from '../alarm-modal/create';
import AlarmModifyModal from '../alarm-modal/modify';
import AlarmDeleteModal from '../alarm-modal/delete';
import AlarmListModal from '../alarm-modal/list';
import AlarmNoticeModalContainer from '../alarm-modal/notice-container';
// Types
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { ModalContainerProps } from './types';
import EconomicCalendarModal from '../chart-modal/economic-calendar';
import TradingAccountsModal from '../trading-accounts';

const Modals = (props: ModalContainerProps) => {
    return (
        <React.Fragment>
            {props.orderCreateModalVisibility ? <OrderCreateModal /> : null}
            {props.orderModifyModalVisibility ? <OrderModifyModal /> : null}
            {props.orderCloseModalVisibility ? <OrderCloseModal /> : null}
            {props.orderDeleteModalVisibility ? <OrderDeleteModal /> : null}

            {props.symbolListModalVisibility ? <SymbolListModal /> : null}

            {props.automaticSystemModalVisibility ? <AutomaticSystemModal /> : null}
            {props.automaticSystemForwardingModalVisibility ? <AutomaticSystemForwardingModal /> : null}
            
            {props.settingsModalVisibility ? <SettingsModal /> : null}
            {props.passwordModalVisibility ? <PasswordModal /> : null}
            {props.tradingAccounts ? <TradingAccountsModal /> : null}
            {props.oneClickTradingDisclaimerModalVisibility ? <OneClickTradingDisclaimerModal /> : null}

            {props.alarmListModalVisibility ? <AlarmListModal /> : null}
            {props.alarmCreateModalVisibility ? <AlarmCreateModal /> : null}
            {props.alarmModifyModalVisibility ? <AlarmModifyModal /> : null}
            {props.alarmDeleteModalVisibility ? <AlarmDeleteModal /> : null}
            {props.alarmNoticeModalVisibility ? <AlarmNoticeModalContainer /> : null}

            {props.economicCalendarVisibility ? <EconomicCalendarModal /> : null}
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    // Order
    orderCreateModalVisibility: state.modal.orderCreate.visibility,
    orderModifyModalVisibility: state.modal.orderModify.visibility,
    orderCloseModalVisibility: state.modal.orderClose.visibility,
    orderDeleteModalVisibility: state.modal.orderDelete.visibility,
    // Symbol 
    symbolListModalVisibility: state.modal.symbolList.visibility,
    // Automatic System
    automaticSystemModalVisibility: state.modal.automaticSystem.visibility,
    automaticSystemForwardingModalVisibility: state.modal.automaticSystemForwarding.visibility,
    // Setting
    settingsModalVisibility: state.modal.settings.visibility,
    passwordModalVisibility: state.modal.password.visibility,
    tradingAccounts: state.modal.tradingAccounts.visibility,
    oneClickTradingDisclaimerModalVisibility: state.modal.oneClickTradingDisclaimer.visibility,
    // Alarm
    alarmListModalVisibility: state.modal.alarmList.visibility,
    alarmCreateModalVisibility: state.modal.alarmCreate.visibility,
    alarmModifyModalVisibility: state.modal.alarmModify.visibility,
    alarmDeleteModalVisibility: state.modal.alarmDelete.visibility,
    alarmNoticeModalVisibility: state.modal.alarmNotice.visibility,
    // Chart
    economicCalendarVisibility: state.modal.economicCalendar.visibility
})

export default connect(mapStateToProps)(Modals);
