import React from 'react';
import IMAGES from 'assets/images';
import { globalStockExchanges, MapLocations, MapStatuses } from './constants';
import stockExchangeTimeHelper from './helper';
import useTranslation from 'modules/localization';

const MAP = IMAGES.INDICATOR.MAP.ReactComponent;

const StockExchangeTimes = () => {
    const { t } = useTranslation()
    const [stockExchangeStatus, setStockExchangeStatus] = React.useState<{[property: string]: boolean}>({})
    const mapRef = React.useRef<any>();

    const newYorkData: any = globalStockExchanges.find((stockExchange) => stockExchange.micCode === 'XNYS');
    const londonData: any = globalStockExchanges.find((stockExchange) => stockExchange.micCode === 'XLON');
    const tokyoData: any = globalStockExchanges.find((stockExchange) => stockExchange.micCode === 'XJPX');

    /** Description: Gets stock exchanges status. */
    const isMarketOpen = (code: string) => {
        if (stockExchangeStatus.hasOwnProperty(code)) {
            return stockExchangeStatus[code];
        }

        return null;
    }

    /** Description: Updates all stock exchange status. */
    const updateStockExchangeStatus = () => {
        const status: any = {};

        globalStockExchanges.forEach((stockExchange) => {
            const isOpen = stockExchangeTimeHelper.isStockExchangeOpen(stockExchange.openTime, stockExchange.closeTime, stockExchange.countryCode);
            status[stockExchange.micCode] = isOpen;
        })

        setStockExchangeStatus(status); 
    } 
    
    /** Description: Clears class list of location. */
    const clearMapClassList = (location: string) => {
        if (mapRef.current) {
            const item = mapRef.current.getElementById(location);
            if (item) {
                if (item.classList.length) {
                    item.classList.forEach((className: string) => item.classList.remove(className));
                }
            }
        }
    }

    /** Description: updates default location statuses. */
    React.useEffect(() => {
        // Clear class list of items
        clearMapClassList(MapLocations.NEW_YORK);
        clearMapClassList(MapLocations.LONDON);
        clearMapClassList(MapLocations.TOKYO);
        
        // Add current status to classlist of items
        const nyStatus = isMarketOpen('XNYS') ? MapStatuses.OPEN : MapStatuses.CLOSE;
        const londonStatus = isMarketOpen('XLON') ? MapStatuses.OPEN : MapStatuses.CLOSE;
        const tokyoStatus = isMarketOpen('XJPX') ? MapStatuses.OPEN : MapStatuses.CLOSE;

        mapRef.current.getElementById(MapLocations.NEW_YORK).classList.add(nyStatus);
        mapRef.current.getElementById(MapLocations.LONDON).classList.add(londonStatus);
        mapRef.current.getElementById(MapLocations.TOKYO).classList.add(tokyoStatus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef, stockExchangeStatus])

    React.useEffect(() => {
        // Add mic codes to stock exchange status
        const setInitialValues = () => {
            const codes: any = {}
            globalStockExchanges.forEach((stockExchange) => {
                codes[stockExchange.micCode] = null;
            })
    
            setStockExchangeStatus(codes);
        } 
        
        setInitialValues();
        // Set a timer to updating stock exchange status
        updateStockExchangeStatus();
        setInterval(updateStockExchangeStatus, 5000);
    }, [])

    const getStatusClass = (status: boolean | null) => status? 'green-text': 'red-text';  
    const getStatusText = (status: boolean | null) => status !== null? (status? t('Open'): t('Close')) : '...';  

    const newYorkStatus = isMarketOpen(newYorkData.micCode);
    const londonStatus = isMarketOpen(londonData.micCode);
    const tokyoStatus = isMarketOpen(tokyoData.micCode);

    return (
        <div className="stock-exchange-times">
            <div className="map">
                <MAP ref={mapRef} />  
            </div>
            <div className="body">
                <div className="stock-exchange">
                    <div className="name">New York</div>
                    <div className={'status ' + getStatusClass(newYorkStatus)}>{getStatusText(newYorkStatus)}</div>
                </div>
                <div className="stock-exchange">
                    <div className="name">London</div>
                    <div className={'status ' + getStatusClass(londonStatus)}>{getStatusText(londonStatus)}</div>
                </div>
                <div className="stock-exchange">
                    <div className="name">Tokyo</div>
                    <div className={'status ' + getStatusClass(tokyoStatus)}>{getStatusText(tokyoStatus)}</div>
                </div>
            </div>
        </div>
    )
}

export default StockExchangeTimes;