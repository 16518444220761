import { CountryCodes, countryUtcTimes, UtcOperations } from "./constants";

class StockExchangeTimeHelper {
    /** Description: Splits the time string. e.g: '19:20' => [19, 20] */
    private splitTime = (time: string) => {
        let splitTime: Array<string> = time.split('.');
        
        if (splitTime.length) {
            const result = splitTime.map(val => parseInt(val));

            return result;
        }

        return null;
    }

    /** Description: Gets the time value and converts to the datetime. */
    private convertToDateTime = (time: string) => {
        let date = new Date();
        
        const timeValues = this.splitTime(time);
        if (timeValues) {
            const [hours, minutes] = timeValues;
            date.setHours(hours, minutes, 0, 0);
        }
        
        return date;
    }

    /** Description: Gets the time value and converts to the datetime. */
    private convertUTCTimeFormat = (timezone: CountryCodes, datetime: Date) => {
        let date = datetime;

        const hasUTCTime = countryUtcTimes.hasOwnProperty(timezone);
        if (hasUTCTime && datetime) {
            const utcTime = countryUtcTimes[timezone];
            const hours = (1000 * 60 * 60) * utcTime.value;
            
            let timestamp = datetime.getTime();
            if (utcTime.operation === UtcOperations.POSITIVE) {
                timestamp = timestamp - hours;
            } else {
                timestamp = timestamp + hours;
            }

            date = new Date(timestamp);
        }

        const result = date.getHours() + '.' +  date.getMinutes();
        return result;
    }

    /** Description: Returns the open-close local times of the stock exchange market. */
    public getOpenCloseTime = (openTime: string[], closeTime: string[]) => {
        let result = '';
        
        const arrMinLength = openTime.length > closeTime.length? closeTime.length : openTime.length;  
        
        for (let index = 0; index < arrMinLength; index++) {
            result += `${openTime[index]}-${closeTime[index]} / `;
        }
        
        // delete last ' / ' characters
        result = result.substring(0, result.length - 3)
        return result;
    }

    /** Description: Returns the open-close utc times of the stock exchange market. */
    public getUtcOpenCloseTime = (openTime: string[], closeTime: string[], timezone: CountryCodes) => {
        let result = '';

        const arrMinLength = openTime.length > closeTime.length? closeTime.length : openTime.length;  
        
        for (let index = 0; index < arrMinLength; index++) {
            const open = this.convertUTCTimeFormat(timezone, this.convertToDateTime(openTime[index]));
            const close = this.convertUTCTimeFormat(timezone, this.convertToDateTime(closeTime[index]));

            result += `${open}-${close} / `;
        }
        
        // delete last ' / ' characters
        result = result.substring(0, result.length - 3)
        return result;
    }

    /** Description: Returns the status of a stock exchange market. */
    public isStockExchangeOpen = (openTime: string[], closeTime: string[], timezone: CountryCodes) => {
        let result = false;

        const currentDateTime = new Date();
        const currentHours = currentDateTime.getUTCHours();
        const currentMinutes = currentDateTime.getUTCMinutes();

        const arrMinLength = openTime.length > closeTime.length? closeTime.length : openTime.length; 
        for (let index = 0; index < arrMinLength; index++) {
            let open = this.convertUTCTimeFormat(timezone, this.convertToDateTime(openTime[index]));
            let close = this.convertUTCTimeFormat(timezone, this.convertToDateTime(closeTime[index]));

            const openValues = this.splitTime(open);
            const closeValues = this.splitTime(close);

            if (openValues && closeValues) {
                const [openHours, openMinutes] = openValues;
                const [closeHours, closeMinutes] = closeValues;
    
                if (((currentHours === openHours && currentMinutes >= openMinutes) || (currentHours > openHours)) && ((currentHours === closeHours && currentMinutes <= closeMinutes) || (currentHours < closeHours))) {
                    // current hour = opening hour => current minut >
                    // current hour != opening hour => current hour > opening hour
                    // current hour = closing hour => current minut <
                    // current hour != closing hour => current hour < closing time
                    return true;
                } 
            }
        }

        return result;
    }
}

const stockExchangeTimeHelper = new StockExchangeTimeHelper();
export default stockExchangeTimeHelper;