import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';
import useTranslation from 'modules/localization';
import React from 'react';
import { connect } from 'react-redux';
import { orderService } from 'services';
import { OrderHistoryData } from 'shared/symbol/types';
import { Cmd } from 'shared/trade/enums';
import { RootState } from 'store/types';
import { OrderHistoryPeriods } from './constants';
import orderHistorHelper from './helper';
import OrderHistoryTableToolBox from './toolbox';
import { OrderHistoryTableProps } from './types';

const OrderHistoryTable = (props: OrderHistoryTableProps) => {
    const [sortedColumn, setSortedColumn] = React.useState<SortedColumn>(null);
    const [period, setPeriod] = React.useState(OrderHistoryPeriods.TODAY);
    const [startDate, setStartDate] = React.useState<any>('');
    const [endDate, setEndDate] = React.useState<any>('');
    const { t } = useTranslation();

    // <ColumnSortFeature>
    const orderHistory: OrderHistoryData[] = tableColumnSortHelper.getSortedData({ sortStateGetter: sortedColumn, data: props.orderHistory.data || [] });

    const handleColumnClick = (key: string, type: 'number' | 'string') => tableColumnSortHelper.changeSortedColumn({ 
        columnKey: key, 
        columnType: type, 
        sortStateGetter: sortedColumn, 
        sortStateSetter: setSortedColumn 
    });

    const renderColumnSortIcon = (key: string) => tableColumnSortHelper.renderSortedColumnIconInput({
        columnKey: key,
        sortStateGetter: sortedColumn
    });

    // </ColumnSortFeature>
        
    // <Calculations>
    const getTotal = (orderKey: keyof OrderHistoryData) => {
        let calculation = 0;

        if (orderHistory) {
            orderHistory.forEach((order) => calculation += order[orderKey] as number);
        }

        return calculation.toFixed(2);
    }
    // </Calculations>

    React.useEffect(() => {
        if (startDate?.value && endDate?.value) {
            orderService.provider.getHistory(startDate.value, endDate.value);
        }
    }, [startDate, endDate])

    React.useEffect(() => {
        const date = orderHistorHelper.getPeriodDate(period);
        if (date) {
            setStartDate(date.startDate);
            setEndDate(date.endDate);
        } 
    }, [period])

    return (
        <div className='order-history-table'>
            <OrderHistoryTableToolBox usePeriod={[period, setPeriod]} useStartDate={[startDate, setStartDate]} useEndDate={[endDate, setEndDate]} />
            <table>
                <thead>
                    <tr>
                        <th style={{ width: '25px' }} onClick={() => handleColumnClick('order', 'number')}>{t('Deal')} {renderColumnSortIcon('order')}</th>
                        <th style={{ width: '15px' }} onClick={() => handleColumnClick('cmd', 'number')}>{t('Type')} {renderColumnSortIcon('cmd')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('volume', 'number')}>{t('Volume')} {renderColumnSortIcon('volume')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('symbol', 'string')}>{t('Symbol')} {renderColumnSortIcon('symbol')}</th>
                        <th style={{ width: '40px' }} onClick={() => handleColumnClick('openTime', 'number')}>{t('Time')} {renderColumnSortIcon('openTime')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('openPrice', 'number')}>{t('Price')} {renderColumnSortIcon('openPrice')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('sl', 'number')}>{t('StopLoss')} {renderColumnSortIcon('sl')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('tp', 'number')}>{t('TakeProfit')} {renderColumnSortIcon('tp')}</th>
                        <th style={{ width: '40px' }} onClick={() => handleColumnClick('closeTime', 'number')}>{t('CloseTime')} {renderColumnSortIcon('closeTime')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('closePrice', 'number')}>{t('ClosePrice')} {renderColumnSortIcon('closePrice')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('commission', 'number')}>{t('Commission')} {renderColumnSortIcon('commission')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('swap', 'number')}>{t('Swap')} {renderColumnSortIcon('swap')}</th>
                        <th style={{ width: '20px' }} onClick={() => handleColumnClick('profit', 'number')}>{t('ProfitAndLoss')} {renderColumnSortIcon('profit')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        orderHistory?.map((history) => {
                            const digit = history.digits;

                            const deal = '#' + history.order;
                            const type = Cmd[history.cmd];
                            const size = history.volume.toFixed(2);
                            const symbol = history.symbol;
                            
                            const openTime = (history.openTime).toFormat('yyyy.LL.dd HH:mm:ss');
                            const openPrice = history.openPrice.toFixed(digit);
                            const stopLoss = history.sl.toFixed(digit);
                            const takeProfit = history.tp.toFixed(digit);
                            const closeTime = history.closeTime.toFormat('yyyy.LL.dd HH:mm:ss');
                            const closePrice = history.closePrice.toFixed(digit);
                            
                            const commission = history.commission.toFixed(2);
                            const swap = history.swap.toFixed(2);
                            const profit = history.profit.toFixed(2);

                            return (
                                <tr key={history.order}>
                                    <td>{deal}</td>
                                    <td>{type}</td>
                                    <td>{size}</td>
                                    <td>{symbol}</td>
                                    <td>{openTime}</td>
                                    <td>{openPrice}</td>
                                    <td>{stopLoss}</td>
                                    <td>{takeProfit}</td>
                                    <td>{closeTime}</td>
                                    <td>{closePrice}</td>
                                    <td>{commission}</td>
                                    <td>{swap}</td>
                                    <td>{profit}</td>
                                </tr>
                            )
                        })
                    }

                    <tr>
                        <td colSpan={9}></td>
                        <td>
                            <strong>{t('TotalOrders')}</strong>
                        </td>
                        <td>
                            <strong>{getTotal('commission')}</strong>
                        </td>
                        <td>
                            <strong>{getTotal('swap')}</strong>
                        </td>
                        <td>
                            <strong>{getTotal('profit')}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    orderHistory: state.order.history,
})

export default connect(mapStateToProps)(OrderHistoryTable);