import React from 'react';
import storeManager from 'store/manager';
import IMAGES from 'assets/images';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
// Components
import Modal from 'components/modal';
// Types
import { ModalOrderCreate } from 'store/slices/modal-slice/types';
import { OrderSides, OrderTypes } from 'shared/symbol/constants';
import useTranslation from 'modules/localization';
import { orderService } from 'services';
import { AppOneClickTrading } from 'store/slices/app-slice/types';
import getDecimalCount from 'shared/utils/getDecimalCount';

interface Props {
    modalData: ModalOrderCreate,
    oneClickTrading: AppOneClickTrading
}

const OrderCreateModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    const orderData = props.modalData.data;
    const { symbol, ask, bid, price, digit, amount, takeProfit, stopLoss, orderSide, orderType } = orderData;

    const handleClose = () => storeManager.modal.setOrderCreate({ visibility: false, data: null })

    const handleSubmitClick = async () => {
        if (!serviceAvailable) return;
        setServiceAvailable(false);

        await orderService.provider.create()
            .then(() => handleClose())
            .finally(() => setServiceAvailable(true));
    }

    const changeAmount = (value: string) => getDecimalCount(value) <= 2? orderService.changeOrderCreateData('amount', value) : null
    const changePrice = (value: string) => getDecimalCount(value) <= digit? orderService.changeOrderCreateData('price', value) : null
    const changeTakeProfit = (value: string) => getDecimalCount(value) <= digit? orderService.changeOrderCreateData('takeProfit', value.replace(',', '.')) : null
    const changeStopLoss = (value: string) => getDecimalCount(value) <= digit? orderService.changeOrderCreateData('stopLoss', value.replace(',', '.')) : null

    // one click trading 
    if (props.oneClickTrading) {
        orderService.provider.create()
            .finally(() => handleClose())

        return null;
    }
    
    return (
        <Modal title={t('NewOrder')} id="order-create-modal" close={handleClose}>
            <div className="order-type tabs">
                <button type="button" className={orderType === OrderTypes.LIMIT? 'selected' : undefined} onClick={() => orderService.changeOrderCreateData('orderType', OrderTypes.LIMIT)}>
                    {t('Limit').toUpperCase()}
                </button>
                <button type="button" className={orderType === OrderTypes.MARKET? 'selected' : undefined} onClick={() => orderService.changeOrderCreateData('orderType', OrderTypes.MARKET)}>
                    {t('Market').toUpperCase()}
                </button>
                <button type="button" className={orderType === OrderTypes.STOP? 'selected' : undefined} onClick={() => orderService.changeOrderCreateData('orderType', OrderTypes.STOP)}>
                    {t('Stop').toUpperCase()}
                </button>
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    {symbol}
                </div>
                <div className="order-side tabs">
                    <button type="button" className={"selected" + (orderSide === OrderSides.SELL ? ' sell' : '')} onClick={() => orderService.changeOrderCreateData('orderSide', OrderSides.SELL)}>
                        {t('Sell')}
                        <br/>
                        {orderType === OrderTypes.MARKET && bid.toFixed(digit)}
                    </button>
                    <button type="button" className={"selected" + (orderSide === OrderSides.BUY ? ' buy' : '')} onClick={() => orderService.changeOrderCreateData('orderSide', OrderSides.BUY)}>
                        {t('Buy')}
                        <br/>
                        {orderType === OrderTypes.MARKET && ask.toFixed(digit)}
                    </button>
                </div>
                <div className="amount">
                    <label htmlFor="create-amount">{t('Quantity')}</label>
                    <input id="create-amount" type="number" step="0.01" min="0.01" value={amount} onChange={(e) => changeAmount(e.currentTarget.value)} />
                    <div className="amount-buttons">
                        <div onClick={() => orderService.addAmount(amount, 0.05)} onAuxClick={(e) => orderService.subtractAmount(amount, 0.05, e)} onContextMenu={(e) => e.preventDefault()}>0.05</div>
                        <div onClick={() => orderService.addAmount(amount, 0.10)} onAuxClick={(e) => orderService.subtractAmount(amount, 0.10, e)} onContextMenu={(e) => e.preventDefault()}>0.10</div>
                        <div onClick={() => orderService.addAmount(amount, 0.50)} onAuxClick={(e) => orderService.subtractAmount(amount, 0.50, e)} onContextMenu={(e) => e.preventDefault()}>0.50</div>
                        <div onClick={() => orderService.addAmount(amount, 1.00)} onAuxClick={(e) => orderService.subtractAmount(amount, 1.00, e)} onContextMenu={(e) => e.preventDefault()}>1.00</div>
                        <div onClick={() => orderService.addAmount(amount, 5.00)} onAuxClick={(e) => orderService.subtractAmount(amount, 5.00, e)} onContextMenu={(e) => e.preventDefault()}>5.00</div>
                    </div>
                </div>
                {
                    orderType !== OrderTypes.MARKET && (
                            <div className="price">
                                <label htmlFor="create-price">{t('Price')}</label>
                                <input type="text" id="create-price" value={price} onChange={(e) => changePrice(e.currentTarget.value)}/>
                            </div>      
                        )
                    }
                <div className="takeprofit-stoploss">
                    <div className={'col' + (orderSide === OrderSides.SELL? ' flex-end' : '')}>
                        <label htmlFor="create-take-profit" className="take-profit-label">{t('TakeProfit')}</label>
                        <input id="create-take-profit" type="text" value={takeProfit} onChange={(e) => changeTakeProfit(e.currentTarget.value)} />
                    </div>
                    <div className="col mid-col">
                        <img src={orderSide === OrderSides.SELL? IMAGES.ICON.TPSL_SELL : IMAGES.ICON.TPSL_BUY} alt="" />
                    </div>
                    <div className={'col' + (orderSide === OrderSides.BUY? ' flex-end' : '')}>
                        <label htmlFor="create-stop-loss" className="stop-loss-label">{t('StopLoss')}</label>
                        <input id="create-stop-loss" type="text" value={stopLoss} onChange={(e) => changeStopLoss(e.currentTarget.value)} />
                    </div>
                </div>
                <button type="submit" className={'create-button' + (orderSide === OrderSides.SELL? ' red' : '')} onClick={handleSubmitClick} disabled={!serviceAvailable}>
                    {
                        serviceAvailable? 
                            `${orderSide === OrderSides.BUY? t('Buy') : t('Sell')}` : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    modalData: state.modal.orderCreate,
    oneClickTrading: state.app.oneClickTrading
})

export default connect(mapStateToProps)(OrderCreateModal);