import apiUtils from "api/utils";
import { appConfig } from "app/config";

export class ServiceProxyBase extends Error {
    public getBaseUrl(temp:string, baseUrl?:string) : string
    {
        return appConfig.apiServiceUrl;
    }

    protected transformOptions = apiUtils.transformOptions;
    protected transformResult = apiUtils.transformResult;
}