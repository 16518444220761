export const automaticDataDe = [
    {
        name: 'Bohemian II',
        description: 'Diskretionäre Indexstrategie',
        factsheet: 'https://trademaster.com/Factsheet/Bohemian-II-Factsheet.pdf',
        moreInfo: 'https://smarter-investments.com/project/bohemian-ii-a-step-1/',
        goalStrategie: [
            'Die Strategie Bohemian II erzeugt Signale, die den gesamten Markt abdecken. Da der S&P 500 der größte und stabilste Index in diesem Bereich ist, konzentrieren wir unsere Aufmerksamkeit auf diesen Index als Stellvertreter für den gesamten Aktienmarkt. Wir handeln nicht mit einzelnen Aktien oder Rohstoffen.',
            'Die Strategie Bohemian II erfasst die Stimmung der Marktteilnehmer an den Aktienmärkten und ist bestrebt, aus jeder Phase der Marktstimmung Vorteile für die Anleger zu generieren. Das System ist diskretionär und nutzt Stimmungsindikatoren und die Analyse statistischer Anomalien, um unabhängig über die Richtung des Marktes in der unmittelbaren Zukunft - am nächsten Handelstag - zu entscheiden.',
            'Täglich wird ein Long- oder Short-Signal geliefert, das für den gesamten Handelstag gilt, so dass nur einmal am Tag, bei der Eröffnungsglocke des Kassamarktes, eine neue Position ermittelt wird, die bis zur Schlussglocke gehalten wird.',
            'Investoren müssen bereit sein, (auch wesentlich) Verluste zu akzeptieren.'
        ],
        information: {
            name: 'Bohemian II',
            product: 'Aktive Vermögensverwaltung',
            riskManagement: 'Automatisiert und manuell',
            recommendedInvestmentPeriod: 'Minimum 3 Jahre',
            availability: 'Monatlich',
            minInvestment: '5.000 EUR',
            setupFee: '5,0 % netto	5,9 % brutto',
            profitSharing: '15,0 % netto	17,7 % brutto',
            managementFee: '0,13 % netto	0,15 % brutto',
            otherFees: 'Handelskommission',
        },
        riskInfo: {
            class: 'Sehr hoch (6 von 7)',
            lossTolerance: '50%',
            riskRating: [
                'Anleger müssen eine bestimmte Mindestpunktzahl erreichen, um ausreichende Kenntnisse und Erfahrungen nachweisen zu können, um in diese Strategie zu investieren. Investoren sollten nicht mehr als 5–10 % ihres Kapitals in Investments der Risikoklasse 6 investieren.',
            ],
            suitability: [
                'Investoren mit hoher Renditeerwartung und hohem Risikobewusstsein, die auch wesentliche Verluste tragen können.',
            ]
        },
        forwardingWindow: [
            `Zur Registrierung für die Strategie Bohemian II werden Sie nun an smarter-investments weitergeleitet.`,
            ' ',
            'smarter-investments ist ein Produkt der:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'Lizenznummer: VANS-IF-9616',
        ]
    },
    {
        name: 'Mighty Innovations',
        description: 'Investitionen in Fonds, ETFs oder auch Aktien',
        factsheet: 'https://trademaster.com/Factsheet/Mighty-Innovations-Factsheet.pdf',
        moreInfo: 'https://smarter-investments.com/project/mighty-innovations-a-step-1/',
        goalStrategie: [
            'Die Strategie von Mighty Innovations konzentriert sich auf Investitionen in Fonds, ETFs oder auch Aktien in den Bereichen Cloud Computing, E-Commerce, Cyber Security, Blockchain, Automation sowie Unternehmungen der orbitalen und suborbitalen Raumfahrt. ',
            'Hierzu gehören Unternehmen, die bezüglich künstlicher Intelligenz, Robotik, 3D-Druck, Materialien und Energiespeicherung mit der Raumfahrt in Verbindung stehen und umfassen Synergiebereiche wie Landwirtschaft, Internetzugang, globales Positionierungssystem (GPS), Bauwesen, Bildgebung, Drohnen, Lufttaxis und elektrische Luftfahrtfahrzeuge.',
            'Auch kann in Unternehmen investiert werden, die innovative Zahlungsplattformen und -methoden, Point-of-Sale-Anbieter, E-Commerce, Transaktionsinnovationen, Geschäftsanalysen, Betrugsreduzierung, reibungslose Finanzierungsplattformen, Peerto-Peer-Kreditvergabe, Blockchain-Technologien, Vermittlerbörsen, Technologien zur Vermögensallokation, mobile Zahlungen sowie Risikopreis- und Pooling-Aggregatoren (Versicherungen) entwickeln, nutzen oder sich auf diese verlassen. Auch andere Branchen sind möglich. ',
            'Das Portfolio wird aktiv gemanagt, das heißt die Gewichtung der einzelnen Anlageklassen wird flexibel an die jeweilige Kapitalmarktsituation angepasst, um Chancen wahrzunehmen und Volatilitäten zu reduzieren. ',
            'Ziel des Portfolios ist die Erzielung einer höheren Rendite bei erhöhten Risiken. Investoren müssen bereit sein, auch wesentlich Verluste zu akzeptieren'
        ],
        information: {
            name: 'Mighty Innovations',
            product: 'Aktive Vermögensverwaltung',
            riskManagement: 'Automatisiert und manuell',
            recommendedInvestmentPeriod: 'Minimum 5 Jahre',
            availability: 'Monatlich',
            minInvestment: '5.000 EUR',
            setupFee: '5,0 % netto	5,9 % brutto',
            profitSharing: '15,25 % netto	18,00 % brutto',
            managementFee: '1,52 % netto	1,80 % brutto',
            otherFees: 'Handelskommission',
        },
        riskInfo: {
            class: 'Sehr hoch (6 von 7)',
            lossTolerance: '50%',
            riskRating: [
                'Anleger müssen eine bestimmte Mindestpunktzahl erreichen, um ausreichende Kenntnisse und Erfahrungen nachweisen zu können, um in diese Strategie zu investieren. Investoren sollten nicht mehr als 5–10 % ihres Kapitals in Investments der Risikoklasse 6 investieren.',
            ],
            suitability: [
                'Investoren mit hoher Renditeerwartung und hohem Risikobewusstsein, die auch wesentliche Verluste tragen können.',
            ]
        },
        forwardingWindow: [
            `Zur Registrierung für die Strategie Mighty Innovations werden Sie nun an smarter-investments weitergeleitet.`,
            ' ',
            'smarter-investments ist ein Produkt der:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'Lizenznummer: VANS-IF-9616',
        ]
    },
    {
        name: 'Mighty Index',
        description: 'Recherchen- und analysenbasierte CFD Strategie',
        factsheet: 'https://trademaster.com/Factsheet/Mighty-Index-Factsheet.pdf',
        moreInfo: 'https://smarter-investments.com/project/mighty-index-a-step-1/',
        goalStrategie: [
            'Das Anlageziel der Mighty Index-Strategie ist es, aufgrund von Recherchen und Analysen in verschiedenen Zielmärkten (CFDs, Futures und andere Derivate auf Aktien Indizes, Renten, Währungen und Rohstoffen) von der Einflussnahme größerer Marktteilnehmer (Fonds, Versicherungen und Banken) zu profitieren. Die von den einzelnen Teilstrategien genutzten Marktmechanismen bestehen typischerweise bereits viele Jahre, teilweise sogar Jahrzehnte. ',
            'Es werden je Unterstrategie gehebelte Positionen eingegangen und typischerweise für wenige Stunden bis Tage gehalten. Zur Risikobegrenzung werden sämtliche Positionen mit einem Stop Loss versehen',
            'Die Verteilung des Kapitals auf verschiedene Zielmärkte soll zu einer effektiven Risikostreuung beitragen. Sämtliche Teilstrategien werden kontinuierlich überprüft und ggf. angepasst. ',
            'Investoren müssen bereit sein, (auch wesentliche) Verluste zu akzeptieren.'
        ],
        information: {
            name: 'Mighty Index',
            product: 'Aktive Vermögensverwaltung',
            riskManagement: 'Automatisiert und manuell',
            recommendedInvestmentPeriod: 'Minimum 3 Jahre',
            availability: 'Monatlich',
            minInvestment: '10.000 EUR',
            setupFee: '5,0 % netto	5,9 % brutto',
            profitSharing: '20,0 % netto	23,6 % brutto',
            managementFee: '0,20 % netto	0,236 % brutto',
            otherFees: 'Handelskommissionen',
        },
        riskInfo: {
            class: 'Sehr hoch (7 von 7)',
            lossTolerance: '50%',
            riskRating: [
                'Anleger müssen eine bestimmte Mindestpunktzahl erreichen, um ausreichende Kenntnisse und Erfahrungen nachweisen zu können, um in diese Strategie zu investieren. Investoren sollten nicht mehr als 5–10 % ihres Kapitals in Investments der Risikoklasse 7 investieren.',

            ],
            suitability: [
                'Investoren mit hoher Renditeerwartung und hohem Risikobewusstsein, die auch wesentliche Verluste tragen können.',
            ]
        },
        forwardingWindow: [
            `Zur Registrierung für die Strategie Mighty Index werden Sie nun an smarter-investments weitergeleitet.`,
            ' ',
            'smarter-investments ist ein Produkt der:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'Lizenznummer: VANS-IF-9616',
        ]
    },
    {
        name: 'Mighty Balanced',
        description: 'Zielinvestitionen in nationale und internationale Aktien',
        factsheet: 'https://trademaster.com/Factsheet/Mighty-Balanced-Factsheet.pdf',
        moreInfo: 'https://smarter-investments.com/project/mighty-balanced-a-step-1/',
        goalStrategie: [
            'Die Strategie von Mighty Balanced konzentriert sich auf Zielinvestitionen in nationale und internationale Aktien sowie Anleihen über Fonds und ETFs. Derivate sowie Einzelwerte können mit bis zu 30 % des Investmentvermögens beigemischt werden. Strategie von Mighty Balanced konzentriert sich auf Zielinvestitionen in nationale und internationale Aktien sowie Anleihen über Fonds und ETFs. Derivate sowie Einzelwerte können mit bis zu 30 % des Investmentvermögens beigemischt werden. Das Portfolio wird aktiv gemanagt, das heißt die Gewichtung der einzelnen Anlageklassen wird flexibel an die jeweilige Kapitalmarktsituation angepasst, um Chancen wahrzunehmen und Volatilitäten zu reduzieren. Ziel des Portfolios ist die Erzielung einer höheren Rendite bei höheren Risiken.',
            'Das Portfolio wird aktiv gemanagt, das heißt die Gewichtung der einzelnen Anlageklassen wird flexibel an die jeweilige Kapitalmarktsituation angepasst, um Chancen wahrzunehmen und Volatilitäten zu reduzieren. Ziel des Portfolios ist die Erzielung einer höheren Rendite bei höheren Risiken.',
            'Investoren müssen bereit sein, auch höhere Verluste zu akzeptieren.'
        ],
        information: {
            name: 'Mighty Balanced',
            product: 'Aktive Vermögensverwaltung',
            riskManagement: 'Automatisiert und manuell',
            recommendedInvestmentPeriod: 'Minimum 5 Jahre',
            availability: 'Monatlich',
            minInvestment: '5.000 EUR',
            setupFee: '5,0 % netto	5,9 % brutto',
            profitSharing: '15,25 % netto	18,0 % brutto',
            managementFee: '0,13 % netto	0,15 % brutto',
            otherFees: 'Handelskommissionen',
        },
        riskInfo: {
            class: 'Moderat Wachstum (5 von 7)',
            lossTolerance: '25%',
            riskRating: [
                'Anleger müssen eine bestimmte Mindestpunktzahl erreichen, um ausreichende Kenntnisse und Erfahrungen nachweisen zu können, um in diese Strategie zu investieren. Investoren sollten nicht mehr als 30% ihres Kapitals in Investments der Risikoklasse 5 investieren. ',
            ],
            suitability: [
                'Um Ertragschancen über Kapitalmarkt-zinsniveau zu nutzen, werden wachstumsstarke Anlagen gesucht. Erhöhte Wertschwankungen sind unvermeidbar und werden vom Investor akzeptiert.',
            ]
        },
        forwardingWindow: [
            `Zur Registrierung für die Strategie Mighty Balanced werden Sie nun an smarter-investments weitergeleitet.`,
            ' ',
            'smarter-investments ist ein Produkt der:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'Lizenznummer: VANS-IF-9616',
        ]
    },
]

export const automaticDataEn = [
    {
        name: 'Bohemian II',
        description: 'Discretionary index strategy',
        factsheet: 'https://trademaster.com/Factsheet/Bohemian-II-Factsheet_EN.pdf',
        moreInfo: 'https://smarter-investments.com/en/project/bohemian-ii-a-step-1/',
        goalStrategie: [
            'The strategy Bohemian II generates signals that encompass the entire market. Since the S&P 500 is the largest and most stable index in this area, we focus our attention on this index as a proxy for the entire stock market. We do not trade individual stocks or commodities.',
            'The strategy Bohemian II captures the sentiment of market participants in the equity markets and seeks to generate benefits for investors from each phase of market sentiment. The system is discretionary and uses sentiment indicators and analysis of statistical anomalies to decide independently on the direction of the market in the immediate future - the next trading day.',
            'A daily signal will be provided, long or short, which is valid for the entire trading day, so that only once a day, at the opening bell of the cash market, an incoming position is identified which is held until the closing bell.',
            'Investors must be prepared to accept (even substantial) losses.'
        ],
        information: {
            name: 'Bohemian II',
            product: 'Active Asset Management',
            riskManagement: 'Automated and manually',
            recommendedInvestmentPeriod: 'Minimum 3 years',
            availability: 'Monthly',
            minInvestment: '5.000 EUR',
            setupFee: '5,0 % net	5,9 % gross',
            profitSharing: '15,0 % net	17,7 % gross',
            managementFee: '0,13 % net	0,15 % gross',
            otherFees: 'Commissions on trading level',
        },
        riskInfo: {
            class: 'Very high (6 of 7)',
            lossTolerance: '50%',
            riskRating: [
                'Investors need to reach a certain threshold in order to demonstrate sufficient knowledge and experience to invest in this strategy. Investors should not invest more than 5–10 % of their capital in risk class 6 investments.',
            ],
            suitability: [
                'Investors who expect a high return by being aware of the higher risk and who can accept even substantial losses.',
            ]
        },
        forwardingWindow: [
            `To register for the Bohemian II strategy, you will now be forwarded to smarter-investments.`,
            ' ',
            'smarter-investments is a brand of:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'License number: VANS-IF-9616',
        ]
    },
    {
        name: 'Mighty Innovations',
        description: 'Investments in funds, ETFs or even shares',
        factsheet: 'https://trademaster.com/Factsheet/Mighty-Innovations-Factsheet_EN.pdf',
        moreInfo: 'https://smarter-investments.com/en/project/mighty-innovations-a-step-1/',
        goalStrategie: [
            "Mighty Innovations' strategy focuses on investing in funds, ETFs or equities in the areas of cloud computing, e-commerce, cyber security, blockchain, automation and orbital and sub-orbital space ventures. These include companies related to space in terms of artificial intelligence, robotics, 3D printing, materials and energy storage, and include synergy areas such as agriculture, internet access, global positioning system (GPS), construction, imaging, drones, air taxis and electric aerospace vehicles.",
            'Investments can also be made in companies that develop, use or rely on innovative payment platforms and methods, point-of-sale providers, e-commerce, transaction innovations, business analytics, fraud reduction, frictionless financing platforms, peer-to-peer lending, blockchain technologies, intermediary exchanges, asset allocation technologies, mobile payments, and risk pricing and pooling aggregators (insurance). Other sectors are also possible.',
            'The portfolio is actively managed, i.e. the weighting of the individual asset classes is flexibly adjusted to the respective capital market situation in order to take advantage of opportunities and reduce volatilities. The goal of the portfolio is to achieve a higher return with increased risks.',
            'Investors must be prepared to accept even substantial losses.'
        ],
        information: {
            name: 'Mighty Innovations',
            product: 'Active Asset Management',
            riskManagement: 'Automated and manually',
            recommendedInvestmentPeriod: 'Minimum 5 years',
            availability: 'Monthly',
            minInvestment: '5.000 EUR',
            setupFee: '5,0 % net 5,9 % gross',
            profitSharing: '15,25 % net	18,00 % gross',
            managementFee: '1,52 % net 1,80 % gross',
            otherFees: 'Commissions on trading level',
        },
        riskInfo: {
            class: 'Moderate Growth (6 of 7)',
            lossTolerance: '50%',
            riskRating: [
                'Investors need to reach a certain threshold in order to demonstrate sufficient knowledge and experience to invest in this strategy. Investors should not invest more than 5–10 % of their capital in risk class 6 investments.',
            ],
            suitability: [
                'Investors who expect a high return by being aware of the higher risk and who can accept even substantial losses.',
            ]
        },
        forwardingWindow: [
            `To register for the Mighty Innovations strategy, you will now be forwarded to smarter-investments`,
            ' ',
            'smarter-investments is a brand of:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'License number: VANS-IF-9616',
        ]
    },
    {
        name: 'Mighty Index',
        description: 'Research and analysis-based CFD strategy',
        factsheet: 'https://trademaster.com/Factsheet/Mighty-Index-Factsheet_EN.pdf',
        moreInfo: 'https://smarter-investments.com/en/project/mighty-index-a-step-1/',
        goalStrategie: [
            'A CFD is a contract-for-difference, a financial derivative product that allows investors to speculate on the price movement of assets from a range of markets, including forex, commodities, indices and shares, without owning the underlying asset.',
            'The investment objective of the Mighty Index strategy is to benefit from the leverage of larger market participants (funds, insurance and banks) through research and analysis in various target markets (CFDs, futures and other derivates on equity indices, bonds, currencies and commodities). The market mechanisms used by the individual sub-strategies typically already exist for many years, sometimes even decades.',
            "For each sub-strategy, leveraged positions are entered and typically held for a few hours to days. To limit the risk, all positions are provided with a stop loss. The distribution of capital among various target markets should contribute to effective risk diversification. All sub-strategies are continuously reviewed and adjusted if necessary.",
            'Investors need to be willing and able to accept any as well as substantial losses.'
        ],
        information: {
            name: 'Mighty Index',
            product: 'Active Asset Management',
            riskManagement: 'Automated and manually',
            recommendedInvestmentPeriod: 'Minimum 3 years',
            availability: 'Monthly',
            minInvestment: '10.000 EUR',
            setupFee: '5,0 % net	5,9 % gross',
            profitSharing: '20,0 % net	23,6 % gross',
            managementFee: '0,20 % net	0,236 % gross',
            otherFees: 'Commissions on trading level',
        },
        riskInfo: {
            class: 'Very high (7 of 7)',
            lossTolerance: '50%',
            riskRating: [
                'Investors need to reach a certain threshold in order to demonstrate sufficient knowledge and experience to invest in this strategy. Investors should not invest more than 5–10 % of their capital in risk class 7 investments.',

            ],
            suitability: [
                'Investors who expect a high return by being aware of the higher risk and who can accept even substantial losses.',
            ]
        },
        forwardingWindow: [
            `To register for the Mighty Index strategy, you will now be forwarded to smarter-investments.`,
            ' ',
            'smarter-investments is a brand of:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'License number: VANS-IF-9616',
        ]
    },
    {
        name: 'Mighty Balanced',
        description: 'Target investments in national and international stocks',
        factsheet: 'https://trademaster.com/Factsheet/Mighty-Balanced-Factsheet_EN.pdf',
        moreInfo: 'https://smarter-investments.com/en/project/mighty-balanced-a-step-1/',
        goalStrategie: [
            'The Mighty Balanced strategy focuses on target investments in national and international equities and bonds via funds and ETFs. Derivatives and individual stocks can be included in up to 30% of the investment assets. The portfolio is actively managed, i.e. the weighting of the individual asset classes is flexibly adjusted to the respective capital market situation in order to take advantage of opportunities and reduce volatility. The objective of the portfolio is to achieve a higher return with higher risks.',
            'Investors must be prepared to accept moderate higher losses.'
        ],
        information: {
            name: 'Mighty Balanced',
            product: 'Active Asset Management',
            riskManagement: 'Automated and manually',
            recommendedInvestmentPeriod: 'Minimum 5 years',
            availability: 'Monthly',
            minInvestment: '5.000 EUR',
            setupFee: '5,0 % net	5,9 % gross',
            profitSharing: '15,25 % net	18,0 % gross',
            managementFee: '0,13 % net	0,15 % gross',
            otherFees: 'Commissions on trading level',
        },
        riskInfo: {
            class: 'Moderat Moderate Growth (5 of 7)',
            lossTolerance: '25%',
            riskRating: [
                'Investors need to reach a certain threshold in order to demonstrate sufficient knowledge and experience to invest in this strategy. Investors should not invest more than 30 % of their capital in risk class 5 investments. ',
            ],
            suitability: [
                'Investors need to reach a certain threshold in order to demonstrate sufficient knowledge and experience to invest in this strategy. Investors should not invest more than 30 % of their capital in risk class 5 investments',
            ]
        },
        forwardingWindow: [
            `To register for the Mighty Balanced strategy, you will now be forwarded to smarter-investments.`,
            ' ',
            'smarter-investments is a brand of:',
            ' ',
            'Van Sterling Capital Ltd.',
            '168, St. Christopher Street',
            'Valletta VLT 1467',
            'Malta',
            ' ',
            'Malta Financial Service Authority (MFSA)',
            'License number: VANS-IF-9616',
        ]
    },

]