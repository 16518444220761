import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';
import useTranslation from 'modules/localization';
import React from 'react';
import OpenPositions from './open-positions';
import PendingPositions from './pending-positions';

const OpenOrdersTable = () => {
    const { t } = useTranslation();
    const [sortedColumn, setSortedColumn] = React.useState<SortedColumn>(null);

    // <ColumnSortFeature>
    const handleColumnClick = (key: string, type: 'number' | 'string') => tableColumnSortHelper.changeSortedColumn({ 
        columnKey: key, 
        columnType: type, 
        sortStateGetter: sortedColumn, 
        sortStateSetter: setSortedColumn 
    });

    const renderColumnSortIcon = (key: string) => tableColumnSortHelper.renderSortedColumnIconInput({
        columnKey: key,
        sortStateGetter: sortedColumn
    });
    // </ColumnSortFeature>

    return (
        <div className='open-orders-table'>
            <table>
                <thead>
                    <tr>
                        <th style={{ width: '75px' }} onClick={() => handleColumnClick('order', 'number')} >{t('Deal')} {renderColumnSortIcon('order')}</th>
                        <th style={{ width: '80px' }} onClick={() => handleColumnClick('openTime', 'string')} >{t('Time')} {renderColumnSortIcon('openTime')}</th>
                        <th style={{ width: '60px' }} onClick={() => handleColumnClick('type', 'string')} >{t('Type')} {renderColumnSortIcon('type')}</th>
                        <th style={{ width: '60px' }} onClick={() => handleColumnClick('volume', 'number')} >{t('Volume')} {renderColumnSortIcon('volume')}</th>
                        <th style={{ width: '85px' }} onClick={() => handleColumnClick('symbol', 'string')} >{t('Symbol')} {renderColumnSortIcon('symbol')}</th>
                        <th style={{ width: '65px' }} onClick={() => handleColumnClick('openPrice', 'number')} >{t('Price')} {renderColumnSortIcon('openPrice')}</th>
                        <th style={{ width: '65px' }} onClick={() => handleColumnClick('sl', 'number')} >{t('SL')} {renderColumnSortIcon('sl')}</th>
                        <th style={{ width: '65px' }} onClick={() => handleColumnClick('tp', 'number')} >{t('TP')} {renderColumnSortIcon('tp')}</th>
                        <th style={{ width: '75px' }} onClick={() => handleColumnClick('cp', 'number')} >{t('ClosePrice')} {renderColumnSortIcon('cp')}</th>
                        <th style={{ width: '60px' }} onClick={() => handleColumnClick('commission', 'number')} >{t('Commission')} {renderColumnSortIcon('commission')}</th>
                        <th style={{ width: '60px' }} onClick={() => handleColumnClick('swap', 'number')} >{t('Swap')} {renderColumnSortIcon('swap')}</th>
                        <th style={{ width: '60px' }} onClick={() => handleColumnClick('profit', 'number')} >{t('ProfitAndLoss')} {renderColumnSortIcon('profit')}</th>
                        <th style={{ width: '150px' }}>{t('Options')} {renderColumnSortIcon('')}</th>
                    </tr>
                </thead>
                <tbody>
                    <OpenPositions sortedColumn={sortedColumn} />
                    <PendingPositions sortedColumn={sortedColumn} />
                </tbody>
            </table>
        </div>
    )
}

export default OpenOrdersTable;