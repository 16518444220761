import { OrderSides, OrderTypes } from "shared/symbol/constants";
import { Op } from "shared/trade/enums";

/** Description: gets the cmd value of order
 * 
 * Parameters: orderSide: OrderSides, orderType: OrderTypes
 * 
 * Output: Op | -1 (-1 = not found) 
 */
const getCmd = (orderSide: OrderSides, orderType: OrderTypes) => {
    if (orderSide === OrderSides.BUY) {
        // buy market
        if (orderType === OrderTypes.MARKET) {
            return Op.Buy;

        // buy limit
        } else if (orderType === OrderTypes.LIMIT) {
            return Op.BuyLimit;
            
        // buy stop
        } else if (orderType === OrderTypes.STOP) {
            return Op.BuyStop;

        }

    } else if (orderSide === OrderSides.SELL) {
        // sell market
        if (orderType === OrderTypes.MARKET) {
            return Op.Sell;

        // sell limit
        } else if (orderType === OrderTypes.LIMIT) {
            return Op.SellLimit;
            
        // sell stop
        } else if (orderType === OrderTypes.STOP) {
            return Op.SellStop;
            
        }
        
    }

    return -1;
}

export default getCmd;