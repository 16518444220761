import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
// Shared
import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
// Types
import { OrderState } from "./types";
import { thunkService } from "services";

class OrderThunk {
    /** Description: Adds the "Order" method cases.  */
    public addCases = (builder: ActionReducerMapBuilder<OrderState>) => {
        this.addOrderHistoryCase(builder);
        this.addTradingAccountInfoCase(builder);
        this.addLoginInformationsCase(builder);
    }

    /** Description: Adds the "getOrderHistory" method cases.  */
    private addOrderHistoryCase = (builder: ActionReducerMapBuilder<OrderState>) => {
        // Event: onPending
        builder.addCase(thunkService.order.getOrderHistory.pending, (state, action) => {
            state.history = new StoreMiddlewareEntity({
                data: null,
                status: EntityStatus.PENDING,
                updateTime: Date.now()
            })
        });

        // Event: onRejected
        builder.addCase(thunkService.order.getOrderHistory.rejected, (state, action) => {
            state.history = new StoreMiddlewareEntity({
                data: action.error.message,
                status: EntityStatus.FAILED,
                updateTime: Date.now()
            })
        });

        // Event: onFulfilled
        builder.addCase(thunkService.order.getOrderHistory.fulfilled, (state, action) => {
            state.history = new StoreMiddlewareEntity({
                data: action.payload,
                status: EntityStatus.SUCCEEDED,
                updateTime: Date.now()
            })
        });
    }

    /** Description: Adds the "getTradingAccountInfo" method cases.  */
    private addTradingAccountInfoCase = (builder: ActionReducerMapBuilder<OrderState>) => {
        // Event: onFulfilled
        builder.addCase(thunkService.account.getTradingAccountInfo.fulfilled, (state, action) => {
            const payload = action.payload as any;
            const tradingAccountInfo = payload.tradingAccountInfo;
            
            if (tradingAccountInfo) {
                state.open = tradingAccountInfo.openOrdersInfo;
                state.pending = tradingAccountInfo.pendingOrdersInfo;
            }
        });
    }
    
    /** Description: Adds the "getLoginInformations" method cases.  */
    private addLoginInformationsCase = (builder: ActionReducerMapBuilder<OrderState>) => {
        // Event: onFulfilled
        builder.addCase(thunkService.account.getLoginInformations.fulfilled, (state, action) => {
            const { tradingAccountInfo } = action.payload as any;

            if (tradingAccountInfo) {
                state.open = tradingAccountInfo.openOrdersInfo;
                state.pending = tradingAccountInfo.pendingOrdersInfo;
            }
        })
    }
}

const orderThunk = new OrderThunk();
export default orderThunk;