import React from 'react';

interface Props {
    children?: JSX.Element | JSX.Element[]
}

const AccordionListContainer = (props: Props) => {
    return (
        <div className="accordion-list">
            <ul>
                {props.children}
            </ul>
        </div>
    )
}


export default AccordionListContainer;