import { RegisterFormProps } from './interfaces';
import { RootState } from '../../../store/types';
import { connect } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import RegisterForm from '.';
import React from 'react';

const RegisterFormWithRecaptcha = (props: RegisterFormProps) => {

    let recaptchaSiteKey: string = props.recaptchaSiteKey || '';
    let useCaptchaOnRegistration: boolean = props.useCaptchaOnRegistration || false;

    return (
        <React.Fragment>
            {useCaptchaOnRegistration === true && <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey} >
                <RegisterForm {...props} />
            </GoogleReCaptchaProvider>}

            {useCaptchaOnRegistration === false &&<RegisterForm {...props} />}
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    recaptchaSiteKey: state.app.config.data?.recaptchaSiteKey,
    useCaptchaOnRegistration: state.app.config.data?.useCaptchaOnRegistration
})

export default connect(mapStateToProps)(RegisterFormWithRecaptcha);