import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiResponse } from "shared/api/constants";
import apiUtils from ".";

const fetchApi = (options: AxiosRequestConfig) => {
    return new Promise((resolve, reject) => {
        const handleCatch = (error: any) => {
            const response = new ApiResponse();
            response.success = false;
            response.message = error.message;

            resolve(response)
        }

        const sendRequest = (transformedOptions: AxiosRequestConfig<any>) => {
            axios.request(transformedOptions)
                .then((response: AxiosResponse) => {
                    const process = async (response: AxiosResponse) => {
                        return Promise.resolve(response.data);
                    }

                    const url = transformedOptions.url || null;
                    const callback = apiUtils.transformResult(url, response, process);
                    resolve(callback);
                })
                .catch(handleCatch)
        }

        apiUtils.transformOptions(options)
            .then(sendRequest)
            .catch(handleCatch);
    })
}

export default fetchApi;