import storageManager from "modules/storage-manager";
import React from "react";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import storeManager from "store/manager";

type SortState = string | null;
type SortStateDispatcher = React.Dispatch<React.SetStateAction<SortState>>;

class WatchlistSortHelper {
    // public props
    public element: { state: SortState, set: SortStateDispatcher } = { state: null, set: () => null };
    
    // private props
    private _sortTimers: NodeJS.Timer[] = [];

    // private constant props
    private readonly TOP_SENSIBILITY = 200;
    private readonly BOTTOM_SENSIBILITY = 200;
    private readonly INTERVAL_MS = 400;

    // <ContainerHandlers>
    /** Description: If empty background is hovered, resets the element prop. */
    public handleContainerMouseUp = (event: React.MouseEvent<HTMLDivElement>) => {
        const target: any = event.target;
        if (target?.className === 'watchlist-container') {
            this.element.set(null);
        }
    }

    /** Description: When the mouse is leaved, clears the sort element prop.  */
    public handleContainerMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
        this.element.set(null);
        this.clearAllSortIntervals();
    }
    
    /** Description: Speeds up the sorting process. */
    public handleContainerMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!this.element.state) 
            return;
        
        const topArea = event.clientY - event.currentTarget.offsetTop;
        const bottomArea = (event.currentTarget.offsetTop + event.currentTarget.clientHeight) - event.clientY;

        const isInTopArea = topArea <= this.TOP_SENSIBILITY;
        const isInBottomArea = bottomArea <= this.BOTTOM_SENSIBILITY;


        this.clearAllSortIntervals();
        if (isInTopArea) {
            this.addSortTimer(-300, event);

        } else if (isInBottomArea) {
            this.addSortTimer(300, event);

        } 
    }
    // </ContainerHandlers>

    // <ItemHandlers>
    /** Description: Sets the sort element prop. */
    public handleItemMouseDown = (sortElementKey: string) => {
        this.element.set(sortElementKey);
    }

    /** Description: When hovered an item, changes position of draggin' item in the watchlist array. */
    public handleItemMouseEnter = (sortElementKey: string) => {
        const sortElement = this.element.state;
        if (!sortElement) 
            return;
    

        let symbolList = [...storeManager.symbol.getWatchList()];
        
        const dragIndex = symbolList.findIndex((symbol) => symbol === sortElement);
        symbolList = symbolList.filter((symbol) => symbol !== sortElement);

        let toIndex = symbolList.findIndex((symbol) => symbol === sortElementKey);
        toIndex = dragIndex > toIndex ? toIndex: toIndex +1;

        const l1 = symbolList.slice(0, toIndex);
        const l2 = symbolList.slice(toIndex, symbolList.length);
        
        symbolList = [
            ...l1,
            sortElement,
            ...l2
        ]
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const storageWatchList = storageManager.preferences.get()?.watchList || {};
        storeManager.symbol.setWatchList(symbolList);
        storageManager.preferences.update({ 
            watchList:  {
                ...storageWatchList,
                [platformCode]: {
                    ...storageWatchList[platformCode],
                    [accountNo]: symbolList
                }
            }
        });
    }

    /** Description: When sortin' stops, sets drag data as null. */
    public handleItemMouseUp = () => {
        this.element.set(null);
        this.clearAllSortIntervals();
    }
    // </ItemHandlers>

    // <SortInterval>
    /** Description: Adds new interval for scrolling. */
    private addSortTimer = (scrollValue: number, event: React.MouseEvent<HTMLDivElement>) => {
        let counter = 1; 
        const currentTarget = event.currentTarget;

        const sortTimer = setInterval(function() {
            let topValue = currentTarget.scrollTop + (scrollValue * counter);

            if (topValue < 0) topValue = 0;
            if (topValue > currentTarget.scrollHeight) topValue = currentTarget.scrollHeight;

            currentTarget.scrollTo({ top: topValue, behavior: 'smooth' });
            counter += 1;

        }, this.INTERVAL_MS);

        this._sortTimers.push(sortTimer);
    }

    /** Description: Clears all sort intervals. */
    private clearAllSortIntervals = () => {
        this._sortTimers.forEach((sortTimer) => {
            clearInterval(sortTimer);
        });
    }
    // </SortInterval>

    // <SortStyles>
    /** Description: Gets the sort styles to element. */
    public getSortStyles = (sortElementKey: string) => {
        let styles: React.CSSProperties | undefined = {
            userSelect: 'none',
        };
        
        if (this.element.state !== null && this.element.state !== sortElementKey) {
            styles.opacity = "0.15";
        }

        return styles;
    }
    // </SortStyles>
}

let watchlistSortHelper = new WatchlistSortHelper();
export default watchlistSortHelper;