import * as signalR from "@microsoft/signalr";

abstract class HubBase {
    // Private Props
    private readonly _connectionUrl: string;
    private readonly reconnectionMs: number = 3000;
    // Protected Props
    protected connection: signalR.HubConnection | null = null;
    protected subscriptions: Array<string> = [];
    protected onConnectionDefine!: Function;
    protected onConnectionStart!: Function;

    constructor(url: string) {
        this._connectionUrl = url;
    }

    // <Connection>
    /** Description: Creates a SignalR Connection. */
    private create = () => {
        this.connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.None)
            .withUrl(this._connectionUrl)
            .build();

        if (this.onConnectionDefine) {
            this.onConnectionDefine();
        }
    }

    /** Description: Connects a SignalR Hub.  */ 
    protected connect = () => {
        // If hub connection is not created, creates it.
        if (!this.connection) {
            this.create();
        }
        
        this.connection?.onclose(() => {
            // alert('Reconnecting...');
            this.connect();
        });

        // If the hub is not connected, connects the hub.
        if (this.connection?.state === signalR.HubConnectionState.Disconnected) {
            this.connection.start()
            .then(() => {
                if (this.onConnectionStart) {
                    this.onConnectionStart();
                }
            })
            .catch(() => {
                // in case of failure, tries to reconnect the hub every each "reconnectionMs (default: 3000)" ms. 
                let self = this;
                setTimeout(self.connect, this.reconnectionMs);
            })
        }
    }

    /** Description: Disconnects the Connected SignalR Hub. */ 
    protected disconnect = () => {
        // If the hub is connected, disconnects the hub.
        if (this.connection?.state === signalR.HubConnectionState.Connected) {
            this.connection.stop();
        }
    }
    // </Connection>

    // <Subscription>
    /** Description: Subscribes the subscription list. */ 
    protected subscribe = () => {
        // If the hub is connected, sends the "AddToGroup" message with each subscription in the subscription list
        if (this.connection?.state === signalR.HubConnectionState.Connected) {
            this.subscriptions.forEach((subscription: any) => {
                this.connection?.invoke("AddToGroup", subscription);
            })
        }
    }

    /** Description: Unsubscribes the subscription list. */ 
    protected unsubscribe = () => {
        // If the hub is connected, sends the "RemoveToGroup" message with each subscription in the subscription list
        if (this.connection?.state === signalR.HubConnectionState.Connected && this.subscriptions.length) {
            this.subscriptions.forEach((subscription: any) => {
                this.connection?.invoke("RemoveToGroup", subscription);
            })

            this.subscriptions = [];
        }
    }
    // </Subscription>

    /** Description: Gets connection status. */
    public getStatus = () => {
        return this.connection?.state || null;
    }
}

export default HubBase;