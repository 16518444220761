import React from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';
import { chartService } from 'services';
// Types
import { ModalProps } from './type';

const Modal = (props: ModalProps) => {
    const modalRef = React.useRef<HTMLDivElement>(null);

    const setPositionCenter = () => {
        if (modalRef.current) {
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            const modalWidth = modalRef.current.offsetWidth;
            const modalHeight = modalRef.current.offsetHeight;

            const left = (windowWidth / 2) - (modalWidth / 2);
            const top = (windowHeight / 2) - (modalHeight / 2);

            modalRef.current.style.left = left + "px";
            modalRef.current.style.top = top + "px";
        }
    }

    const showOnTop = (element: HTMLDivElement) => {
        const modals: any = Object.values({...document.getElementsByClassName('modal')});
        if (modals?.length) {
            modals.forEach((modal: HTMLDivElement) => modal.style.zIndex = "2");
            element.style.zIndex = "10";
        }
    }

    const handleMouseDown = (event: DraggableEvent) => {
        showOnTop(event.currentTarget as any);
        chartService.wrapperHelper.create();
    }

    const handleMouseUp = () => {
        chartService.wrapperHelper.remove();
    }

    React.useEffect(() => {
        setPositionCenter();

        if (modalRef.current) {
            modalRef.current.focus();
            showOnTop(modalRef.current);
        } 

        return (( ) => {
            chartService.wrapperHelper.remove();
        })
    }, [])
    
    return (
        <Draggable handle="div.modal-head" bounds={'parent'} onStart={handleMouseDown} onStop={handleMouseUp}>
            <div ref={modalRef} id={props.id} className="modal" tabIndex={0} onKeyUp={(key) => key.code === 'Escape' && props.close()}>
                <div className="head modal-head">
                    <div className="title">
                        {props.title}
                    </div>
                    <div className="control-buttons">
                        <button onMouseDown={props.close}>
                            <i className='icofont-close-squared-alt' />    
                        </button>
                    </div>
                </div>
                <div className="body" style={props.bodyStyle}>
                    {props.children}
                </div>
            </div>
        </Draggable>
    )
}

export default Modal;