import React from 'react';
import { connect } from 'react-redux';
import { appConfig } from 'app/config';
// Hubs
import FeedHub from "./hubs/feed-hub";
import TradeMasterHub from './hubs/trademaster-hub';
import IndicatorHub from './hubs/indicator-hub';
// Types
import { RootState } from 'store/types';
import { SocketProps } from './types';

const Socket = (props: SocketProps) => {
    const location = (props.location === appConfig.pages.trader.path) || (props.location === appConfig.pages.tvChart.path);
    
    return (
        <React.Fragment>
            { 
                location &&
                    <React.Fragment>
                        <FeedHub />
                        <TradeMasterHub />
                        <IndicatorHub />
                    </React.Fragment>
            }

        </React.Fragment>
    );
}

const mapStateToProps = (state: RootState) => ({
    location: state.app.location,
})

export default connect(mapStateToProps)(Socket);