import React from 'react';
import { connect } from 'react-redux';
// Types
import { RootState } from 'store/types';
import { PendingOrderInfo } from 'shared/symbol/types';
import { OrderPending } from 'store/slices/order-slice/types';
import storeManager from 'store/manager';
import useTranslation from 'modules/localization';
import { chartService, orderService } from 'services';
import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';

interface Props {
    data: OrderPending,
    sortedColumn: SortedColumn
}

const _OpenOrdersTablePendingPositions = (props: Props) => {
    const { t } = useTranslation();

    const openModifyModal = (data: PendingOrderInfo) => storeManager.modal.setOrderModify({ visibility: true, data: {
        orderId: data.order,
        symbol: data.symbol,
        orderSide: data.type,
        digit: data.digits,
        price: data.openPrice.toFixed(data.digits),
        stopLoss: data.sl.toFixed(data.digits),
        takeProfit: data.tp.toFixed(data.digits),
    }});
    
    const openCloseModal = (data: PendingOrderInfo) => {

        storeManager.modal.setOrderDelete({
            visibility: true, data: {
                orderId: data.order,
                symbol: data.symbol,
                orderType: data.type,
            }
        })

    };

    const handleCloseMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        const rowElement = event.currentTarget.parentElement?.parentElement?.parentElement; 
        if (rowElement) {
            rowElement.classList.add('close');
            
            setTimeout(() => {
                if (rowElement) {
                    rowElement.classList.remove('close');
                }
    
            }, orderService.provider.ROW_TRANSACTION_ANIMATION_MS / 2);

        }
    }

    const data = tableColumnSortHelper.getSortedData({ sortStateGetter: props.sortedColumn, data: props.data });

    return (
        <React.Fragment>
            <tr className="table-title">
                <td colSpan={13}><b>{t('PendingOrders')}</b></td>
            </tr>
            {
                data?.map((data: PendingOrderInfo) => {
                    const openPrice = data.openPrice.toFixed(data.digits);
                    const stopLoss = data.sl.toFixed(data.digits);
                    const takeProfit = data.tp.toFixed(data.digits);
                    const closePrice = data.cp.toFixed(data.digits);

                    const volume = data.volume.toFixed(2);
                    const swap = data.swap.toFixed(2);
                    const profit = data.profit.toFixed(2);

                    return (
                        <tr key={data.order} id={`order-${data.order}`}>
                            <td>#{data.order}</td>
                            <td>{data.openTime}</td>
                            <td>{data.type}</td>
                            <td>{volume}</td>
                            <td className="chart-button" onClick={() => chartService.changeSelectedTab(data.symbol)}>
                                {data.symbol}
                                <i className="icofont-chart-line-alt"></i>
                            </td>
                            <td>{openPrice}</td>
                            <td>{stopLoss}</td>
                            <td>{takeProfit}</td>
                            <td>{closePrice}</td>
                            <td>-</td>
                            <td>{swap}</td>
                            <td>{profit}</td>
                            <td>
                                <div className="options">
                                    <button className="modify-button" onClick={() => openModifyModal(data)}>
                                        {t('Modify')}
                                        <i className="icofont-edit"></i>
                                    </button>
                                    <button className="delete-button" onClick={() => openCloseModal(data)} onMouseDown={handleCloseMouseDown}>
                                        {t('Close')}
                                        <i className="icofont-close-squared-alt"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    data: state.order.pending,
})

export default connect(mapStateToProps)(_OpenOrdersTablePendingPositions);