import { createAsyncThunk } from "@reduxjs/toolkit";
import Api, { ApiTypes } from "api";
import storageManager from "modules/storage-manager";
import { alarmService } from "services";
import { ApiResponse } from "../../shared/api/constants";

class AccountThunkService {
    private readonly _tradeMasterService;
    private readonly _sessionService;
    
    constructor() {
        this._tradeMasterService = new Api.TradeMasterServiceProxy();
        this._sessionService = new Api.SessionServiceProxy();
    }

    /** Description: Returns trading account informations. */
    public getTradingAccountInfo = createAsyncThunk(
        'app/getTradingAccountInfo',
        async (input: {
            platformCode: string,
            accountNo: number,    
        }) => {
            return new Promise(async (resolve, reject) => {
                const response: ApiResponse<ApiTypes.IGetTradingAccountInfoOutputDto> = await this._tradeMasterService.getTradingAccountInfo(input.accountNo, input.platformCode) as any;
                if (response.success) {
                    const data = response.data;
                    
                    const userData = storageManager.user.get();
                    storageManager.user.set({
                        ...userData,
                        platform: input.platformCode,
                        accountNo: input.accountNo, 
                    })
    
                    alarmService.getAll() // get all alarms
    
                    resolve(data as any);
                } else {
                    reject(response.message);
                }
            })    
        }
    )

    /** Description: Returns login informations. */
    public getLoginInformations = createAsyncThunk(
        'account/loginInformations',
        async () => {
            return new Promise(async (resolve, reject) => {
                const response: ApiResponse<ApiTypes.IGetCurrentLoginInformationsOutput> = await this._sessionService.getCurrentLoginInformations() as any;
                if (response.success) {
                    const { brokers, symbols, tradingAccountInfo, group, defaultWatchListSymbols } = response.data; // tradingPlatform
                    
                    if (!response.data.user) {
                        storageManager.user.remove();

                        let pathname = window.location.pathname.replaceAll(/\//ig, '');

                        if (pathname === 'external-login') {
                            window.location.href = "/"
                        }
                        else {
                            window.location.reload();
                        }
                    }

                    const user: ApiTypes.IUserLoginInfoDto = {
                        ...response.data.user,
                    };
                    
                    const data = {
                        brokers,
                        user,
                        symbols,
                        tradingAccountInfo,
                        secGroups: group?.secGroups,
                        defaultWatchListSymbols
                    };

                    resolve(data as any);

                } else {
                    const message = response.message;
                    reject(message);
                }
            })
        }
    )
}

export default AccountThunkService;
