import useTranslation from 'modules/localization';
import React from 'react';
import storeManager from 'store/manager';

interface Props {
    useFilter: [string, React.Dispatch<React.SetStateAction<string>>],
}

const WatchListHeader = (props: Props) => {
    const { t } = useTranslation();

    const [filter, setFilter] = props.useFilter;

    const openSymbolListModal = () => storeManager.modal.setSymbolList({ visibility: true, data: null })

    return (
        <div className="watchlist-header">
            <span className="title">{t('WatchList')}</span>
            <div className="controls">
                <div className="input-group">
                    <input 
                        className="search-input"
                        type="text" 
                        value={filter} 
                        onChange={(e) => setFilter(e.currentTarget.value.toUpperCase())} 
                        placeholder={t('Search')} />
                        <i className="icofont-search-2"></i>
                </div>
                <button className="symbols-button" onClick={openSymbolListModal}>
                    <i className="icofont-plus"></i>
                </button>
            </div>
        </div>
    )
}

export default WatchListHeader;