export enum SymbolFeedDirections {
    UP = 'up-direction',
    DEFAULT = 'default-direction',
    DOWN = 'down-direction'
}

export enum OrderTypes {
    LIMIT = 'LIMIT',
    MARKET = 'MARKET',
    STOP = 'STOP',
}

export enum OrderSides {
    SELL = 'SELL',
    BUY = 'BUY'
}

export enum ChartModes {
    MULTI,
    SINGLE
}

export const CMD = {
    BUY: 0,
    SELL: 1,
    BUY_MARKET: 0,
    SELL_MARKET: 1,
    BUY_LIMIT: 2,
    SELL_LIMIT: 3,
    BUY_STOP: 4,
    SELL_STOP: 5,
}