import HubBase from "../_base";
import { ITradeMasterHub } from "./types";
import getEncryptedAccessToken from "shared/utils/getEncryptedAccessToken";
import storeManager from "store/manager";
import { symbolService } from "services";
import { appConfig } from "app/config";

class TradeMasterHub extends HubBase implements ITradeMasterHub {
    constructor() {
        const accountNo = storeManager.account.getTraderNo() || '';
        const accessToken = getEncryptedAccessToken();
        
        const url = new URL(appConfig.socketServiceUrl + appConfig.hubs.trademaster);
        url.searchParams.append('login', accountNo.toString());
        url.searchParams.append('enc_auth_token', accessToken);

        // config.hubs.trademaster.url + '?login=' + accountNo.toString() + '&enc_auth_token=' + encodeURIComponent(accessToken)
        super(url.href);
    }

    // <Lifecycle>
    /** Description: Starts the TradeMasterHub connection. */
    start = () => {
        this.onConnectionDefine = () => {
            // Handler registrations
            this.connection?.on('receiveTradingAccountInfo', symbolService.updateOrderData);
            this.connection?.on('receiveConSymbol', symbolService.updateSymbolConfigData);
            this.connection?.on('groupConfig', symbolService.updateGroupConfigData);
        }

        return {
            then: (handleConnectionStart: Function) => {
                this.onConnectionStart = handleConnectionStart;
                this.connect();
            }
        };
    }

    /** Description: Updates the TradeMasterHub subscription list.*/
    update(subscriptionList: Array<string>) {
        if (subscriptionList !== this.subscriptions) {
            this.unsubscribe();
            this.subscriptions = subscriptionList;
            this.subscribe();
        }
    }

    /** Description: Closes the TradeMasterHub connection. */
    close() {
        this.disconnect();
    }
    // </Lifecycle>
}

export default TradeMasterHub;