import Api, { ApiTypes } from "api";
import { toast } from 'react-toastify';
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import { CMD } from "shared/symbol/constants";
import getCmd from "shared/utils/getCmd";
import Dependencies from "shared/app/dependencies";
import OrderValidator from "./validator";
import { thunkService } from "services";
import { OpenOrderInfo } from "shared/symbol/types";
import { ApiResponse } from "../../shared/api/constants";

class OrderProvider {
    private readonly _storeManager;
    private readonly _orderService;
    private readonly _orderValidator;
    private readonly _dispatch;
    public readonly ROW_TRANSACTION_ANIMATION_MS = 3000;

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
        this._dispatch = dependencies.dispatch;
        
        this._orderService = new Api.OrdersServiceProxy();
        this._orderValidator = new OrderValidator();
    }

    /** Description: Creates an order. */
    public async create() {
        return new Promise(async (resolve, reject) => {

            const accountNo = getAccountNo();
            const platformCode = getPlatformCode();
            const data = this._storeManager.modal.getOrderCreate().data;
    
            const inputDto: ApiTypes.CreateOrderInputDto = new Api.CreateOrderInputDto({
                accountNo: accountNo,
                platformCode: platformCode,
                cmd: getCmd(data.orderSide, data.orderType),
                price: data.price,
                volume: data.amount,
                sl: data.stopLoss,
                tp: data.takeProfit,
                symbol: data.symbol,
                expireDate: undefined,
            })
    
            const isValid = this._orderValidator.validateCreate(inputDto);
            if (isValid) {
                const response: ApiResponse<ApiTypes.CreateOrderOutputDto> = await this._orderService.create(inputDto) as any;
                if (response.success) {
                    const transaction = response.data.tradeTransaction;
                    const order: OpenOrderInfo = {
                        symbol: transaction.symbol || '...',
                        order: transaction.order,
                        type: 'Transaction',
                        volume: transaction.volume,
                        openPrice: 0,
                        openTime: '...',
                        sl: transaction.sl,
                        tp: transaction.tp,
                        commission: 0,
                        swap: 0,
                        profit: 0,
                        cp: 0,
                        pp: 0,
                        digits: 0,
                    }  

                    if (transaction.cmd >= 2) {
                        const orders = this._storeManager.order.getPending();
                        this._storeManager.order.setPending([...orders, order]);

                    } else {
                        const orders = this._storeManager.order.getOpen();
                        this._storeManager.order.setOpen([...orders, order]);
                    }
                    
                    // Row transaction animation
                    setTimeout(() => {
                        const orderRow = document.getElementById(`order-${transaction.order}`);
                        if (orderRow) {
                            orderRow.classList.add('create');
                        }

                        setTimeout(() => {
                            if (orderRow) {
                                orderRow.classList.remove('create');
                            }

                        }, this.ROW_TRANSACTION_ANIMATION_MS);
                        
                    }, 0); // TODO: should be outside of setTimeout method 

                } else {
                    toast(response.message);
                }

                resolve(response);
            } else {
                reject();
            }

        })
    }

    /** Description: Edits an order. */
    public async modify() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const data = this._storeManager.modal.getOrderModify().data;
        
        const inputDto = new Api.ModifyOrderInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            orderId: data.orderId,
            cmd: (CMD as any)[data.orderSide],
            price: data.price,
            sl: data.stopLoss,
            tp: data.takeProfit,
        });

        const response: ApiResponse<ApiTypes.ModifyOrderOutputDto> = await this._orderService.modify(inputDto) as any;
        if (response.success) {
            const transaction = response.data.tradeTransaction;

           // Row transaction animation
           setTimeout(() => {
                const orderRow = document.getElementById(`order-${transaction.order}`);
                if (orderRow) {
                    orderRow.classList.add('modify');
                }

                setTimeout(() => {
                    if (orderRow) {
                        orderRow.classList.remove('modify');
                    }

                }, this.ROW_TRANSACTION_ANIMATION_MS);
                
            }, 0); // TODO: should be outside of setTimeout method 


        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    /** Description: Closes an order. */
    public async close() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const data = this._storeManager.modal.getOrderClose().data;
        
        const inputDto = new Api.CloseOrderInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            orderId: data.orderId,
            cmd: (CMD as any)[data.orderType],
            symbol: data.symbol,
            volume: data.amount,
            price: data.price,
            sl: data.stopLoss,
            tp: data.takeProfit,
        })

        const response: ApiResponse<ApiTypes.CloseOrderOutputDto> = await this._orderService.close(inputDto) as any;
        if (response.success) {
            const transaction = response.data.tradeTransaction;
            const orders = this._storeManager.order.getOpen();
            this._storeManager.order.setOpen([...orders].filter((order) => order.order !== transaction.order));

        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    /** Description: Closes all of orders. */
    public async closeAll(input: any) {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const inputDto = new Api.CloseAllOrdersInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            closeSymbolType: input.closeSymbolType,
            operationType: input.operationType,
        })

        const response: ApiResponse<ApiTypes.CloseAllOrdersOutputDto> = await this._orderService.closeAllOrders(inputDto) as any;
        if (response.success && response.data.succesOrders?.length) {
            const succesOrders = response.data.succesOrders;
            const orders = this._storeManager.order.getOpen();
            this._storeManager.order.setOpen([...orders].filter(order => !succesOrders?.some((succesOrder) => succesOrder.order === order.order)));
        } else {
            toast('Multiple close failed.');
        }
    }

    /** Description: Deletes an order. */
    public async delete() {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();
        const data = this._storeManager.modal.getOrderDelete().data;

        const inputDto = new Api.DeleteOrderInputDto({
            accountNo: accountNo,
            platformCode: platformCode,
            orderId: data.orderId
        })

        const response: ApiResponse<ApiTypes.DeleteOrderOutputDto> = await this._orderService.delete(inputDto) as any;
        if (response.success) {
            const transaction = response.data.tradeTransaction;
            const orders = this._storeManager.order.getPending();
            this._storeManager.order.setPending([...orders].filter((order) => order.order !== transaction.order));

        } else {
            toast(response.message);
        }

        return Promise.resolve();
    }

    public getHistory(startDate: string, endDate: string) {
        return this._dispatch(thunkService.order.getOrderHistory({ startDate, endDate }));
    }
}

export default OrderProvider;