import Dependencies from "shared/app/dependencies";
import OrderProvider from "./provider";

class OrderService {
    public readonly provider;
    private readonly _storeManager;
    
    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
        this.provider = new OrderProvider(dependencies); 
    }

    changeOrderCreateData = (property: any, value: any) => {
        const createModal = this._storeManager.modal.getOrderCreate();

        if (createModal.data) {
            this._storeManager.modal.setOrderCreate({
                ...createModal, 
                data: { 
                    ...createModal.data,
                    [property]: value 
                }
            })
        }
    }
    
    addAmount = (amount: string, value: number) => {
        let quantity = parseFloat(amount);

        if (isNaN(quantity)) {
            this.changeOrderCreateData('amount', value.toFixed(2));

        } else {
            quantity += value;
    
            const amountValue = quantity.toFixed(2);
    
            this.changeOrderCreateData('amount', amountValue);
        }

    }


    subtractAmount = (amount: string, value: number, event: React.MouseEvent<any, MouseEvent>) => {
        event.preventDefault();
        let quantity = parseFloat(amount);

        if (isNaN(quantity)) {
            this.changeOrderCreateData('amount', 0);

        } else {
            quantity -= value;
            quantity = quantity > 0? quantity : 0;
    
            const amountValue = quantity.toFixed(2);
    
            this.changeOrderCreateData('amount', amountValue);
        }
    }
}

export default OrderService;