import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { SymbolState } from "./types";

const symbolState: SymbolState= {
    data: {},
    watchList: [],
    defaultWatchListSymbols: new Array<string>(),
    analysis: 'EURUSD',
    config: new StoreMiddlewareEntity({ data: [], status: EntityStatus.IDLE, updateTime: null }),
    secGroups: []
}

export default symbolState;