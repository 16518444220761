import React from 'react';
import { connect } from 'react-redux';
// Types
import { AppBottomFieldHeight } from 'store/slices/app-slice/types';
import { RootState } from 'store/types';
import bottomFieldResizerHelper from './bottom-field-resizer-helper';

export interface BottomFieldResizerProps {
    fieldHeight: AppBottomFieldHeight
}

const BottomFieldResizer = (props: BottomFieldResizerProps) => {
    const ref =  React.createRef<HTMLDivElement>();

    bottomFieldResizerHelper.updateProps(props);
    bottomFieldResizerHelper.updateRef(ref);

    React.useEffect(() => {
        bottomFieldResizerHelper.handleComponentMount();
    }, [])

    return (
        <div ref={ref} onMouseDown={bottomFieldResizerHelper.handleMouseDown} className="horizantal-line"></div>
    )
}

const mapStateToProps = (state: RootState) => ({
    fieldHeight: state.app.bottomFieldHeight,   
})

export default connect(mapStateToProps)(BottomFieldResizer);