import LoginFormWithRecaptcha from '../../../components/forms/login-form/login-form-with-recaptcha';

const LoginContainer = () => {

    return (
        <div className="login-container">
            <LoginFormWithRecaptcha />
        </div>
    )
}

export default LoginContainer;