import { Routes } from 'react-router-dom';
// Routes
import authRoutes from './routes/auth';
import mainRoutes from './routes/main';

const Router = () => {
    return (
        <Routes>
            {[...authRoutes]}
            {[...mainRoutes]}
        </Routes>
    )
}

export default Router;