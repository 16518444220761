import React from 'react';
import Radio from '../radio-button';
import { RadioButtonGroupProps, RadioButtonProps } from './interfaces';

const RadioButtonGroup = (props: RadioButtonGroupProps) => {
    const children = React.Children.toArray(props.children);

    /** Description: Changes radio button props. */
    const changeRadioButtonProps = (children: JSX.Element) => {
        let childrenProps = children.props;

        childrenProps = {
            ...childrenProps,
            selectedValue: props.value,
            check: function() {
                props.onChange!(this.value)
            } 

        } as RadioButtonProps

        return childrenProps;
    }

    return (
        <React.Fragment>
            {
                React.Children.map(children as any, (_children: JSX.Element) => {
                    let childrenProps = _children.props;

                    if (_children.type?.name === Radio.Button.name) {
                        childrenProps = changeRadioButtonProps(_children);
                    } 
                    
                    return (
                        React.cloneElement(_children, childrenProps)
                    )
                })
            }
        </React.Fragment>
    )
}

export default RadioButtonGroup;