/** Description: Enum of Themes. 
 * 
 * Keys: LIGHT, DARK
 */
export enum Themes {
    LIGHT = 'light',
    DARK = 'dark',
}

/** Description: Enum of Layout Modes. 
 * 
 * Keys: BASIC, ADVANCED
 */
export enum LayoutModes {
    BASIC = 'basic',
    ADVANCED = 'advanced'
}