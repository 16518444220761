import { ActionReducerMapBuilder } from "@reduxjs/toolkit"
import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { SymbolState } from "./types";
import { symbolService, thunkService } from "services";

class SymbolThunk {
    /** Description: Adds the "Symbol" method cases.  */
    public addCases = (builder: ActionReducerMapBuilder<SymbolState>) => {
        this.addGetTradingAccountInfoCase(builder);
        this.addLoginInformationsCase(builder);
    }

    /** Description: Adds the "getTradingAccountInfo" method cases. */
    private addGetTradingAccountInfoCase = (builder: ActionReducerMapBuilder<SymbolState>) => {
        // Event: onPending
        builder.addCase(thunkService.account.getTradingAccountInfo.pending, (state, action) => {
            state.config = new StoreMiddlewareEntity({
                data: null,
                status: EntityStatus.PENDING,
                updateTime: Date.now()
            })
        });

        // Event: onRejected
        builder.addCase(thunkService.account.getTradingAccountInfo.rejected, (state, action) => {
            state.config = new StoreMiddlewareEntity({
                data: action.error.message,
                status: EntityStatus.FAILED,
                updateTime: Date.now()
            })
        });

        // Event: onFulfilled
        builder.addCase(thunkService.account.getTradingAccountInfo.fulfilled, (state, action) => {
            const { symbols } = action.payload as any;

            state.defaultWatchListSymbols = (action.payload as any).defaultWatchListSymbols;

            state.config = new StoreMiddlewareEntity({
                data: symbols,
                status: EntityStatus.SUCCEEDED,
                updateTime: Date.now(),
            })
        });
    }

    /** Description: Adds the "getLoginInformations" method cases. */
    private addLoginInformationsCase = (builder: ActionReducerMapBuilder<SymbolState>) => {
        // Event: onFulfilled
        builder.addCase(thunkService.account.getLoginInformations.fulfilled, (state, action) => {
            const payload: any = action.payload;
            const symbols = payload.symbols;
            const secGroups = payload?.secGroups || null;
            
            if (secGroups) {
                symbols?.forEach((item: any) => symbolService.symbolSecGroup[item.symbol] = secGroups?.[item.type]);
            }

            state.defaultWatchListSymbols = payload.defaultWatchListSymbols;

            // symbolService.symbolSecGroups = 
            state.secGroups = secGroups;
            state.config = new StoreMiddlewareEntity({
                data: symbols,
                status: EntityStatus.SUCCEEDED,
                updateTime: Date.now()
            })
        });
    }
}

const symbolThunk = new SymbolThunk();
export default symbolThunk;
