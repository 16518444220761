const config = (window as any).appConfig;

export const appConfig = {
    apiServiceUrl: config.apiServiceUrl,
    socketServiceUrl: config.socketServiceUrl,
    termsOfUse: config.termsOfUse,
    privacyPolicy: config.privacyPolicy,
    strategiesIsActive: config.strategiesIsActive,
    pages: { 
        tvChart: {
            path: config.pages.tvChart,
            permission: 'Pages.TradeMaster'
        },
        login: {
            path: config.pages.login,
            permission: null
        },
        externalLogin: {
            path: config.pages.externalLogin,
            permission: null
        },
        register: {
            path: config.pages.register,
            permission: null
        },
        forgotPassword: {
            path: config.pages.forgotPassword,
            permission: null
        },
        resetPassword: {
            path: config.pages.resetPassword,
            permission: null
        },
        confirmEmail: {
            path: config.pages.confirmEmail,
            permission: null
        },
        trader: {
            path: '/',
            permission: 'Pages.TradeMaster'
        },
        tradingAccounts: {
            path: config.pages.tradingAccounts,
            permission: 'Pages.TradingAccounts'
        }
    },
    hubs: {
        trademaster: config.sockets.trademasterHub,
        feed: config.sockets.feedHub,
        indicator: config.sockets.indicatorHub 
    }
}