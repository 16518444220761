import useTranslation from 'modules/localization';
import React from 'react';
import { connect } from 'react-redux';
import { authService } from 'services';
import getPlatformCode from 'shared/utils/getPlatformCode';
import storeManager from 'store/manager';
import { AccountTraderNo } from 'store/slices/account-slice/types';
import { RootState } from 'store/types';

interface Props {
    traderNo: AccountTraderNo,
}

const HeaderMenu = (props: Props) => {
    const [platformCode, setPlatformCode] = React.useState('');
    const accountNo = props.traderNo;
    const { t } = useTranslation();

    React.useEffect(() => {
        setTimeout(() => {
            const code = getPlatformCode();
            setPlatformCode(code);
        });

    }, [props.traderNo])

    const handleLogoutClick = async () => {
        authService.logout();
    }

    const handleSettingsClick = () => {
        storeManager.modal.setSettings({ visibility: true, data: null })
    }

    const handleTradingAccountsClick = () => {
        storeManager.modal.setTradingAccounts({ visibility: true, data: null })
    }

    return (
        <div className="header-menu">
            <div className="column">
                <div className="tm-acc-title">{t('TradingAccounts')}</div>
                <div>{platformCode} #{accountNo}</div>
            </div>
            <div className="column tm-icon-wrapper">
                <div onClick={handleTradingAccountsClick} className="change-accounts"><i className="icofont-exchange"></i></div>
                <div className="settings" onClick={handleSettingsClick}><i className="icofont-gear"></i></div>
                <div onClick={handleLogoutClick}><i className="icofont-logout"></i></div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    traderNo: state.account.traderNo
})

export default connect(mapStateToProps)(HeaderMenu);