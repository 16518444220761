import useTranslation from 'modules/localization';
import React from 'react';
import AccordionListItem from '../src/item';

const ListTitleItem = () => {
    const { t } = useTranslation(); 

    return (
        <AccordionListItem title={t('DropdownTitle')} isExpandable={false} />
    )
}

export default ListTitleItem;