import { ApiTypes } from "api";
import storageManager from "modules/storage-manager";
import Dependencies from "shared/app/dependencies";
import { ChartModes } from "shared/symbol/constants";
import isNumber from "shared/utils/isNumber";
import ChartWrapperHelper from "./chart-wrapper-helper";

class ChartService {
    public wrapperHelper = new ChartWrapperHelper();

    private readonly _multiChartCount = 4;
    private readonly _storeManager;

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
    }

    /** Description: Sets the mode of the chart. */
    public setMode = (mode: ChartModes) => {
        const tabs = this.getTabs(mode);
        storageManager.preferences.update({ chartTabs: tabs });

        this._storeManager.chart.setMode(mode);
        this._storeManager.chart.setTabs(tabs);
        this._storeManager.chart.setSelectedTab(0);
    }

    /** Description: Sets the selected tab with index. */
    public setSelectedTab = (index: number) => {
        this._storeManager.chart.setSelectedTab(index);
        storageManager.preferences.update({ chartSelectedTab: index });
    }

    /** Description: Changes the selected tab. */
    public changeSelectedTab = (symbol: string) => {
        const tabs = [...this._storeManager.chart.getTabs()];

        const symbolIndex = tabs.findIndex((tab) => tab === symbol);
        if (symbolIndex !== -1) {
            this.setSelectedTab(symbolIndex);

        } else {
            const selectedTabIndex = this._storeManager.chart.getSelectedTab();
            tabs[selectedTabIndex] = symbol;

            this._storeManager.chart.setTabs(tabs);
            storageManager.preferences.update({ chartTabs: tabs });
        }
    }

    /** Description: Gets the chart tabs. */
    private getTabs = (mode: ChartModes) => {
        switch (mode) {
            case ChartModes.MULTI:
                return this.getMultiChartTabs();

            case ChartModes.SINGLE:
                return this.getSingleChartTab();

            default:
                return this.getSingleChartTab();
        }
    }

    /** Description: Gets multi chart tabs. */
    private getMultiChartTabs = () => {
        const selectedTab = this._storeManager.chart.getTabs()[this._storeManager.chart.getSelectedTab()];
        const watchList = this._storeManager.symbol.getWatchList();
        const symbolConfigData = this._storeManager.symbol.getConfig().data;
        const symbols = symbolConfigData.map((config: ApiTypes.ConSymbolDto) => config.symbol);

        let tabs = [selectedTab, ...watchList, ...symbols].filter((symbol, index, self) => self.indexOf(symbol) === index);
        tabs = tabs.slice(0, this._multiChartCount);

        return tabs;
    }

    /** Description: Gets single chart tabs. */
    private getSingleChartTab() {
        const tabs = this._storeManager.chart.getTabs();
        const selectedTab = this._storeManager.chart.getSelectedTab();

        return [tabs[selectedTab]];
    }


    public storeStorageValues = () => {
        this.storeStorageChartTabs();
        this.storeStorageSelectedChartTab();
    }

    private storeStorageChartTabs = () => {
        const storageValue = storageManager.preferences.get()?.chartTabs;
        if (storageValue) {
            const deleteStorageValue = () => storageManager.preferences.update({ chartTabs: undefined });
            
            if (Array.isArray(storageValue)) {
                const watchList = this._storeManager.symbol.getWatchList();
                const symbolCount = watchList?.length;
                const storageSymbolCount = storageValue.length;

                if (symbolCount >= storageSymbolCount) {
                    let shouldValueDelete = false;
                    
                    storageValue.forEach((symbol) => {
                        const isFound = watchList.findIndex((_symbol) => _symbol === symbol) !== -1;
                        if (!isFound) {
                            shouldValueDelete = true;
                        }
                    })

                    if (shouldValueDelete) {
                        deleteStorageValue();

                    } else {
                        this._storeManager.chart.setTabs(storageValue);

                        if (storageValue.length > 1) {
                            this._storeManager.chart.setMode(ChartModes.MULTI);    
                        }
                    }

                }  else {
                    deleteStorageValue();
                }

                
            } else {
                storageManager.preferences.update({ chartSelectedTab: undefined })
            }
        } 
    }

    private storeStorageSelectedChartTab = () => {
        const storageValue = storageManager.preferences.get()?.chartSelectedTab;
        if (storageValue) {
            const deleteStorageValue = () => storageManager.preferences.update({ chartSelectedTab: undefined });

            if (isNumber(storageValue)) {
                const tabCount = this._storeManager.chart.getTabs()?.length;
                const tabOrder = storageValue + 1;

                if ((tabOrder) > 0 && tabOrder <= tabCount) {
                    this._storeManager.chart.setSelectedTab(storageValue);

                } else {
                    deleteStorageValue();        
                }

            } else {
                deleteStorageValue();        
            }
        }
    }


}

export default ChartService;