import { createSlice } from '@reduxjs/toolkit';
import accountThunk from './middleware';
// States
import accountState from './state';
// Types
import { AccountReducers } from './types';

const accountSlice = createSlice({
    name: 'account',
    initialState: accountState,
    reducers: {
        setUser(state, action) { state.user = action.payload; },
        setTraderNo(state, action) { state.traderNo = action.payload; },
        setTradingStatus(state, action) { state.tradingStatus = action.payload },
        setLoginInformations(state, action) { state.loginInformations = action.payload }
    } as AccountReducers,
    extraReducers(builder) {
        accountThunk.addLoginInformationsCase(builder);
    },
});

export default accountSlice;