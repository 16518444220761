import React from 'react';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
// Components
import Modal from 'components/modal';
// Types
import { RootState } from 'store/types';
import { ModalOrderClose } from 'store/slices/modal-slice/types';
import useTranslation from 'modules/localization';
import { orderService } from 'services';
import { AppOneClickTrading } from 'store/slices/app-slice/types';

interface Props {
    modalData: ModalOrderClose,
    oneClickTrading: AppOneClickTrading
}

const OrderCloseModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    const orderData = props.modalData.data;
    const { orderId, symbol, orderType, amount } = orderData;

    const handleClose = () => storeManager.modal.setOrderClose({ visibility: false, data: null })

    const changeOrderData = (property: string, value: any) => {
        storeManager.modal.setOrderClose({
            ...props.modalData, 
            data: { 
                ...orderData,
                [property]: value 
            }
        })
    }

    const changeAmount = (amount: string) => {
        const decimalCount = amount.replace('.', ',').split(',')?.[1]?.length || 0;

        if (decimalCount <= 2) {
            changeOrderData('amount', amount)
        }
    }

    const handleSubmitClick = () => {
        if (!serviceAvailable) return;

        setServiceAvailable(false);

        orderService.provider.close()
            .then(() => handleClose())
            .finally(() => setServiceAvailable(true));
    }

    // one click trading 
    if (props.oneClickTrading) {
        orderService.provider.close()
            .finally(() => handleClose())

        return null;
    }

    return (
        <Modal title={t('CloseOrder')} id="order-close-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                <div className="description">
                    #{orderId} {orderType}
                </div>
                <div className="amount">
                    <label htmlFor="order-amount">{t('Quantity')}</label>
                    <input type="number" id="order-amount" step="0.01" min="0.01" value={amount} onChange={(e) => changeAmount(e.currentTarget.value)} />
                </div>
                <button type="submit" className="submit-button" disabled={!serviceAvailable} onClick={handleSubmitClick}>
                    {
                        serviceAvailable? 
                            t('Close') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    modalData: state.modal.orderClose,
    oneClickTrading: state.app.oneClickTrading
})

export default connect(mapStateToProps)(OrderCloseModal);