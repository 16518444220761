import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/types";
import Copyright from "./copyright";
import { EconomicCalendarProps } from "./types";
import Widget from "./widget";

const EconomicCalendar: React.FC<EconomicCalendarProps> = ({
  isTransparent = false,
  width = 600,
  height = 400,
  autosize = false,
  importanceFilter = "-1,0,1",
  currencyFilter = undefined,
  copyrightStyles,
  ...props
}) => {
  return (
    <div id="tradingview_widget_wrapper">
      <Widget
        scriptHTML={{
          colorTheme: props.theme,
          isTransparent,
          ...(!autosize ? { width } : { width: "100%" }),
          ...(!autosize ? { height } : { height: "100%" }),
          locale: props.locale,
          importanceFilter,
          currencyFilter,
          ...props,
        }}
        scriptSRC="https://s3.tradingview.com/external-embedding/embed-widget-events.js"
      ></Widget>
      <Copyright
        copyrightStyles={copyrightStyles}
        href={`https://www.tradingview.com/markets/currencies/economic-calendar/`}
        spanText={`Economic Calendar`}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  theme: state.app.theme
})

export default connect(mapStateToProps)(EconomicCalendar);