import { SignalTypes } from "shared/indicator/enums";

class SignalHelper {
    /** Description: Parses the signals as short, middle, long. */
    public parse = (signal: string): {
        short: SignalTypes,
        middle: SignalTypes,
        long: SignalTypes
    } => {
        if (signal) {
            const signals: Array<any> = signal.match(/.{2}/g) as any;

            if (signals?.length === 3) {
                const short: keyof typeof SignalTypes = signals[0];
                const middle: keyof typeof SignalTypes  = signals[1]; 
                const long: keyof typeof SignalTypes = signals[2]; 

                return {
                    short: SignalTypes[short],
                    middle: SignalTypes[middle],
                    long: SignalTypes[long],
                }
            }
        } 

        return {
            short: SignalTypes.UN,
            middle: SignalTypes.UN,
            long: SignalTypes.UN,
        }
    }
}

const signalHelper = new SignalHelper();
export default signalHelper;