import Dependencies from "shared/app/dependencies";
// Services
import AccountService from "./account";
import AlarmService from "./alarm";
import AppService from "./app";
import AuthService from "./auth";
import ChartService from "./chart";
import IndicatorService from "./indicator";
import OrderService from "./order";
import SymbolService from "./symbol";
import ThunkService from "./thunk";

// Statics
export let appService: AppService;
export let accountService: AccountService;
export let authService: AuthService;
export let symbolService: SymbolService;
export let indicatorService: IndicatorService;
export let orderService: OrderService;
export let chartService: ChartService;
export let alarmService: AlarmService;

// Classics
export const thunkService = new ThunkService(); 

class Services {
    injectDependencies = (dependencies: Dependencies.Root) => {
        appService = new AppService(dependencies);
        accountService = new AccountService(dependencies);
        authService = new AuthService(dependencies);
        symbolService = new SymbolService(dependencies);
        indicatorService = new IndicatorService(dependencies);
        orderService = new OrderService(dependencies);
        chartService = new ChartService(dependencies);
        alarmService = new AlarmService(dependencies);
    }
}

const services = new Services();
export default services;