import { IndicatorState } from "./types";

const indicatorState: IndicatorState = {
    level: null,
    pipVario: null,
    signal: null,
    trend: null,
    momentum: null,
    supportResistance: {},
}

export default indicatorState;