import React from 'react';
import useTranslation from 'modules/localization';
import { connect } from 'react-redux';
import { AppOneClickTrading } from 'store/slices/app-slice/types';
import { RootState } from 'store/types';

interface Props {
    oneClickTrading: AppOneClickTrading    
}

const OneClickTradingStatus = (props: Props) => {
    const { t } = useTranslation();

    if (!props.oneClickTrading) {
        return null;
    }

    return (
        <div className="one-click-trading-status">{t('OneClickTradingMode')}</div>
    )
}

const mapStateToProps = (state: RootState) => ({
    oneClickTrading: state.app.oneClickTrading
})

export default connect(mapStateToProps)(OneClickTradingStatus);