import storageManager from "modules/storage-manager";
import React from "react";
import { chartService } from "services";
import isNumber from "shared/utils/isNumber";
import storeManager from "store/manager";
import { BottomFieldResizerProps } from "./bottom-field-resizer";

class BottomFieldResizerHelper {    
    private _props!: BottomFieldResizerProps;
    private _ref!: React.RefObject<HTMLDivElement>;
    private _firstPosition: number | null = null;
    
    /** Description: Updates the bottom field resizer props. */
    public updateProps = (props: BottomFieldResizerProps) => {
        this._props = props;
    }

    /** Description: Updates the bottom field resizer ref. */
    public updateRef = (ref: React.RefObject<HTMLDivElement>) => {
        this._ref = ref;
    }

    /** Description: Handles the mount event of bottom field resizer component. */
    public handleComponentMount = () => {
        const storageValue = this.getStoragePositionValue();
        if (storageValue) {
            storeManager.app.setBottomFieldHeight(storageValue);                
        }
    }
    
    /** Description: Handles the mouse down event of bottom field resizer component. */
    public handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        document.body.style.cursor = 'row-resize';

        // For chart bug
        chartService.wrapperHelper.create();
    
        // Get first position
        this._firstPosition = event.clientY;
    
        document.onmousemove = (e) => this.handleMouseMove(e);
        document.onmouseup = () => this.handleMouseUp();

        if (this._ref.current) {
            this._ref.current?.classList.add('hover');
        }
    }

    /** Description: Handles the mouse move event of bottom field resizer component. */
    private handleMouseMove = (event: MouseEvent) => {
        const position = event.clientY;

        if (this._firstPosition && position) {
            const height = this._firstPosition - position; 
            const currentHeight = this._props.fieldHeight;
            const fieldHeight = currentHeight + height;

            if (fieldHeight > 0) {
                this.setFieldHeight(fieldHeight);
            }
        
            window.getSelection()?.removeAllRanges();

            
            this._firstPosition = position;
        }
    }

    /** Description: Handles the mouse up event of bottom field resizer component. */
    private handleMouseUp = () => {
        const reset: any = null;
        document.body.style.cursor = reset; 

        // For chart bug
        chartService.wrapperHelper.remove();
        
        document.onmousemove = null;
        document.onmouseup = null;

        this._firstPosition = null;

        if (this._ref.current) {
            this._ref.current?.classList.remove('hover');
        }
    }

    /** Description: Sets the field height of bottom section. */
    public setFieldHeight = (height: number) => {
        storeManager.app.setBottomFieldHeight(height);
        // Update the local storage
        storageManager.preferences.update({ datatableHeight: height });
    }

    /** Description: Returns the stored value in local storage, if its exist. */
    private getStoragePositionValue = () => {
        let result: number | null = null;

        const storageValue = storageManager.preferences.get()?.datatableHeight;
        
        if (storageValue && isNumber(storageValue)) {
            result = storageValue;

        } else {
            storageManager.preferences.update({ datatableHeight: undefined });
        }

        return result;        
    }
}

const bottomFieldResizerHelper = new BottomFieldResizerHelper();
export default bottomFieldResizerHelper;