import { Bar, LibrarySymbolInfo, ResolutionString, SubscribeBarsCallback } from 'chart/charting_library/datafeed-api';
import { resolutionSeconds } from './constants';

type Handler = {
    id: string,
    channel: string,
    resolution: ResolutionString,
    callback: SubscribeBarsCallback,
    lastBar: Bar | null 
}

class StreamingProvider {
    private _lastBarCache: Bar | null = null;
    private _subscriptionItem: Handler | null = null;

    /** Description: Subscribes the FeedHub. */
    public subscribe = (symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void) => {
        this._subscriptionItem = {
            id: listenerGuid,
            channel: symbolInfo.name,
            resolution,
            callback: onTick,
            lastBar: this._lastBarCache,
        }

        console.log('[subscribeOnStream]: ', this._subscriptionItem.channel);
    }

    /** Description: Handles the FeedHub's onMessage event. */
    public onMessage = (data: any) => {
        const channelString = data.symbol;

        if (this._subscriptionItem?.channel === channelString && this._subscriptionItem?.lastBar && this._lastBarCache) {
            const lastBar: Bar = {...this._subscriptionItem.lastBar};
            const tradePrice = data.bid;
            const tradeTime = data.time;
    
            const resolution = this._subscriptionItem.resolution;
            const resolutionAsSecond = resolutionSeconds[resolution];
            const nextBar = this._lastBarCache.time + (resolutionAsSecond * 1000);
            
            let bar: Bar;
            if (tradeTime >= nextBar) {
                bar = {
                    time: nextBar,
                    open: this._lastBarCache.close,
                    high: tradePrice,
                    low: tradePrice,
                    close: tradePrice,
                };

                this._lastBarCache.time = nextBar;
                this._lastBarCache.open = bar.open;
                this._lastBarCache.close = bar.close;
                this._lastBarCache.high = tradePrice;
                this._lastBarCache.low = tradePrice;
                
            } else {
                bar = {
                    ...lastBar,
                    open: this._lastBarCache.open,
                    high: this._lastBarCache.high,
                    low: this._lastBarCache.low,
                    close: tradePrice,
                };

                this._lastBarCache = {
                    ...bar,
                    high: Math.max(this._lastBarCache.high, tradePrice),
                    low: Math.min(this._lastBarCache.low, tradePrice)
                };
            }
    
            this._subscriptionItem.lastBar = {...bar};
            this._subscriptionItem.callback({...bar});
        }
     
        return Promise.resolve();
    }

    // <_LastBarCache>
    /** Description: Resets the last bar. */
    public resetLastBar = () => {
        this._lastBarCache = null
        this._subscriptionItem = null;
    }

    /** Description: Returns true, if the last bar is empty. */
    public isLastBarEmpty = () => {
        return !this._lastBarCache;
    }

    /** Description: Sets the last bar. */
    public setLastBar = (bar: Bar) => {
        this._lastBarCache = bar;
    }
    // </_LastBarCache>
}

const streamingProvider = new StreamingProvider();
export default streamingProvider;