export const apiResolutions: any = {
	'1': 'M1',
	'5': 'M5',
	'15': 'M15',
	'30': 'M30',
	'60': 'H1',
	'240': 'H4',
	'1D': 'D1',
	'1W': 'W1',
	'1M': 'MN1',
}

export const resolutionSeconds: any = {
	'1': 60,
	'5': 300,
	'15': 900,
	'30': 1800,
	'60': 3600,
	'240': 14400,
	'1D': 86400,
	'1W': 604800,
	'1M': 2419200,
}

/**
 * Limitation of the Amount of History Data

	Server has the following limitations on the history data:

	65536 bars for M1 charts (equal to several months);
	32768 bars for M5 charts (equal to about 6 months);
	16384 bars for M15, M30, H1, H4, and D1 charts;
	1024 bars for W1 charts;
	256 bars for MN1 charts.
 */
export const barSecondLimitations: any = {
	'1': 65536 * resolutionSeconds['1'],
	'5': 32768 * resolutionSeconds['5'],
	'15': 16384 * resolutionSeconds['15'],
	'30': 16384 * resolutionSeconds['30'],
	'60': 16384 * resolutionSeconds['60'],
	'240': 16384 * resolutionSeconds['240'],
	'1D': 16384 * resolutionSeconds['1D'],
	'1W': 1024 * resolutionSeconds['1W'],
	'1M': 256 * resolutionSeconds['1M'],
} 

// export const barSecondLimitations: any = {
// 	'1': 65536 * 60 * 1.3,
// 	'5': 32768 * 60 * 2 * 5 * 1.3,
// 	'15': 16384 * 60 * 1.3,
// 	'30': 16384 * 60 * 1.3,
// 	'60': 16384 * 60 * 40 * 1.3,
// 	'240': 16384 * 60 * 1.3,
// 	'1D': 16384 * 60 * 24 * 1.3,
// 	'1W': 1024 * 60 * 24 * 7 * 1.3,
// 	'1M': 256 * 60 * 24 * 30 * 1.3,
// } 