import streamingProvider from "chart/src/streaming-provider";
import storageManager from "modules/storage-manager";
import { alarmService } from "services";
import Dependencies from "shared/app/dependencies";
import { OrderData } from "shared/socket/types";
import { OpenOrderInfo, PendingOrderInfo, SymbolConfigData, SymbolFeedData, TradingStatusData } from "shared/symbol/types";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import { SymbolConfig, SymbolSecGroup } from "store/slices/symbol-slice/types";

class SymbolService {
    public readonly MIN_COLOR_CHANGE_TIME = 500; // 0.5s
    public symbolSecGroup: {[key: string]: SymbolSecGroup} = {}; 

    private readonly _storeManager;
    private _prevPrices: {[prop: string]: {
        ask: number,
        bid: number,
        updatedTime: number
    }} = {};

    constructor(dependencies: Dependencies.Root) {
        this._storeManager = dependencies.storeManager;
    }

    // <SocketHandler>
    /** Description: Updates the symbol feed data. (WebSocket.FeedHub) */
    public updateSymbolFeedData = async (feedData: SymbolFeedData) => {
        feedData.time = Date.now();
        
        // Data manupulations
        const markUpData = this.markUp(feedData);

        // save data
        this.updatePrevPrice(markUpData);
        this._storeManager.symbol.updateData(markUpData);
        
        alarmService.checkAlarmOnStream(markUpData);
        
        streamingProvider.onMessage(feedData);
    }

    /** Description: Updates the symbol config data. (WebSocket.AuctionHub) */
    public updateSymbolConfigData = (data: SymbolConfigData[]) => {
        // this._storeManager.symbol.setConfig(data)
    }
    // </SocketHandler>

    private markUp = (feedData: SymbolFeedData) => {
        let markUpData = feedData;

        const spreadDiff = this.symbolSecGroup?.[feedData.symbol]?.spreadDiff; 
        if (spreadDiff !== undefined && feedData.point) {
            const spreadDiffHalf = Math.round(spreadDiff / 2); 
            
            markUpData.bid = markUpData.bid - spreadDiffHalf * feedData.point; 
            markUpData.ask = markUpData.ask - (spreadDiff - spreadDiffHalf) * feedData.point; 
        }

        return markUpData;
    }

    private updatePrevPrice = (feedData: SymbolFeedData) => {
        const symbol = feedData.symbol;
        const feedStoreData = this._storeManager.symbol.getData();
        const hasSymbol = feedStoreData.hasOwnProperty(symbol);
        if (hasSymbol) {
            const symbolData = feedStoreData[symbol];
            
            this._prevPrices[symbol] = {
                ask: symbolData.ask,
                bid: symbolData.bid,
                updatedTime: symbolData.time
            }

        } else {
            this._prevPrices[symbol] = {
                ask: feedData.ask,
                bid: feedData.bid,
                updatedTime: feedData.time
            }
        }

    }

    public getPrevPrices = (symbol: string) => {
        const hasSymbol = this._prevPrices.hasOwnProperty(symbol);

        if (hasSymbol) { 
            return this._prevPrices[symbol];
        }

        return null;
    }

    public setConfig = (data: SymbolConfig) => {
        this._storeManager.symbol.setConfig(data)
    }

    // <Handler>
    /** Description: Updates the order history data. (WebSocket.TradeMaster) */
    public updateOrderData = (response: OrderData) => {
        this.updateTradingStatusData(response);
        this.updateOpenOrderData(response.openOrdersInfo);
        this.updatePendingOrderData(response.pendingOrdersInfo);
    }

    /** Description: Updates the symbol group data. (WebSocket.TradeMaster) */
    public updateGroupConfigData = (data: any) => {
        // console.log('group-config', data);
    } 
    // </Handler>

    public storeStorageValues = () => {
        this.storeStorageWatchList();
    }

    private storeStorageWatchList = () => {
        const storageValue = storageManager.preferences.get()?.watchList;
        if (storageValue) {
            const accountNo = getAccountNo();
            const platformCode = getPlatformCode();

            const storageWatchList = storageValue?.[platformCode]?.[accountNo];
            
            if (Array.isArray(storageWatchList) && storageWatchList?.length) {
                this._storeManager.symbol.setWatchList([...storageWatchList]);
            } 
        }
    }

    /** Description: Updates the trading account status data. 
    * 
    * Data: Balance, Equity, Credit, Margin, Free-Margin, Margin-Level, Volume, Profit/Loss */
    private updateTradingStatusData = (data: TradingStatusData) => {
        this._storeManager.account.setTradingStatus({
            balance: data.balance,
            credit: data.credit,
            equity: data.equity,
            margin: data.margin,
            marginFree: data.marginFree,
            marginLevel: data.marginLevel,
            volume: data.volume,
            profit: data.equity - data.balance,
        });
    }

    /** Description: Updates the open order (open-positions) data. */
    private updateOpenOrderData = (data: OpenOrderInfo[]) => {
        this._storeManager.order.setOpen(data);
    }

    /** Description: Updates the pending order (pending-positions) data. */
    private updatePendingOrderData = (data: PendingOrderInfo[]) => {
        this._storeManager.order.setPending(data);
    }
}

export default SymbolService;