import getToken from "shared/utils/getToken";
import { DateTime } from 'luxon';

class AuthorizationHelper {
    public checkTokenExpiration = () => {
        const token = getToken();
        if (token) {
            const encodedPayload = token.split('.')![1];
            const decodedPayload = window.atob(encodedPayload);
            const payload = JSON.parse(decodedPayload);

            const expiration = payload.exp;
            const currentTime = DateTime.now().toSeconds();

            if (expiration > currentTime) {
                return true;
            }
        }

        return false;
    }
    
}

const authorizationHelper = new AuthorizationHelper();
export default authorizationHelper;