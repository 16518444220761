import React from 'react';
import { connect } from 'react-redux';
import socket from 'socket';
import storeManager from 'store/manager';
import indicatorState from 'store/slices/indicator-slice/state';
import { SymbolAnalysis } from 'store/slices/symbol-slice/types';
import { RootState } from 'store/types';

interface Props {
    analysis: SymbolAnalysis
}

const IndicatorHub = (props: Props) => {
    React.useEffect(() => {
        socket.createIndicatorSocket();

        return (() => {
            socket.indicator.close();
        })
    }, [])

    React.useEffect(() => {
        if (socket.indicator) {
            const resetIndicators = () => {
                // Change current indicator values with default values
                storeManager.indicator.setLevel(indicatorState.level);
                storeManager.indicator.setSignal(indicatorState.signal);
                storeManager.indicator.setTrend(indicatorState.trend);
                storeManager.indicator.setMomentum(indicatorState.momentum);
            }
    
            const changeSubscription = () => {
                const subscriptions = [props.analysis];
                socket.indicator.update(subscriptions);
            }    
            
            const isConnected = socket.indicator.getStatus();
            if (!isConnected) {
                socket.indicator.start()
                .then(() => changeSubscription())
            } else {
                resetIndicators();
                changeSubscription();
            }

        } else {
            console.error('ERROR! Indicator socket is not connected.');
            
        }

    }, [props.analysis])



    return null;
}

const mapStateToProps = (state: RootState) => ({
    analysis: state.symbol.analysis,
})

export default connect(mapStateToProps)(IndicatorHub);