import React, { useState } from 'react';
import storeManager from 'store/manager';
import { LoginFormProps } from './interfaces';
import useTranslation from 'modules/localization';
import { authService } from 'services';
import Api, { ApiTypes } from 'api';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import removeCaptcha from '../../../shared/utils/removeCaptcha';
import { appConfig } from 'app/config';

const LoginForm = (props: LoginFormProps) => {
    const [serviceAvailable, setServiceAvailable] = useState(true);
    const { t, changeLanguage } = useTranslation();

    let useCaptchaOnLogin: boolean = props.useCaptchaOnLogin || false;

    const [input, setInput] = useState(new Api.AuthenticateModel({
        userNameOrEmailAddress: '',
        password: '',
        rememberClient: false,
        singleSignIn: false,
        returnUrl: undefined,
        captchaResponse: '',
        twoFactorRememberClientToken: undefined,
        twoFactorVerificationCode: undefined
    }) as any);

    const [validation, setValidation] = useState({ userNameOrEmailAddress: '', password: '' });

    const [validationSummary, setValidationSummary] = useState('');

    const { executeRecaptcha } = useGoogleReCaptcha();

    /** Description: Handles the form's "onSubmit" event. */
    const handleLoginClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();

        if (!serviceAvailable) { return; }

        let validateLoginResult: boolean = validateForm(input);

        if (!validateLoginResult) { return; }
        setServiceAvailable(false);

        if (useCaptchaOnLogin) {

            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const token = await executeRecaptcha();
            // Do whatever you want with the token

            if (token !== undefined && token.length > 0) {
                input.captchaResponse = token;
            }
        }

        authService.login(input)
            .then((data) => {
                if (!data.success) {
                    setValidationSummary(data.message)
                }
            })
            .finally(() => {
                setServiceAvailable(true);
                removeCaptcha();
            });
    }

    const validateForm = (input: ApiTypes.AuthenticateModel): boolean => {

        let newMessages = {} as any;

        if (input.userNameOrEmailAddress.length === 0) {
            newMessages.userNameOrEmailAddress = t('ValidationRequired', t('Username'));
        }
        else if (input.userNameOrEmailAddress.length > 256) {
            newMessages.userNameOrEmailAddress = t('ValidationMaxLength', t('Username'), 256);
        }

        if (input.password.length === 0) {
            newMessages.password = t('ValidationRequired', t('Password'));
        }
        else if (input.password.length > 32) {
            newMessages.password = t('ValidationMaxLength', t('Password'), 32);
        }

        setValidationSummary('');
        setValidation(newMessages);

        return Object.keys(newMessages).length === 0;
    }

    //const handleRememberMe = () => {
    //    const current = input.rememberClient;
    //    setInput((input: any) => ({ ...input, ['rememberClient']: !current }));
    //};

    /** Description: Handles the "onClick" event of the "Register" button. */
    const handleRegisterClick = () => {
        storeManager.app.setLocation(appConfig.pages.register.path);
    }

    const handleForgotPasswordClick = () => {
        storeManager.app.setLocation(appConfig.pages.forgotPassword.path);
    }

    React.useEffect(() => {
        const privacyPolicy = document.getElementById('privacy-policy');
        if (privacyPolicy) {
            privacyPolicy.onclick = () => window.open(appConfig.privacyPolicy);
        }

        const termOfUse = document.getElementById('terms');
        if (termOfUse) {
            termOfUse.onclick = () => window.open(appConfig.termsOfUse);
        }

    }, [props])

    return (
        <div className="login-form">
            <h2 className="tm-title">{t('Login')}</h2>
            <form>
                <div className={`form-group${validation.userNameOrEmailAddress ? " has-validation" : ""}`}>
                    <label htmlFor="user-id">{t('Username')}</label>
                    <div className="textbox">
                        <div className="input-group">
                            <input disabled={!serviceAvailable} type="text" id="user-id" value={input.userNameOrEmailAddress} onChange={(e) => { setInput({ ...input, userNameOrEmailAddress: e.currentTarget.value.trim() }); }} onKeyUp={(e) => { validateForm(input); }} name="userNameOrEmailAddress" />
                            <i className="icofont-ui-user"></i>
                        </div>
                        {validation.userNameOrEmailAddress && <div className="invalid-tooltip">
                            {validation.userNameOrEmailAddress}
                        </div>}
                    </div>
                </div>
                <div className={`form-group${validation.password ? " has-validation" : ""}`}>
                    <label htmlFor="password">{t('Password')}</label>
                    <div className="textbox">
                        <div className="input-group">
                            <input disabled={!serviceAvailable} type="password" id="password" value={input.password} onChange={(e) => { setInput({ ...input, password: e.currentTarget.value.trim() }); }} onKeyUp={(e) => { validateForm(input); }} autoComplete="new-password" />
                            <i className="icofont-ui-password"></i>
                        </div>
                        {validation.password && <div className="invalid-tooltip">
                            {validation.password}
                        </div>}
                    </div>
                </div>
                {validationSummary && <div className="form-group tm-form-val has-validation"><div className="textbox">
                    <div className="invalid-tooltip">
                        {validationSummary}
                    </div>
                </div>
                </div>}


                <div className="description" dangerouslySetInnerHTML={{ __html: t('TermText') }}></div>
                <div className="description" dangerouslySetInnerHTML={{ __html: t('RiskyText') }}></div>

                {/*<div className="form-row">*/}
                {/*    <div className="remember-me">*/}
                {/*        <input type="checkbox" id="rememberMe" name="rememberMe" checked={input.rememberClient} onClick={handleRememberMe} />*/}
                {/*        <label >{t('RememberMe')}</label>*/}
                {/*    </div>*/}

                {/*    <div className="description" onClick={handleForgotPasswordClick} >*/}
                {/*        <span>{t('ForgotPassword')}</span>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="description" onClick={handleForgotPasswordClick} >
                    <span>{t('ForgotPassword')}</span>
                </div>

                <button disabled={!serviceAvailable} type="submit" className="login" onClick={handleLoginClick}>{serviceAvailable ? t('Login') : t('Loading')}</button>
                <button disabled={!serviceAvailable} type="button" className="register btn-outline" onClick={handleRegisterClick}>{t('CreateDemoAccount')}</button>
            </form>
            <div className="language-selector">
                <div className="language" onClick={() => changeLanguage('en')}>
                    <span>English</span>
                    <i className="flag-icon flag-icon-gb"></i>
                </div>
                |
                <div className="language" onClick={() => changeLanguage('de')}>
                    <i className="flag-icon flag-icon-de"></i>
                    <span>Deutsch</span>
                </div>
            </div>
        </div>
    )
}

export default LoginForm;