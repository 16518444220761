import React from 'react';
import { connect } from 'react-redux';
import getAccountNo from 'shared/utils/getAccountNo';
import getPlatformCode from 'shared/utils/getPlatformCode';
import socket from 'socket';
import { AccountTraderNo } from 'store/slices/account-slice/types';
import { RootState } from 'store/types';

interface Props {
    traderNo: AccountTraderNo
}

const TradeMasterHub = (props: Props) => {
    React.useEffect(() => {
        socket.createTradeMasterSocket();
        
        socket.tradeMaster.start()
        .then(() => changeSubscription())
        
        return (() => {
            socket.tradeMaster.close();
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.traderNo])

    const changeSubscription = () => {
        const platformCode = getPlatformCode();
        const accountNo = getAccountNo();
    
        const subscriptions = [platformCode + "_" + accountNo];
        socket.tradeMaster.update(subscriptions);
    }  

    return null;
}


const mapStateToProps = (state: RootState) => ({
    traderNo: state.account.traderNo,
})

export default connect(mapStateToProps)(TradeMasterHub);