import React, { useEffect, useState } from 'react';
import useTranslation from 'modules/localization';
import storeManager from 'store/manager';
import { appConfig } from 'app/config';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AccountServiceProxy, ResetPasswordInput, ResetPasswordOutput } from '../../../api/service-proxies';

const ResetPasswordForm = () => {

    const location = useLocation();

    const { t, changeLanguage } = useTranslation();

    const [inProgress, setInProgress] = useState(false);

    const [input, setInput] = useState(new ResetPasswordInput() as any);
    const [confirmPassword, setConfirmPassword] = useState('');

    const [validation, setValidation] = useState({ password: '', confirm: '' });

    const _accountServiceProxy = new AccountServiceProxy();

    useEffect(() => {
        if (location.search && !input.c) {
            const params = new URLSearchParams(location.search);
            const code = params.get('c') as string;
            setInput({ ...input, c: code });
        }
    })

    useEffect(() => {

        if (input.password !== undefined) {
            validatePassword(input.password, confirmPassword);
        }

    }, [input, confirmPassword])

    const validatePassword = (password: string, confirm: string): boolean => {

        let newMessages = {} as any;

        if (password.length == 0) {
            newMessages.password = t('ValidationRequired', t('Password'));
        }
        else if (password.length > 32) {
            newMessages.password = t('ValidationMaxLength', t('Password'), 32);
        }
        else if (password.length < 6) {
            newMessages.password = t('ValidationMinLength', t('Password'), 6);
        }

        if (confirm.length == 0) {
            newMessages.confirm = t('ValidationRequired', t('ConfirmPassword'));
        }
        else if (password !== confirm) {
            newMessages.confirm = t('ValidationCompare', t('ConfirmPassword'));
        }

        setValidation(newMessages);

        return Object.keys(newMessages).length === 0;
    }

    /** Description: Handles the form's "onSubmit" event. */
    const handleSubmitClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();

        let validateLoginResult: boolean = validatePassword(input.password, confirmPassword);
        if (!validateLoginResult) { return; }

        setInProgress(true);

        _accountServiceProxy.resetPassword(input)
            .then((result) => {
                setInProgress(false);
                storeManager.app.setLocation(appConfig.pages.login.path);
            })
            .catch((_error: any) => {
                console.log(_error);
            })
            .finally(() => {
                
            });

    }

    const handleCloseClick = () => {
        storeManager.app.setLocation(appConfig.pages.login.path);
    }

    return (
        <div className="reset-password-form">
            <h2 className="tm-title">{t('ChangePassword')}</h2>
            <form>
                <div className="description" >{t('PleaseEnterYourNewPassword')}</div>

                <div className={`form-group${validation.password ? " has-validation" : ""}`}>
                    <label htmlFor="password">{t('Password')}</label>
                    <div className="textbox">
                        <div className="input-group">
                            <input type="password" id="password" value={input.password} onChange={(e) => { setInput({ ...input, password: e.currentTarget.value.trim() }); }} name="password" />
                            <i className="icofont-ui-password"></i>
                        </div>
                        {validation.password && <div className="invalid-tooltip">
                            {validation.password}
                        </div>}
                    </div>
                </div>
                <div className={`form-group${validation.confirm ? " has-validation" : ""}`}>
                    <label htmlFor="confirmPassword">{t('ConfirmPassword')}</label>
                    <div className="textbox">
                        <div className="input-group">
                            <input type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.currentTarget.value.trim()); }} name="confirmPassword" />
                            <i className="icofont-ui-password"></i>
                        </div>
                        {validation.confirm && <div className="invalid-tooltip">
                            {validation.confirm}
                        </div>}
                    </div>
                </div>

                <button type="submit" className="submit" onClick={handleSubmitClick}>{!inProgress ? t('Submit') : t('Loading')}</button>
                <button type="button" className="submit btn-outline" onClick={handleCloseClick}><i className="icofont-double-left"></i>{t('Close')}</button>
            </form>
            <div className="language-selector">
                <div className="language" onClick={() => changeLanguage('en')}>
                    <span>English</span>
                    <i className="flag-icon flag-icon-gb"></i>
                </div>
                |
                <div className="language" onClick={() => changeLanguage('de')}>
                    <i className="flag-icon flag-icon-de"></i>
                    <span>Deutsch</span>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordForm;