import storageManager from "modules/storage-manager";
import Dependencies from "shared/app/dependencies";
import { Themes } from "shared/app/enums";
import isEnumMember from "shared/utils/isEnumMember";

class ThemeManager {
    public default = Themes.DARK;
    public readonly themes = Themes;
    
    private readonly _themeQualifiedName: string = 'theme';
    private readonly _document = window.document.documentElement;     
    private readonly _storeManager;
    
    constructor(dependencies: Dependencies.StoreManager) {
        this._storeManager = dependencies.storeManager;

        const storageValue = this.getStorageValue();
        if (storageValue) {
            this.default = storageValue;
        }
    }

    /** Description: Returns the current app theme. */
    get() {
        const theme = this._storeManager.app.getTheme();
        return theme;
    }

    /** Description: Changes the app theme. */
    set(theme: Themes) {
        this._storeManager.app.setTheme(theme);
        this._document.setAttribute(this._themeQualifiedName, theme);
        storageManager.preferences.update({ theme: theme });
    }
    
    /** Description: Changes the app theme as default. */
    setAsDefault() {
        this.set(this.default);
    }

    /** Description: Returns the stored value in local storage, if its exist. */
    private getStorageValue = () => {
        let result: Themes | null = null;
        
        const storageValue = storageManager.preferences.get()?.theme;
        if (storageValue) {
            const isMember = isEnumMember(Themes, storageValue);
            if (isMember) {
                result = storageValue as Themes;

            } else {
                storageManager.preferences.update({ theme: undefined }) // deletes the unrecognized value
            } 
        }

        return result;
    }
}

export default ThemeManager;