import { accountService, chartService, indicatorService, thunkService } from "services";
import Dependencies from "shared/app/dependencies";
// Managers
import LanguageManager from "./language-manager";
import LayoutModeManager from "./layout-mode-manager";
import OneClickTradingManager from "./one-click-trading-manager";
import ThemeManager from "./theme-manager";

class AppService {
    public readonly language;
    public readonly theme;
    public readonly layoutMode;
    public readonly oneClickTrading;

    private readonly _dispatch;

    constructor(dependencies: Dependencies.Root) {
        this.language = new LanguageManager();
        this.theme = new ThemeManager(dependencies);
        this.layoutMode = new LayoutModeManager(dependencies);
        this.oneClickTrading = new OneClickTradingManager(dependencies);

        this._dispatch = dependencies.dispatch;
    }

    /** Description: Handles application start event. */
    public onInit = async () => {
        await this.getConfig();

        this.theme.setAsDefault();
        this.layoutMode.setAsDefault();
        this.oneClickTrading.setAsDefault();
        
        accountService.storeStorageValues();
        indicatorService.storeStorageValues();
        chartService.storeStorageValues();
    }

    /** Description: Gets login information and app config data. */
    public getConfig = async () => {
        let app;
        let user;
        
        await this.getAppConfig()
        .then(async (appConfig) => {
            app = appConfig?.payload;

            await accountService.getLoginInformations()
            .then((userConfig) => {
                user = userConfig?.payload;
            });
        });

        return {
            app,
            user
        }
    } 

    /** Description: Gets app config data. */
    public async getAppConfig() {
        return this._dispatch(thunkService.app.getAppConfig())
    }
}

export default AppService;