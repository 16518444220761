import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { TradingStatusData } from "shared/symbol/types";
import { AccountState } from "./types";
import { thunkService } from "services";

class AccountThunk {
    /** Description: Adds the "getLoginInformations" method cases to extra reducers in the Account Slice.  */
    public addLoginInformationsCase = (builder: ActionReducerMapBuilder<AccountState>) => {
        // Event: onPending
        builder.addCase(thunkService.account.getLoginInformations.pending, (state, action) => {
            state.loginInformations = new StoreMiddlewareEntity({
                data: null,
                status: EntityStatus.PENDING,
                updateTime: Date.now(),
            })
        })

        // Event: onRejected
        builder.addCase(thunkService.account.getLoginInformations.rejected, (state, action) => {
            state.loginInformations = new StoreMiddlewareEntity({
                data: action.error.message,
                status: EntityStatus.FAILED,
                updateTime: Date.now(),
            })
        })

        // Event: onFulfilled
        builder.addCase(thunkService.account.getLoginInformations.fulfilled, (state, action) => {
            const { user, symbols, tradingAccountInfo,  ...loginInformations } = action.payload as any;

            state.user = user;  
            state.loginInformations = new StoreMiddlewareEntity({
                data: loginInformations,
                status: EntityStatus.SUCCEEDED,
                updateTime: Date.now(),
            })

            if (tradingAccountInfo) {
                const tradingStatusData = {
                    balance: tradingAccountInfo.balance,
                    credit: tradingAccountInfo.credit,
                    equity: tradingAccountInfo.equity,
                    margin: tradingAccountInfo.margin,
                    marginFree: tradingAccountInfo.marginFree,
                    marginLevel: tradingAccountInfo.marginLevel,
                    volume: tradingAccountInfo.volume,
                    profit: tradingAccountInfo.equity - tradingAccountInfo.balance,
                }
                
                state.tradingStatus = tradingStatusData;
            }
        })

        /// TODO: should be fixed
        //////////////////////////////////////
        builder.addCase(thunkService.account.getTradingAccountInfo.fulfilled, (state, action) => {
            const payload = action.payload as any;
            const tradingAccountInfo: TradingStatusData = payload.tradingAccountInfo;

            if (tradingAccountInfo) {
                const tradingStatusData = {
                    balance: tradingAccountInfo.balance,
                    credit: tradingAccountInfo.credit,
                    equity: tradingAccountInfo.equity,
                    margin: tradingAccountInfo.margin,
                    marginFree: tradingAccountInfo.marginFree,
                    marginLevel: tradingAccountInfo.marginLevel,
                    volume: tradingAccountInfo.volume,
                    profit: tradingAccountInfo.equity - tradingAccountInfo.balance,
                }
                
                state.tradingStatus = tradingStatusData;
            }
        });
    }
}

const accountThunk = new AccountThunk();
export default accountThunk;