export enum SupportResistanceLevels {
    R4U = 'R4U',
    R3U = 'R3U',
    R2U = 'R2U',
    R1U = 'R1U',
    PU = 'PU',
    PD = 'PD',
    S1D = 'S1D',
    S2D = 'S2D',
    S3D = 'S3D',
    S4D = 'S4D'
}