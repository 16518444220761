import useTranslation from 'modules/localization';
import React from 'react';
import { connect } from 'react-redux';
import { chartService } from 'services';
import { ChartModes } from 'shared/symbol/constants';
import { RootState } from 'store/types';
import { ChartHeaderProps } from './types';

const ChartHeader = (props: ChartHeaderProps) => {
    const { t } = useTranslation();

    return (
        <div className="chart-header">
            <div className="tabs">
                {
                    props.tabs.map((symbol: string, index: number) => {
                        return (
                            <button 
                                key={index} 
                                className={props.mode === ChartModes.MULTI && props.selectedTab === index? 'active': undefined} 
                                onClick={() => chartService.setSelectedTab(index)}>
                                    <span>{symbol}</span>
                                    <i className="icofont-chart-line-alt"></i>
                            </button>
                        )
                    })
                }
            </div>
            <div className="layout-selector">
                <div className="row title">
                    <i className="icofont-layout"></i>
                    <span>{t('ChartLayouts')}</span>
                </div>
                <div className="container">
                    <div className="row" onClick={() => chartService.setMode(ChartModes.MULTI)}>
                        <img src="" alt="" />
                        <span>{t('MultiChart')}</span>
                    </div>
                    <div className="row" onClick={() => chartService.setMode(ChartModes.SINGLE)}>
                        <img src="" alt="" />
                        <span>{t('SingleChart')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    mode: state.chart.mode,
    tabs: state.chart.tabs,
    selectedTab: state.chart.selectedTab,
})

export default connect(mapStateToProps)(ChartHeader);