import React from 'react';
import storeManager from 'store/manager';
// Components
import Modal from 'components/modal';
import { ModalOrderDelete } from 'store/slices/modal-slice/types';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import generateKey from 'shared/utils/generateKey';
import useTranslation from 'modules/localization';
import { AppOneClickTrading } from 'store/slices/app-slice/types';
import { orderService } from 'services';

interface Props {
    modalData: ModalOrderDelete,
    oneClickTrading: AppOneClickTrading
}

const OrderDeleteModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    const orderData = props.modalData.data;
    const { orderId, symbol, orderType } = orderData;

    const handleClose = () => storeManager.modal.setOrderDelete({ visibility: false, data: null })
    
    const handleSubmitClick = () => {
        if (!serviceAvailable) return;

        setServiceAvailable(false);

        orderService.provider.delete()
            .then(() => handleClose())
            .finally(() => setServiceAvailable(true));
    }

    // one click trading
    if (props.oneClickTrading) {
        orderService.provider.delete()
            .finally(() => handleClose());

        return null;
    }

    return (
        <Modal key={generateKey()} title={t('CloseOrder')} id="order-delete-modal" close={handleClose}>
            <form>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                <div className="description">
                    #{orderId} {orderType}
                </div>
                <div className="message" dangerouslySetInnerHTML={{ __html: t('OrderDeleteMessage') }}></div>
                <button type="submit" className="submit-button" disabled={!serviceAvailable} onClick={handleSubmitClick}>
                    {
                        serviceAvailable? 
                            t('Close') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    modalData: state.modal.orderDelete,
    oneClickTrading: state.app.oneClickTrading
})

export default connect(mapStateToProps)(OrderDeleteModal);