import type * as ApiTypes from "./service-proxies/";
import * as ServiceProxy from "./service-proxies/";
import configurationProvider from "./providers/configuration-provider";
//import accountProvider from "./providers/account-provider";

const Api = {
    ...ServiceProxy,
    Config: configurationProvider,
    //Account: accountProvider,
};

export default Api;
export { ApiTypes };