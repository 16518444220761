import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import IMAGES from 'assets/images';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { AppMode, AppOneClickTrading, AppTheme } from 'store/slices/app-slice/types';
import { LayoutModes, Themes } from 'shared/app/enums';
import useTranslation from 'modules/localization';
import Radio from 'components/radio-button';
import { appService } from 'services';
import { Language } from 'app/types';
import storageManager from 'modules/storage-manager';

interface Props {
    currentTheme: AppTheme,
    layoutMode: AppMode,
    oneClickTrading: AppOneClickTrading,
    modalData: any
}

const SettingsModal = (props: Props) => {
    const { t, language: currentLanguage, changeLanguage } = useTranslation();  

    const [layoutMode, setLayoutMode] = React.useState<LayoutModes>(appService.layoutMode.get() as LayoutModes);
    const [language, setLanguage] = React.useState<Language>(currentLanguage);
    const [oneClickTrading, setOneClickTrading] = React.useState(props.oneClickTrading || false);
    const [theme, setTheme] = React.useState<Themes | string | null>(props.currentTheme);

    const handleClose = () => storeManager.modal.setSettings({ visibility: false, data: null });
    const handlePasswordClick = () => storeManager.modal.setPassword({ visibility: true, data: null });
    
    // <OneClickTrading>
    /** Description: Change the one click trading mode. */
    const handleOneClickTradingChange = (oneClickTradingValue: boolean) => {
        if (oneClickTradingValue && oneClickTradingValue !== props.oneClickTrading) {
            // handleClose();
            // show disclaimer modal
            storeManager.modal.setOneClickTradingDisclaimer({ visibility: true, data: null });

            return;
        }

        setOneClickTrading(oneClickTradingValue);
    }

    /** Description: Handles the one click trading disclaimer policy acceptation, */
    React.useEffect(() => {
        /** If policies is accepted, changes one click trading mode to active. */
        if (props.modalData?.isAccepted) {
            setOneClickTrading(true);
        }   
         
    }, [props.modalData])
    // </OneClickTrading>

    const handleResetSettingsClick = () => {
        const isSuccess = storageManager.preferences.remove()
        if (isSuccess) {
            window.location.reload();
        }
    }

    const handleSaveClick = () => {
        // layout mode
        if (layoutMode && layoutMode !== props.layoutMode) {
            appService.layoutMode.set(layoutMode);
        }
        
        // language
        if (language && language !== 'undefined' && language !== currentLanguage) {
            changeLanguage(language);
        }
        
        // theme
        if (theme && theme !== 'undefined' && theme !== props.currentTheme) {
            appService.theme.set(theme as Themes);
        }

        // one click trading
        if (oneClickTrading !== props.oneClickTrading) {
            appService.oneClickTrading.set(oneClickTrading);
        }

        handleClose();
    }
    
    return (
        <Modal title={t('Settings')} id="settings-modal" close={handleClose}>
            <div className="list">
                <div className="list-item">
                    <div className="list-item-description">
                        {t('SelectMode')}
                    </div>
                    <div className="list-item-options">
                        <div className="col-group" onClick={() => setLayoutMode(LayoutModes.ADVANCED)}>
                            <Radio.Button selectedValue={layoutMode} value={LayoutModes.ADVANCED} label={t('Advanced')}/>
                            <img src={IMAGES.THEME.ADVANCED} alt="" />
                        </div>
                        <div className="col-group" onClick={() => setLayoutMode(LayoutModes.BASIC)}>
                            <Radio.Button selectedValue={layoutMode} value={LayoutModes.BASIC} label={t('Basic')}/>
                            <img src={IMAGES.THEME.BASIC} alt="" />
                        </div>
                    </div>
                </div>
                <div className="list-item">
                    <div className="list-item-description">
                        {t('SelectLanguage')}
                    </div>
                    <div className="list-item-options">
                        <select value={language || 'undefined'} onChange={(e) => setLanguage(e.target.value)}>
                            <option value="undefined">{t('SelectLanguage')}</option>
                            <option value="en">English</option>
                            <option value="de">Deutsch</option>
                        </select>
                    </div>
                </div>
                <div className="list-item">
                    <div className="list-item-description">
                        {t('SelectTheme')}
                    </div>
                    <div className="list-item-options">
                        <select value={theme || 'undefined'} onChange={(e) => setTheme(e.target.value as any)}>
                            <option value={'undefined'}>{t('SelectTheme')}</option>
                            <option value={Themes.DARK}>{t('UiCustomization_Dark')}</option>
                            <option value={Themes.LIGHT}>{t('UiCustomization_Light')}</option>
                        </select>
                    </div>
                </div>
                <div className="list-item">
                    <div className="list-item-description">
                        {t('OneClickTrading')}
                    </div>
                    <div className="list-item-options">
                        <Radio.Group value={oneClickTrading} onChange={handleOneClickTradingChange}>
                            <Radio.Button value={true} label={t('Active')}/>
                            <Radio.Button value={false} label={t('Passive')}/>
                        </Radio.Group>
                    </div>
                </div>
                <div className="controls">
                    <button className="reset-settings" onClick={handleResetSettingsClick}>{t('ResetSettings')}</button>
                    <button className="change-password" onClick={handlePasswordClick}>{t('ChangePassword')}</button>
                    <button className="save-changes" onClick={handleSaveClick}>{t('SaveChanges')}</button>
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    currentTheme: state.app.theme,
    layoutMode: state.app.mode,
    oneClickTrading: state.app.oneClickTrading,
    modalData: state.modal.settings.data,
})

export default connect(mapStateToProps)(SettingsModal);