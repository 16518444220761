import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import { ApiTypes } from 'api';
import useTranslation from 'modules/localization';
import { AlarmCondition } from 'api/service-proxies';
import getSymbolDigit from 'shared/utils/getSymbolDigit';

interface Props {
    alarm: ApiTypes.AlarmDto,
}

const AlarmNoticeModal = (props: Props) => {
    const { t } = useTranslation();
    const { alarm } = props;
    const digit = getSymbolDigit(alarm.symbol as string);
    const price = alarm.price.toFixed(digit);
    
    const handleClose = () => {
        let alarmNotices: ApiTypes.AlarmDto[] = storeManager.modal.getAlarmNotice().data || [];
        
        const visibility = alarmNotices.length > 1? true: false;
        alarmNotices = alarmNotices.filter((alarmNotice) => alarmNotice.id !== alarm.id);

        storeManager.modal.setAlarmNotice({ visibility, data: [...alarmNotices] })
    };

    return (
        <Modal title="Alarm Notice" id="notice-alarm-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{alarm.symbol}</span>
                </div>
                <div className="message">
                    {t('Price')}: {price}
                    <br/>
                    {t('Description')}: {alarm.description}
                    <br/>
                    {t('Type')}: {AlarmCondition[alarm.condition]}
                </div>
                <button type="submit" className="submit-button" onClick={handleClose}>
                    {t('Close')}       
                </button>
            </form>
        </Modal>
    )
}

export default AlarmNoticeModal;