import React from 'react';
import useTranslation from 'modules/localization';
import { Outlet } from 'react-router';


const AuthLayout = () => {
    const { t, changeLanguage } = useTranslation();
    return (
        <div className='auth-layout'>
            <div className="tm-login-info__wrapper">
                <div className="tm-info__content">
                <div className="logo"></div>
                <h2>MASTER YOUR TRADING</h2>
                    <p>{t('Auth_Sub_Description')}</p>
                </div>
            </div>
            <Outlet />
        </div>
    )
}

export default AuthLayout;