import { Nullable } from "shared/global/types";
import { SupportResistanceValue } from "shared/indicator/interfaces";
import { SupportResistanceLevels } from "../services/indicator/constants";

class SupportResistanceHelper {
    private fixPrice = (digit: number, price: any) => {        
        return parseFloat(price).toFixed(digit);
    }

    private multiplyPoint = (digit: number) => {
        let result = 1;

        if (digit === 1) { result = 1; }
        if (digit === 2) { result = 10; }
        if (digit === 3) { result = 100; }
        if (digit === 4) { result = 1000; }
        if (digit === 5) { result = 10000; }
        if (digit === 6) { result = 100000; }
        if (digit === 7) { result = 1000000; }

        return result;
    }

    public getLevel = ({ srValues, ask, bid }: { srValues: any, ask: number, bid: number }) => {
        let level: SupportResistanceLevels = SupportResistanceLevels.S4D;
        
        if (srValues.xR4 <= bid) {
            level = SupportResistanceLevels.R4U;
        } else if (srValues.xR3 <= bid) {
            level = SupportResistanceLevels.R3U;
        } else if (srValues.xR2 <= bid) {
            level = SupportResistanceLevels.R2U;
        } else if (srValues.xR1 <= bid) {
            level = SupportResistanceLevels.R1U;
        } else if (srValues.xp <= bid) {
            level = SupportResistanceLevels.PU;
        } else if (srValues.xS1 <= ask) {
            level = SupportResistanceLevels.PD;
        } else if (srValues.xS2 <= ask) {
            level = SupportResistanceLevels.S1D;
        } else if (srValues.xS3 <= ask) {
            level = SupportResistanceLevels.S2D;
        } else if (srValues.xS4 <= ask) {
            level = SupportResistanceLevels.S3D;
        }

        return level;
    }
    
    public getValues = ({ srValues, ask, bid }: { srValues: any, ask: number | undefined, bid: number | undefined }): Nullable<SupportResistanceValue> => {
        if (srValues && ask && bid) {
            const level = this.getLevel({ srValues, ask, bid });
            let supportResistance = null;
        
            const digit = bid.toString().split('.')?.[1]?.length;
            
            if (level === SupportResistanceLevels.R2U || level === SupportResistanceLevels.R3U || level === SupportResistanceLevels.R4U) {
                const top2Value = this.fixPrice(1, (srValues.xR4 - bid) * this.multiplyPoint(digit));
                const top2Price = this.fixPrice(digit, srValues.xR4);
                const top1Value = this.fixPrice(1, (srValues.xR3 - bid) * this.multiplyPoint(digit));
                const top1Price = this.fixPrice(digit, srValues.xR4);
                const bottom1Value = this.fixPrice(1, (bid - srValues.xS1) * this.multiplyPoint(digit));
                const bottom1Price = this.fixPrice(digit, srValues.xS1);
                const bottom2Value = this.fixPrice(1, (bid - srValues.xS2) * this.multiplyPoint(digit));
                const bottom2Price = this.fixPrice(digit, srValues.xS2);

                supportResistance = { top2Value, top2Price, top1Value, top1Price, bottom1Value, bottom1Price, bottom2Value, bottom2Price };

            } else if (level === SupportResistanceLevels.R1U) {
                const top2Value = this.fixPrice(1, (srValues.xR3 - bid) * this.multiplyPoint(digit));
                const top2Price = this.fixPrice(digit, srValues.xR3);
                const top1Value = this.fixPrice(1, (srValues.xR2 - bid) * this.multiplyPoint(digit));
                const top1Price = this.fixPrice(digit, srValues.xR2);
                const bottom1Value = this.fixPrice(1, (bid - srValues.xS1) * this.multiplyPoint(digit));
                const bottom1Price = this.fixPrice(digit, srValues.xS1);
                const bottom2Value = this.fixPrice(1, (bid - srValues.xS2) * this.multiplyPoint(digit));
                const bottom2Price = this.fixPrice(digit, srValues.xS2);

                supportResistance = { top2Value, top2Price, top1Value, top1Price, bottom1Value, bottom1Price, bottom2Value, bottom2Price };

            } else if (level === SupportResistanceLevels.PU || level === SupportResistanceLevels.PD) {
                const top2Value = this.fixPrice(1, (srValues.xR2 - bid) * this.multiplyPoint(digit));
                const top2Price = this.fixPrice(digit, srValues.xR2);
                const top1Value = this.fixPrice(1, (srValues.xR1 - bid) * this.multiplyPoint(digit));
                const top1Price = this.fixPrice(digit, srValues.xR1);
                const bottom1Value = this.fixPrice(1, (bid - srValues.xS1) * this.multiplyPoint(digit));
                const bottom1Price = this.fixPrice(digit, srValues.xS1);
                const bottom2Value = this.fixPrice(1, (bid - srValues.xS2) * this.multiplyPoint(digit));
                const bottom2Price = this.fixPrice(digit, srValues.xS2);

                supportResistance = { top2Value, top2Price, top1Value, top1Price, bottom1Value, bottom1Price, bottom2Value, bottom2Price };

            } else if (level === SupportResistanceLevels.S1D) {
                const top2Value = this.fixPrice(1, (srValues.xR2 - bid) * this.multiplyPoint(digit));
                const top2Price = srValues.xR2;
                const top1Value = this.fixPrice(1, (srValues.xR1 - bid) * this.multiplyPoint(digit));
                const top1Price = this.fixPrice(digit, srValues.xR1);
                const bottom1Value = this.fixPrice(1, (bid - srValues.xS2) * this.multiplyPoint(digit));
                const bottom1Price = this.fixPrice(digit, srValues.xS2);
                const bottom2Value = this.fixPrice(1, (bid - srValues.xS3) * this.multiplyPoint(digit));
                const bottom2Price = this.fixPrice(digit, srValues.xS3);

                supportResistance = { top2Value, top2Price, top1Value, top1Price, bottom1Value, bottom1Price, bottom2Value, bottom2Price };

            } else if (level === SupportResistanceLevels.S2D || level === SupportResistanceLevels.S3D || level === SupportResistanceLevels.S4D) {
                const top2Value = this.fixPrice(1, (srValues.xR2 - bid) * this.multiplyPoint(digit));
                const top2Price = this.fixPrice(digit, srValues.xR2);
                const top1Value = this.fixPrice(1, (srValues.xR1 - bid) * this.multiplyPoint(digit));
                const top1Price = this.fixPrice(digit, srValues.xR1);
                const bottom1Value = this.fixPrice(1, (bid - srValues.xS3) * this.multiplyPoint(digit));
                const bottom1Price = this.fixPrice(digit, srValues.xS3);
                const bottom2Value = this.fixPrice(1, (bid - srValues.xS4) * this.multiplyPoint(digit));
                const bottom2Price = this.fixPrice(digit, srValues.xS4);

                supportResistance = { top2Value, top2Price, top1Value, top1Price, bottom1Value, bottom1Price, bottom2Value, bottom2Price };
            }

            return supportResistance;
        }

        return null;
    }
}

const supportResistanceHelper = new SupportResistanceHelper();
export default supportResistanceHelper;