import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/src/stylesheets/datepicker.scss';
import ReactDOM from 'react-dom/client';
// Router
import { BrowserRouter as Router } from 'react-router-dom';
// Redux
import { Provider } from 'react-redux';
import storeContext from 'store/context';
// App
import App from 'app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={storeContext}>
    <Router>
      <App />
    </Router>
  </Provider>
);