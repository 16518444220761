class ChartWrapperHelper {
    private _chartWrapperId = 'chart-wrapper';
    private _chartContainerName = 'chart-content';
    private _chartId = 'iframe-chart';

    create = () => {
        const hasWrapper = document.getElementById(this._chartWrapperId) !== null;
        if (!hasWrapper) {
            const chart = document.getElementById(this._chartId);

            const wrapper = document.createElement('div');
            wrapper.id = this._chartWrapperId;
            wrapper.style.position = 'absolute';
            wrapper.style.width = chart?.clientWidth? chart?.offsetWidth + 'px' : '100%';
            wrapper.style.height = '100%';
    
            const chartContainer = document.getElementsByClassName(this._chartContainerName);
            if (chartContainer.length) {
                const container = chartContainer[0];
                container.append(wrapper);
            }
        }
    }

    remove = () => {
        const wrapper = document.getElementById(this._chartWrapperId);
        wrapper?.remove();
    }
}

export default ChartWrapperHelper;