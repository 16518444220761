/** Description: Types enum of Signal Indicator.
 * 
 * Keys: UP, DN, NN, UN
 */
export enum SignalTypes {
    UP = 'icofont-bubble-up up-icon',
    DN = 'icofont-bubble-down down-icon',
    NN = 'icofont-bubble-right normal-icon',
    UN = 'icofont-spinner-alt-6 none-icon'
}

/** Description: Types enum of Trend Indicator.
 * 
 * Keys: VALUE_5, VALUE_15, VALUE_30, VALUE_60, VALUE_240, VALUE_1440
 */
export enum TrendTypes {
    VALUE_5 = 'trendValue5',
    VALUE_15 = 'trendValue15',
    VALUE_30 = 'trendValue30',
    VALUE_60 = 'trendValue60',
    VALUE_240 = 'trendValue240',
    VALUE_1440 = 'trendValue1440',
}

/** Description: Types enum of Momentum Indicator.
 * 
 * Keys: VALUE_5, VALUE_15, VALUE_30, VALUE_60, VALUE_240, VALUE_1440
 */
export enum MomentumTypes {
    VALUE_5 = 'mom5',
    VALUE_15 = 'mom15',
    VALUE_30 = 'mom30',
    VALUE_60 = 'mom60',
    VALUE_240 = 'mom240',
    VALUE_1440 = 'mom1440',
}

