import React from 'react';
import LevelItem from './components/indicator-level';
import ListTitleItem from './components/indicator-list-title';
import MomentumItem from './components/indicator-momentum';
import SignalItem from './components/indicator-signal';
import SupportResistanceItem from './components/indicator-support-resistance';
import SymbolSelectorItem from './components/indicator-symbol-selector';
import TrendItem from './components/indicator-trend';
import AccordionListContainer from './src/container';

const AccordionList = () => {
    return (
        <AccordionListContainer>
            <ListTitleItem />
            <SymbolSelectorItem />
            <div className="scrolled-container">
                <LevelItem />
                <SignalItem />
                <TrendItem />
                <MomentumItem />
                <SupportResistanceItem />
            </div>
        </AccordionListContainer>        
    )
}

export default AccordionList;