import React from 'react';
import { connect } from 'react-redux';
// Components
import StockExchangeTimes from 'components/stock-exchange-times';
import SupportResistancePoints from 'components/support-resistance-points';
import ServerTime from 'components/server-time';
import HeaderMenu from 'components/header-menu';
import WatchList from 'components/watchlist';
import TradingAccountStatus from 'components/trading-account-status';
import Chart from 'components/chart';
import DataTables from 'components/datatables/container';
import Strategies from 'components/strategies';
import AccordionList from 'components/accordion-list';
import BottomFieldResizer from 'components/field-resizers/bottom-field-resizer';
import Alarm from 'components/alarm';
// Types
import { RootState } from 'store/types';
import { AppBottomFieldHeight, AppMode } from 'store/slices/app-slice/types';
import { LayoutModes } from 'shared/app/enums';
import OneClickTradingStatus from 'components/one-click-trading-status';
import { appConfig } from "app/config";


interface Props {
    bottomFieldHeight: AppBottomFieldHeight,
    layoutMode: AppMode
}

const AdvancedTraderContainer = (props: Props) => {
    return (
        <div className="trader-container container">
            <div className="header">
                <div className="left"></div>
                <div className="middle">
                    <TradingAccountStatus />

                    {/* <SupportResistancePoints /> */}
                </div>
                <div className="right">
                    <HeaderMenu />
                </div>
            </div>
            <div className="main">
                <div className="left">
                    <WatchList />
                </div>
                <div className="middle">
                    <div className="top">
                        <Chart />
                    </div>
                    <BottomFieldResizer />
                    <div className="bottom" style={{ height: props.bottomFieldHeight + 'px' }}>
                        <DataTables />
                    </div>
                </div>
                <div className="right">
                    {appConfig.strategiesIsActive && < Strategies />}
                    <OneClickTradingStatus /> 
                    <StockExchangeTimes />
                    <AccordionList />
                </div>
            </div>
            <div className="footer">
                <div className="left">
                    <Alarm />
                </div>
                <div className="middle">

                </div>
                <div className="right">
                    <ServerTime />
                </div>
            </div>
        </div>
    )
}

const BasicTraderContainer = (props: Props) => {
    return (
        <div className="trader-container container basic">
            <div className="header">
                <div className="left"></div>
                <div className="middle"></div>
                <div className="right flex-end">
                    <HeaderMenu />
                </div>
            </div>
            <div className="main">
                <div className="left">
                    <WatchList />
                </div>
                <div className="middle">
                    <div className="top">
                        <TradingAccountStatus />
                        <Chart />
                    </div>
                    <BottomFieldResizer />
                    <div className="bottom" style={{ height: props.bottomFieldHeight + 'px' }}>
                        <DataTables />
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="left">
                    <Alarm />
                </div>
            </div>
        </div>
    )
}

const TraderContainer = (props: Props) => {
    return (
        <React.Fragment>
            { props.layoutMode === LayoutModes.BASIC && <BasicTraderContainer {...props} />}
            { props.layoutMode === LayoutModes.ADVANCED && <AdvancedTraderContainer {...props} />}
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    bottomFieldHeight: state.app.bottomFieldHeight,
    layoutMode: state.app.mode
})

export default connect(mapStateToProps)(TraderContainer);