import Api, { ApiTypes } from "api";
import { appConfig } from "app/config";
import { appService } from "services";
import storageManager from "modules/storage-manager";
import Dependencies from "shared/app/dependencies";
import { ChangeUserLanguageDto } from "../../api/service-proxies";
import { ApiResponse } from "../../shared/api/constants";

class AuthService {
    private readonly _tokenAuthService;
    private readonly _accountService;
    private readonly _profileService;


    constructor(dependencies: Dependencies.Root) {
        this._tokenAuthService = new Api.TokenAuthServiceProxy();
        this._accountService = new Api.AccountServiceProxy();
        this._profileService = new Api.ProfileServiceProxy();
    }

    /** Description: Provides the user to sign in. */
    public login = async (input: ApiTypes.AuthenticateModel): Promise<ApiResponse<ApiTypes.IAuthenticateResultModel>> => {

        const response: ApiResponse<ApiTypes.IAuthenticateResultModel> = await this._tokenAuthService.authenticate(input) as any;
        response.data = new Api.AuthenticateResultModel(response.data);

        if (response.success) {

            const token = response.data.accessToken;
            const encryptedAccessToken = response.data.encryptedAccessToken;

            storageManager.user.set({
                id: input.userNameOrEmailAddress,
                token,
                encryptedAccessToken,
            })
            this._profileService.changeLanguage(new ChangeUserLanguageDto({ languageName: appService.language.get() })).then(() => { })

            window.location.href = appConfig.pages.trader.path;
        }

        return response;
    }

    /** Description: Provides the user to sign up. */
    public register = async (input: ApiTypes.RegisterInput) => {
        const response: ApiResponse<ApiTypes.IRegisterOutput> = await this._accountService.register(input) as any;
        response.data = new Api.RegisterOutput(response.data);
        return response;
    }

    public logout = () => {
        storageManager.user.remove();
        document.location.href = '/login';
    }
}

export default AuthService;