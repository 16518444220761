import React from 'react';
import { connect } from 'react-redux';
import AccordionListItem from '../src/item';
import IMAGES from 'assets/images';
// Types
import { RootState } from 'store/types';
import { IndicatorTrend } from 'store/slices/indicator-slice/types';
import { TrendTypes } from 'shared/indicator/enums';
import useTranslation from 'modules/localization';

interface Props {
    data: IndicatorTrend
}

const TrendItem = (props: Props) => {
    const [currentTrend, setCurrentTrend] = React.useState(TrendTypes.VALUE_5);
    const { t } = useTranslation(); 

    const trend = props.data? props.data[currentTrend] : null;
    const image = trend !== null ? IMAGES.INDICATOR.TREND[trend] : null;

    const getClassName = (type: TrendTypes) => {
        return currentTrend === type? 'button active-button' : 'button';
    }

    return (
        <AccordionListItem id={'trend'} title={t('TrendTitle')} icon="icofont-stylish-up">
            <table className="trend-table">
                <tbody>
                    <tr className="center-t">
                        <td colSpan={6}>
                            {
                                image? 
                                    <img src={image.default} alt="" />
                                    :
                                    <i className="icofont-spinner-alt-6 loader"></i>
                            }
                        </td>
                    </tr>
                    <tr className="center-t">
                        <td className={getClassName(TrendTypes.VALUE_5)} onClick={() => setCurrentTrend(TrendTypes.VALUE_5)}>M5</td>
                        <td className={getClassName(TrendTypes.VALUE_15)} onClick={() => setCurrentTrend(TrendTypes.VALUE_15)}>M15</td>
                        <td className={getClassName(TrendTypes.VALUE_30)} onClick={() => setCurrentTrend(TrendTypes.VALUE_30)}>M30</td>
                        <td className={getClassName(TrendTypes.VALUE_60)} onClick={() => setCurrentTrend(TrendTypes.VALUE_60)}>H1</td>
                        <td className={getClassName(TrendTypes.VALUE_240)} onClick={() => setCurrentTrend(TrendTypes.VALUE_240)}>H4</td>
                        <td className={getClassName(TrendTypes.VALUE_1440)} onClick={() => setCurrentTrend(TrendTypes.VALUE_1440)}>D1</td>
                    </tr>
                </tbody>
            </table>
        </AccordionListItem>
    )
}

const mapStateToProps = (state: RootState) => ({
    data: state.indicator.trend
})

export default connect(mapStateToProps)(TrendItem);