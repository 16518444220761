/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { ChartContentProps } from './types';

const TVChartContainer = React.lazy(() => import('chart/components/TVChartContainer'));


const ChartContent = (props: ChartContentProps) => {
    
    const symbol: string = props.tabs[props.selectedTab];

    return (
        <div className={"chart-content"}>
            {
                <Suspense fallback={<React.Fragment>
                        <i className="loader icofont-spinner"></i>
                        <span className="loader">{symbol} Chart is loading...</span>
                </React.Fragment>}>
                    <TVChartContainer />
                </Suspense>
            }

        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    tabs: state.chart.tabs,
    selectedTab: state.chart.selectedTab,
    theme: state.app.theme,
})

export default connect(mapStateToProps)(ChartContent);