import React from 'react';
import Modal from 'components/modal';
import EconomicCalendar from 'components/economic-calender';
import storeManager from 'store/manager';
import useTranslation from 'modules/localization';

const EconomicCalendarModal = () => {
    const { t, language } = useTranslation();

    const handleClose = () => storeManager.modal.setEconomicCalendar({ visibility: false, data: null });

    return (
        <Modal title={t('EconomicCalendar')} id="economic-calender-modal" close={handleClose}>
            <EconomicCalendar locale={language as any} />
        </Modal>
    )
}

export default EconomicCalendarModal;