import ResetPasswordForm from '../../../components/forms/reset-password-form';

const ResetPasswordContainer = () => {

    return (
        <div className="reset-password-container">
            <ResetPasswordForm/>
        </div>
    )
}

export default ResetPasswordContainer;