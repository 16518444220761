import React from 'react';
import { connect } from 'react-redux';
// Types
import { RootState } from 'store/types';
import { OrderOpen } from 'store/slices/order-slice/types';
import { OpenOrderInfo } from 'shared/symbol/types';
import storeManager from 'store/manager';
import { Op } from 'shared/trade/enums';
import { SymbolData } from 'store/slices/symbol-slice/types';
import useTranslation from 'modules/localization';
import { chartService, orderService } from 'services';
import tableColumnSortHelper, { SortedColumn } from 'helpers/table-column-sort-helper';

interface Props {
    data: OrderOpen,
    symbolData: SymbolData,
    sortedColumn: SortedColumn
}

const _OpenOrdersTableOpenPositions = (props: Props) => {
    const { t } = useTranslation();

    const openModifyModal = (data: OpenOrderInfo) => storeManager.modal.setOrderModify({ visibility: true, data: {
        orderId: data.order,
        symbol: data.symbol,
        orderSide: data.type,
        amount: data.volume,
        digit: data.digits,
        price: data.openPrice.toFixed(data.digits),
        stopLoss: data.sl.toFixed(data.digits),
        takeProfit: data.tp.toFixed(data.digits),
        frozenPrice: true
    }});

    const openCloseModal = (data: OpenOrderInfo) => {
        const marketData = props.symbolData.hasOwnProperty(data.symbol)? props.symbolData[data.symbol] : null;
        
        if (marketData) {
            const { ask, bid } = marketData;

            storeManager.modal.setOrderClose({ visibility: true, data: {
                orderId: data.order,
                price: (Op as any)[data.type] === Op.Buy? bid: ask, 
                symbol: data.symbol,
                orderType: data.type,  
                stopLoss: data.sl,
                takeProfit: data.tp,
                amount: data.volume, 
            }});
        }
    };

    const handleCloseMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        const rowElement = event.currentTarget.parentElement?.parentElement?.parentElement; 
        if (rowElement) {
            rowElement.classList.add('close');
            
            setTimeout(() => {
                if (rowElement) {
                    rowElement.classList.remove('close');
                }
    
            }, orderService.provider.ROW_TRANSACTION_ANIMATION_MS / 2);

        }
    }

    // <Calculations>
    const getTotal = (orderKey: keyof OpenOrderInfo) => {
        let calculation = 0;

        if (props.data) {
            props.data.forEach((order: OpenOrderInfo) => calculation += order[orderKey] as number);
        }

        return calculation.toFixed(2);
    }
    // </Calculations>

    const data = tableColumnSortHelper.getSortedData({ sortStateGetter: props.sortedColumn, data: props.data });

    return (
        <React.Fragment>
            <tr className="table-title">
                <td colSpan={13}><b>{t('OpenOrdersTitle')}</b></td>
            </tr>
            {
                data?.map((data: OpenOrderInfo) => {
                    const openPrice = data.openPrice.toFixed(data.digits);
                    const stopLoss = data.sl.toFixed(data.digits);
                    const takeProfit = data.tp.toFixed(data.digits);
                    const closePrice = data.cp.toFixed(data.digits);

                    const volume = data.volume.toFixed(2);
                    const commission = data.commission.toFixed(2);
                    const swap = data.swap.toFixed(2);
                    const profit = data.profit.toFixed(2);

                    return (
                        <tr key={data.order} id={`order-${data.order}`}>
                            <td>#{data.order}</td>
                            <td>{data.openTime}</td>
                            <td>{data.type}</td>
                            <td>{volume}</td>
                            <td className="chart-button" onClick={() => chartService.changeSelectedTab(data.symbol)}>
                                {data.symbol}
                                <i className="icofont-chart-line-alt"></i>
                            </td>
                            <td>{openPrice}</td>
                            <td>{stopLoss}</td>
                            <td>{takeProfit}</td>
                            <td>{closePrice}</td>
                            <td>{commission}</td>
                            <td>{swap}</td>
                            <td>{profit}</td>
                            <td>
                                <div className="options">
                                    <button className="modify-button" onClick={() => openModifyModal(data)}>
                                        {t('Modify')}
                                        <i className="icofont-edit"></i>
                                    </button>
                                    <button className="delete-button" onClick={() => openCloseModal(data)} onMouseDown={handleCloseMouseDown}>
                                        {t('Close')}
                                        <i className="icofont-close-squared-alt"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                })
            }

            <tr>
                <td colSpan={8}></td>
                <td>
                    <strong>{t('TotalOrders')}</strong>
                </td>
                <td>
                    <strong>{getTotal('commission')}</strong>
                </td>
                <td>
                    <strong>{getTotal('swap')}</strong>
                </td>
                <td>
                    <strong>{getTotal('profit')}</strong>
                </td>
                <td></td>
            </tr>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    data: state.order.open,
    symbolData: state.symbol.data
})

export default connect(mapStateToProps)(_OpenOrdersTableOpenPositions);