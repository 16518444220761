const removeCaptcha = () => {
    const nodeBadge = document.querySelector('.grecaptcha-badge');

    if (nodeBadge) {
        nodeBadge.id = 'grecaptcha-badge';
        let osman = document.getElementById('grecaptcha-badge');
        if (osman) {

            osman.remove();
        }
    }
}

export default removeCaptcha;