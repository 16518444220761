import { LoginFormProps } from './interfaces';
import { RootState } from '../../../store/types';
import { connect } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import LoginForm from '.';

const LoginFormWithRecaptcha = (props: LoginFormProps) => {

    let recaptchaSiteKey: string = props.recaptchaSiteKey || '';
    let useCaptchaOnLogin: boolean = props.useCaptchaOnLogin || false;

   
    return (
        <React.Fragment>
            {
                useCaptchaOnLogin ? (
                    <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey} >
                        <LoginForm {...props} />
                    </GoogleReCaptchaProvider>

                ) : (
                    <LoginForm {...props} />
                    )
            }

        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    recaptchaSiteKey: state.app.config.data?.recaptchaSiteKey,
    useCaptchaOnLogin: state.app.config.data?.useCaptchaOnLogin
})

export default connect(mapStateToProps)(LoginFormWithRecaptcha);