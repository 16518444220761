import { TUseLanguage } from "app/types";
import storageManager from "modules/storage-manager";
import { appService } from "services";
import Api from "api";
import { ChangeUserLanguageDto } from "../../api/service-proxies";

class LanguageManager {
    private _languageSetter!: Function;
    private _language: string = 'en';
    private readonly _profileService;

    constructor() {
        const storageValue = storageManager.preferences.get()?.language;
        if (storageValue) {
            this._language = storageValue;  
        }
        this._profileService = new Api.ProfileServiceProxy();
    }

    /** Description: Returns the current app language. */
    get() {
        return this._language;
    }

    /** Description: Changes the app language. */
    setAsync = async (language: string) => {
        this._language = language;
        storageManager.preferences.update({ language });
        if (storageManager.user.get()) {
            this._profileService.changeLanguage(new ChangeUserLanguageDto({ languageName: language })).then(() => { })
        }
        await appService.getConfig();

        return Promise.resolve();
    }

    /** Description: Changes the app language as default. */
    setAsDefault = ()  =>{
        if (this._languageSetter) {
            this._languageSetter(this._language);

        } else {
            throw new Error('You have to configure the application language using the useState hook. - languageManager.configure() method')
        }
    }

    /** Description: Configures the localization context.  */
    configure = (useLanguageHook: TUseLanguage) => {
        this._languageSetter = useLanguageHook[1];
        this.setAsDefault();
    }
}

export default LanguageManager;