import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { OrderHistoryPeriods } from './constants';
import { OrderHistoryToolBoxProps } from './types';
import orderHistorHelper from './helper';
import useTranslation from 'modules/localization';

const OrderHistoryTableToolBox = (props: OrderHistoryToolBoxProps) => {
    const [period, setPeriod] = props.usePeriod;
    const [startDate, setStartDate] = props.useStartDate;
    const [endDate, setEndDate] = props.useEndDate;
    const { t } = useTranslation();

    const changeStartDate = (date: any) => {
        let momentDate = moment(date);
        momentDate = momentDate.set({ 
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })

        setPeriod(OrderHistoryPeriods.CUSTOM);
        setStartDate({
            value: orderHistorHelper.getMomentDateValue(momentDate),
            date
        });
    }
    
    const changeEndDate = (date: any) => {
        let momentDate = moment(date);
        momentDate = momentDate.set({ 
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
        })

        setPeriod(OrderHistoryPeriods.CUSTOM);
        setEndDate({
            value: orderHistorHelper.getMomentDateValue(momentDate),
            date
        });
    }

    const parseInputTime = (date: Date) => {
        return moment(date).format('YYYY.MM.DD');
    } 

    const startDateValue = parseInputTime(startDate.date);
    const endDateValue = parseInputTime(startDate.date);
    
    return (
        <div className="toolbox">
            <div className="flex-start">
                {t('Period')}: {startDateValue} - {endDateValue}
            </div>
            <div className="flex-end">
                <span>{t('CustomPeriod')}/ {t('From')}:</span>
                <div className='datepicker'>
                    <DatePicker selected={startDate.date} onChange={(date) => changeStartDate(date)} />
                </div>
                <span>{t('To')}:</span>
                <div className='datepicker'>
                    <DatePicker selected={endDate.date} onChange={(date) => changeEndDate(date)} />
                </div>
                {/* <button>Show</button> */}
                <div className="selector">
                    <div className="selected">
                        <i className="icofont-navigation-menu"></i>
                        {t(period as any)}
                        
                    </div>
                    <div className="list">
                        <div onClick={() => setPeriod(OrderHistoryPeriods.ALL_HISTORY)}>{t(OrderHistoryPeriods.ALL_HISTORY as any)}</div>
                        <div onClick={() => setPeriod(OrderHistoryPeriods.LAST_MONTH)}>{t(OrderHistoryPeriods.LAST_MONTH as any)}</div>
                        <div onClick={() => setPeriod(OrderHistoryPeriods.LAST_WEEK)}>{t(OrderHistoryPeriods.LAST_WEEK as any)}</div>
                        <div onClick={() => setPeriod(OrderHistoryPeriods.LAST_THREE_DAY)}>{t(OrderHistoryPeriods.LAST_THREE_DAY as any)}</div>
                        <div onClick={() => setPeriod(OrderHistoryPeriods.TODAY)}>{t(OrderHistoryPeriods.TODAY as any)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderHistoryTableToolBox;