import React from 'react';
import { ApiTypes } from 'api';
import { connect } from 'react-redux';
import storeManager from 'store/manager';
import { AlarmData } from 'store/slices/alarm-slice/types';
import { RootState } from 'store/types';
import useTranslation from 'modules/localization';
import { alarmService } from 'services';
import getSymbolDigit from 'shared/utils/getSymbolDigit';

interface Props {
    alarmList: AlarmData,
}

const Alarm = (props: Props) => {  
    const { t } = useTranslation();

    const openAlarmList = () => {
        storeManager.modal.setAlarmList({ visibility: true, data: null });
    }
    
    const modifyAlarm = (data: ApiTypes.AlarmDto) => {
        storeManager.modal.setAlarmModify({ visibility: true, data })
    }

    const deleteAlarm = (data: ApiTypes.AlarmDto) => {
        storeManager.modal.setAlarmDelete({ visibility: true, data })
    }

    React.useEffect(() => {
        alarmService.getAll();
    }, [])
    
    return (
        <div className="alarm">
            <div className="title">
                {t('PriceAlerts')}
            </div>
            <div className="body">
                <div className="head">
                    <span>
                        {t('MaxPriceAlert')}
                    </span>
                    <div onClick={openAlarmList}>
                        {t('Open')}
                    </div>
                </div>
                <div className="content">
                    <table>
                        <thead>
                            <tr>
                                <th>{t('Symbol')}</th>
                                <th>{t('Price')}</th>
                                <th style={{ width: '120px' }} className="options">{t('Options')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.alarmList.map((alarmData, index) => {
                                    const digit = getSymbolDigit(alarmData.symbol as string);
                                    const price = digit !== null? alarmData.price.toFixed(digit) : alarmData.price;

                                    return (
                                        <tr key={alarmData.id}>
                                            <td>{alarmData.symbol}</td>
                                            <td>{price}</td>
                                            <td>
                                                <button onClick={() => modifyAlarm(alarmData)}>
                                                    {t('Modify')}    
                                                </button> 
                                                - 
                                                <button onClick={() => deleteAlarm(alarmData)}>
                                                    {t('Close')}
                                                </button> 
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>        
    )
}

const mapStateToProps = (state: RootState) => ({
    alarmList: state.alarm.data,
})

export default connect(mapStateToProps)(Alarm);