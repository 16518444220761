import React from 'react';
import { connect } from 'react-redux';
import { default as sortHelper }  from './sort-helper';
// Components
import WatchListItem from './item';
// Types
import { RootState } from 'store/types';
import { WatchListContainerProps } from './types';
import storeManager from 'store/manager';
import storageManager from 'modules/storage-manager';
import getAccountNo from 'shared/utils/getAccountNo';
import getPlatformCode from 'shared/utils/getPlatformCode';
import WatchListPassiveItem from './passive-item';

const WatchListContainer = (props: WatchListContainerProps) => {
    const useSortElementState = [sortHelper.element.state, sortHelper.element.set] = React.useState<string | null>(null);
    const [filter] = props.useFilter;
    const filteredSymbolsWithData: string[] = [];
    const filteredSymbolsWithoutData: string[] = [];
    

    const getFilteredSymbols = () => {
        let filteredSymbols = [...props.watchList];

        if (filter !== '') {
            filteredSymbols = filteredSymbols.filter((symbol) => symbol.indexOf(filter) !== -1);
        }
        
        return filteredSymbols;
    }

    React.useEffect(() => {
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const storageWatchList = storageManager.preferences.get()?.watchList || {};
        if (!props.watchList.length && !storageWatchList?.[platformCode]?.[accountNo]?.length) {
            if (props.symbolList?.length) {
                const defaultWatchListSymbols: string[] = props.defaultWatchListSymbols; 
                
                storeManager.symbol.setWatchList(defaultWatchListSymbols);
                storageManager.preferences.update({ 
                    watchList:  {
                        ...storageWatchList,
                        [platformCode]: {
                            ...storageWatchList[platformCode],
                            [accountNo]: defaultWatchListSymbols
                        }
                    }
                });
            }
        }

    }, [props.symbolList, props.watchList])

    getFilteredSymbols().forEach(symbol => {
        props.symbolData.hasOwnProperty(symbol)? 
            filteredSymbolsWithData.push(symbol) : filteredSymbolsWithoutData.push(symbol)
    });

    return (
        <div className="watchlist-container" 
            onMouseMove={sortHelper.handleContainerMouseMove} 
            onMouseLeave={sortHelper.handleContainerMouseLeave} 
            onMouseUp={sortHelper.handleContainerMouseUp} >
                
            {
                filteredSymbolsWithData.map((symbol, index) => {     
                    const data = props.symbolData[symbol];

                    return (
                        <WatchListItem key={index} index={index} useSort={useSortElementState} {...data} />
                    )
                })
            }

            {
                filteredSymbolsWithoutData.map((symbol, index) => {
                    return (
                        <WatchListPassiveItem key={index} symbol={symbol} />
                    )
                })
            }

        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    symbolData: state.symbol.data,
    symbolList: state.symbol.config.data,
    watchList: state.symbol.watchList,
    defaultWatchListSymbols: state.symbol.defaultWatchListSymbols
})

export default connect(mapStateToProps)(WatchListContainer);