import ConfirmEmail from '../../../components/confirm-email';

const ConfirmEmailContainer = () => {

    return (
        <div className="login-container confirm-email-container">
            <ConfirmEmail />
        </div>
    )
}

export default ConfirmEmailContainer;