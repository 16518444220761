import { Route } from "react-router-dom";
import { appConfig } from "app/config";
// Utils
import generateKey from "shared/utils/generateKey";
// Layouts
import MainLayout from "pages/layouts/main-layout";
// Containers
import TraderContainer from "pages/containers/main/trader-container";
import TradingAccountContainer from "pages/containers/main/trading-accounts-container";
import StandaloneChart from "../../components/chart/standalone";
// import ChartContainer from "pages/containers/main/_chart-container";

const mainRoutes = [
        <Route key={generateKey()} element={<MainLayout />} >
        <Route path={appConfig.pages.trader.path} element={<TraderContainer />} />
        <Route path={appConfig.pages.tradingAccounts.path} element={<TradingAccountContainer />} />
        <Route path={appConfig.pages.tvChart.path} element={<StandaloneChart />} />
    </Route>
]

export default mainRoutes;