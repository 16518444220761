import LocalStorageItem from "./item";
import { StorageItemOptions } from "./types";

// storageItemBuilder
const itemBuilder = <T>(options: StorageItemOptions) => {
    const storageItem = new LocalStorageItem<T>(options);

    return storageItem;
}

export default itemBuilder;