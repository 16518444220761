import React from 'react';
import useTranslation from 'modules/localization';
// Constants
import { exposureToolCloseType, exposureToolSymbolType } from './constants';
// Types
import { ApiTypes } from 'api';
import { TranslationKey } from 'modules/localization/translationKey';
import { orderService } from 'services';

/** Description: ExposureToolTableToolBox Component's Props.  */
interface Props {
    symbols: string[],
}

const ExposureToolTableToolBox = (props: Props) => {
    const [symbolType, setSymbolType] = React.useState<{ TEXT: string, VALUE: null | string }>(exposureToolSymbolType.NONE);
    const [operationType, setOperationType] = React.useState<{ TEXT: string, VALUE: null | ApiTypes.EnCloseType }>(exposureToolCloseType.NONE);
    const { t } = useTranslation();
    
    const handleExecuteClick = () => {
        orderService.provider.closeAll({ operationType: operationType.VALUE, closeSymbolType: symbolType.VALUE })
    }

    return (
        <div className="toolbox">
                <span>Trading Tools</span>
                <div className="selector">
                    <div className="selected">
                        { t(symbolType.TEXT as TranslationKey) }
                        <i className="icofont-rounded-down"></i>
                    </div>
                    <div className="list">
                        <div onClick={() => setSymbolType(exposureToolSymbolType.NONE)}>{t(exposureToolSymbolType.NONE.TEXT as TranslationKey)}</div>
                        <div onClick={() => setSymbolType(exposureToolSymbolType.ALL)}>{t(exposureToolSymbolType.ALL.TEXT as TranslationKey)}</div>
                        {
                            props.symbols.map((symbol) => (
                                <div key={symbol} onClick={() => setSymbolType({ TEXT: symbol, VALUE: symbol })}>{symbol}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="selector">
                    <div className="selected">
                        {t(operationType?.TEXT as TranslationKey)}
                        <i className="icofont-rounded-down"></i>
                    </div>
                    <div className="list">
                        {
                            Object.values(exposureToolCloseType).map((closeType, index) => {
                                return (
                                    <div key={index} onClick={() => setOperationType(closeType)}>{t(closeType.TEXT as TranslationKey)}</div>
                                )
                            })
                        }
                    </div>
                </div>
                <button onClick={handleExecuteClick}>{t('ExecuteMultipleClose')}</button>
            </div>
    )
}

export default ExposureToolTableToolBox;