export enum Op {
    Buy,
    Sell,
    BuyLimit,
    SellLimit,
    BuyStop,
    SellStop,
    Balance,
    Credit
}

export const Cmd: any = {
    0: "Buy",
    1: "Sell",
    2: "Buy Limit",
    3: "Sell Limit",
    4: "Buy Stop",
    5: "Sell Stop",
}