import HubBase from "../_base";
import { IIndicatorHub } from "./types";
import { indicatorService } from "services";
import { appConfig } from "app/config";

class IndicatorHub extends HubBase implements IIndicatorHub {
    constructor() {
        super(appConfig.socketServiceUrl + appConfig.hubs.indicator);
    }

    // <Lifecycle>
    /** Description: Starts the IndicatorHub connection. */
    public start = () => {
        this.onConnectionDefine = () => {
            // Handler registrations
            this.connection?.on('receiveIndicatorDetail', indicatorService.updateIndicatorData);
        }

        return {
            then: (handleConnectionStart: Function) => {
                this.onConnectionStart = handleConnectionStart;
                this.connect();
            }
        };
    }

    /** Description: Updates the IndicatorHub subscription list.*/
    update(subscriptionList: Array<string>) {
        if (subscriptionList !== this.subscriptions) {
            this.unsubscribe();
            this.subscriptions = subscriptionList;
            this.subscribe();
        }
    }

    /** Description: Closes the IndicatorHub connection. */
    close() {
        this.disconnect();
    }
    // </Lifecycle>
}

export default IndicatorHub;