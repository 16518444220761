import storageManager from "modules/storage-manager";

const getTokenWithBearer = () => {
    const userData = storageManager.user.get();
    const token = userData?.token;
    
    if (token) {
        return `Bearer ${token}`;
    }

    return null;
}

export default getTokenWithBearer;