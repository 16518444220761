import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import ReactDatePicker from 'react-datepicker';
import useTranslation from 'modules/localization';
import { alarmService } from 'services';
import getDecimalCount from 'shared/utils/getDecimalCount';

interface Props {
    alarmData: any,
}

const AlarmCreateModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    // tpdo - should be moved to shared folder
    const inputKeys = {
        NAME: 'name',
        DESCRIPTION: 'description',
        PRICE: 'price',
        CONDITION: 'condition',
        EXPIRATION: 'expiration',
        MAX_ITERATIONS: 'maxIterations',
    }

    const symbol = props.alarmData?.symbol;

    const name = props.alarmData.hasOwnProperty(inputKeys.NAME)? props.alarmData[inputKeys.NAME] : `${symbol} Alarm`;
    const price = props.alarmData.hasOwnProperty(inputKeys.PRICE)? props.alarmData[inputKeys.PRICE] : props.alarmData?.ask?.toFixed(props.alarmData?.digit);
    const condition = props.alarmData.hasOwnProperty(inputKeys.CONDITION)? props.alarmData[inputKeys.CONDITION] : '0';
    const expiration: any = props.alarmData.hasOwnProperty(inputKeys.CONDITION)? props.alarmData[inputKeys.EXPIRATION] : new Date();
    const maxIterations = props.alarmData.hasOwnProperty(inputKeys.MAX_ITERATIONS)? props.alarmData[inputKeys.MAX_ITERATIONS] : '1';
    const description = props.alarmData.hasOwnProperty(inputKeys.DESCRIPTION)? props.alarmData[inputKeys.DESCRIPTION] : 'No description';
    
    React.useEffect(() => {
        setValue(inputKeys.CONDITION, condition);
        setValue(inputKeys.DESCRIPTION, description);
        setValue(inputKeys.EXPIRATION, expiration);
        setValue(inputKeys.MAX_ITERATIONS, maxIterations);
        setValue(inputKeys.NAME, name);
        setValue(inputKeys.PRICE, price);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /** Description: Closes current modal. */
    const handleClose = () => {
        storeManager.modal.setAlarmCreate({ visibility: false, data: null })
    };

    /** Description: Sets the alarm price. */
    const setValue = (key: string, value: any) => {
        const modalData = storeManager.modal.getAlarmCreate();

        storeManager.modal.setAlarmCreate({
            visibility: modalData.visibility,
            data: {
                ...modalData.data,
                [key]: value
            }
        })
    }

    const changePrice = (value: string) => getDecimalCount(value) <= props.alarmData.digit? setValue(inputKeys.PRICE, value) : null

    const handleSubmit = () => {
        if (!serviceAvailable) 
            return;

        setServiceAvailable(false);
        alarmService.create()
            .finally(() => setServiceAvailable(true));
    }

    return (
        <Modal title={t('Alarm')} id="create-alarm-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                
                <div>
                    <label htmlFor="alarm-create-name">{t('Name')}</label>
                    <input type="text" id="alarm-create-name" step="0.01" min="0.01" value={name} onChange={(e) => setValue(inputKeys.NAME, e.currentTarget.value)} />
                </div>
                <div>
                    <label htmlFor="alarm-create-price">{t('Price')}</label>
                    <input type="number" id="alarm-create-price" step="0.01" value={price} onChange={(e) => changePrice(e.currentTarget.value)} />
                </div>
                <div>
                    <label htmlFor="alarm-create-condition">{t('Condition')}</label>
                    <select id="alarm-create-condition" value={condition} onChange={(e) => setValue(inputKeys.CONDITION, e.currentTarget.value)} >
                        <option value="0">{t('BidHigherThan')}</option>
                        <option value="1">{t('BidLowerThan')}</option>
                        <option value="2">{t('AskHigherThan')}</option>
                        <option value="3">{t('AskLowerThan')}</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="alarm-create-expiration">{t('Expiration')}</label>
                    <div>
                        <ReactDatePicker id="alarm-create-expiration" selected={expiration} onChange={(date) => setValue(inputKeys.EXPIRATION, date)} />
                    </div>
                </div>
                <div>
                    <label htmlFor="alarm-create-description">{t('Description')}</label>
                    <input type="text" id="alarm-create-description" value={description} onChange={(e) => setValue(inputKeys.DESCRIPTION, e.currentTarget.value)} />
                </div>

                <button type="submit" className="submit-button" onClick={handleSubmit}>
                    {
                        serviceAvailable? 
                            t('CreateAlarm') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    alarmData: state.modal.alarmCreate.data
})

export default connect(mapStateToProps)(AlarmCreateModal);