import { AlarmDto } from "api/service-proxies";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/types";
import AlarmNoticeModal from "./notice";

interface Props {
    data: AlarmDto[] | null
}

const AlarmNoticeModalContainer = (props: Props) => {
    return (    
        <React.Fragment>
            {
                props.data?.map((alarm, index) => (
                    <AlarmNoticeModal key={index} alarm={alarm} />
                ))
            }
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => ({
    data: state.modal.alarmNotice.data
})

export default connect(mapStateToProps)(AlarmNoticeModalContainer);