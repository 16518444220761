import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AccountServiceProxy, ActivateEmailInput } from '../../api/service-proxies';
import useTranslation from '../../modules/localization';
import IMAGES from 'assets/images';import storeManager from '../../store/manager';
import { appConfig } from '../../app/config';
;

const ConfirmEmail = () => {

    const location = useLocation();
    const { t, changeLanguage } = useTranslation();
    const [message, setMessage] = useState(t('PleaseWaitToConfirmYourEmailMessage'));
    const [input, setInput] = useState(new ActivateEmailInput() as any);

    const _accountService: AccountServiceProxy = new AccountServiceProxy();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get('c') as string;
        setInput({ ...input, c: code });
    },[])

    useEffect(() => {
        if (input.c) {
            activation();
        }
    }, [input])

    const activation = () => {
        _accountService.activateEmail(input)
            .then(() => {
                setMessage(t('YourEmailIsConfirmedMessage'));
            })
    }

    const handleLoginClick = () => {
        storeManager.app.setLocation(appConfig.pages.login.path);
    }

    return (
        <div className="confirm-email">
            <div className="alert alert-success text-center" role="alert">
                <img src={IMAGES.ICON.MAIL_CONFIRM} width="96" />
                <div className="alert-text">{message}</div>
                <button type="submit" className="login" onClick={handleLoginClick}>{t('Login')}</button>
            </div>
        </div>
    )
}

export default ConfirmEmail;