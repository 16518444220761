import React from 'react';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
// Components
import Modal from 'components/modal';
// Types
import { RootState } from 'store/types';
import { ApiTypes } from 'api';
import { alarmService } from 'services';
import useTranslation from 'modules/localization';

type Alarm = ApiTypes.AlarmDto;

interface Props {
    modalData: Alarm
}

const AlarmDeleteModal = (props: Props) => {
    const [serviceAvailable, setServiceAvailable] = React.useState(true);
    const { t } = useTranslation();

    /** Description: Closes current modal. */
    const handleClose = () => storeManager.modal.setAlarmDelete({ visibility: false, data: null });

    /** Description: Deletes the alarm. */
    const handleSubmit = () => {
        if (!serviceAvailable) 
            return;

        setServiceAvailable(false);
        alarmService.delete()
            .finally(() => setServiceAvailable(true));

    }

    const symbol = props.modalData.symbol;
    
    return (
        <Modal title="Delete Alarm" id="delete-alarm-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                <div className="message" dangerouslySetInnerHTML={{ __html: t('AlarmDeleteMessage') }}></div>
                <button type="submit" className="submit-button" onClick={handleSubmit}>
                    {
                        serviceAvailable? 
                            t('Close') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    modalData: state.modal.alarmDelete.data,
})

export default connect(mapStateToProps)(AlarmDeleteModal);