import IMAGES from 'assets/images';
import useTranslation from 'modules/localization';
import React from 'react'
import { connect } from 'react-redux';
import { MomentumTypes } from 'shared/indicator/enums';
import { IndicatorMomentum } from 'store/slices/indicator-slice/types';
import { RootState } from 'store/types';
import AccordionListItem from '../src/item';

interface Props {
    data: IndicatorMomentum
}

const MomentumItem = (props: Props) => {
    const [currentMomentum, setCurrentMomentum] = React.useState(MomentumTypes.VALUE_5);
    const { t } = useTranslation();

    const momentum = props.data? props.data[currentMomentum] : null;
    const image = momentum !== null? IMAGES.INDICATOR.MOMENTUM[momentum] : null;

    const getClassName = (type: MomentumTypes) => {
        return currentMomentum === type? 'button active-button' : 'button';
    }

    return (
        <AccordionListItem id={'momentum'} title={t('MomentumTitle')} icon="icofont-chart-bar-graph">
            <table className="momentum-table">
                <tbody>
                    <tr className="center-t">
                        <td colSpan={6}>
                            {
                                image?
                                   <img src={image.default} alt="" />
                                   : 
                                   <i className="icofont-spinner-alt-6 loader"></i>
                            }
                        </td>
                    </tr>
                    <tr className="center-t">
                        <td className={getClassName(MomentumTypes.VALUE_5)} onClick={() => setCurrentMomentum(MomentumTypes.VALUE_5)}>M5</td>
                        <td className={getClassName(MomentumTypes.VALUE_15)} onClick={() => setCurrentMomentum(MomentumTypes.VALUE_15)}>M15</td>
                        <td className={getClassName(MomentumTypes.VALUE_30)} onClick={() => setCurrentMomentum(MomentumTypes.VALUE_30)}>M30</td>
                        <td className={getClassName(MomentumTypes.VALUE_60)} onClick={() => setCurrentMomentum(MomentumTypes.VALUE_60)}>H1</td>
                        <td className={getClassName(MomentumTypes.VALUE_240)} onClick={() => setCurrentMomentum(MomentumTypes.VALUE_240)}>H4</td>
                        <td className={getClassName(MomentumTypes.VALUE_1440)} onClick={() => setCurrentMomentum(MomentumTypes.VALUE_1440)}>D1</td>
                    </tr>
                </tbody>
            </table>
        </AccordionListItem>
    )
}


const mapStateToProps = (state: RootState) => ({
    data: state.indicator.momentum
})

export default connect(mapStateToProps)(MomentumItem);