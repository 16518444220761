import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import useTranslation from 'modules/localization';
import { accountService } from 'services';
import { toast } from 'react-toastify';
import Api, { ApiTypes } from '../../../api';

const PasswordModal = () => {
    const [currentPassword, setCurrentPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const { t } = useTranslation();

    const handleClose = () => storeManager.modal.setPassword({ visibility: false, data: null });

    const handleSubmit = async() => {
        // TODO
        if (!currentPassword.length  || !newPassword.length) {
            toast(t('PasswordRequiredMessage'));
            return;
        } else if (newPassword !== confirmPassword) {
            toast(t('PasswordConfirmationRequiredMessage'));
            return;
        }

        const input: ApiTypes.ChangePasswordInput = new Api.ChangePasswordInput({
            currentPassword,
            newPassword
        });

        await accountService.changePassword(input)
        .then((response: any) => {
            if (response.success) {
                handleClose();
            } else {
                toast(response?.message);
            }
            
        })
        .catch((reason) => {
            console.log(reason);
        })
    }

    return (
        <Modal title={t('ChangePassword')} id="change-password-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <label htmlFor="change-password-current">{t('CurrentPassword')}</label>
                <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} id="change-password-current" />
                
                <label htmlFor="change-password-new">{t('NewPassword')}</label>
                <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} id="change-password-new" />
                
                <label htmlFor="change-password-confirm">{t('ConfirmNewPassword')}</label>
                <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} id="change-password-confirm" />

                <button type="submit" className="change-password" onClick={handleSubmit}>{t('ChangePassword')}</button>
            </form>
        </Modal>
    )
}

export default PasswordModal;