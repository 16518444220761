import { OpenOrderInfo } from "shared/symbol/types";
import { ExposureToolData } from "./types";

class ExposureToolHelper {
    public getData = (openOrders: OpenOrderInfo[]) => {
        const result: {[property: string]: ExposureToolData} = {};

        if (openOrders?.length) {
            openOrders.forEach(order => {
                const classItem: ExposureToolData = { 
                    ...result[order.symbol], 
                    symbol: order.symbol, 
                    digit: order.digits 
                }

                if (order.type === 'buy'.toUpperCase()) {
                    classItem.buyDeals = classItem.buyDeals ? classItem.buyDeals + 1 : 1;
                    classItem.buyPrice = classItem.buyPrice ? classItem.buyPrice + order.openPrice : order.openPrice;
                    classItem.buyLots = classItem.buyLots ? classItem.buyLots + order.volume : order.volume;
                    classItem.buyProfit = classItem.buyProfit ? classItem.buyProfit + order.profit : order.profit;
                } else {
                    classItem.sellDeals = classItem.sellDeals ? classItem.sellDeals + 1 : 1;
                    classItem.sellPrice = classItem.sellPrice ? classItem.sellPrice + order.openPrice : order.openPrice;
                    classItem.sellLots = classItem.sellLots ? classItem.sellLots + order.volume : order.volume;
                    classItem.sellProfit = classItem.sellProfit ? classItem.sellProfit + order.profit : order.profit;
                }

                result[order.symbol] = classItem;
            })
        }

        return result;
    }
}

const exposureToolHelper = new ExposureToolHelper();
export default exposureToolHelper;