import useTranslation from 'modules/localization';
import React from 'react';
import getServerTime from './getServerTime';
import { ChartsServiceProxy } from '../../api/service-proxies';
import getAccountNo from 'shared/utils/getAccountNo';
import getPlatformCode from 'shared/utils/getPlatformCode';
import { Nullable } from '../../shared/global/types';
import { ApiResponse } from "shared/api/constants";


const ServerTime = () => {
    const [serverTime, setServerTime] = React.useState('...');
    const { t } = useTranslation();

    React.useEffect(() => {

        let timerEvent: Nullable<NodeJS.Timer> = null;
        const chartsServiceProxy: ChartsServiceProxy = new ChartsServiceProxy();
        const accountNo = getAccountNo();
        const platformCode = getPlatformCode();

        const fetchData = async () => {
            const timeResponse: ApiResponse<any> = await chartsServiceProxy.time(accountNo, platformCode) as any;

            let time: number = timeResponse.data as number;

            timerEvent = setInterval(() => {
                const timeText = getServerTime(time);
                setServerTime(timeText);
                time++;
            }, 1000) // every sec

        }

        fetchData();

        return (() => {
            if (timerEvent != null) { clearInterval(timerEvent); }
        })

    }, []);

    return (
        <div className="server-time">
            <div className="title">
                {t('ServerTime')} (UTC)
            </div>
            <div className="time">
                {serverTime}
            </div>
        </div>
    )
}

export default ServerTime;