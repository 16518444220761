import { AxiosResponse } from "axios";
import { ApiResponse } from "shared/api/constants";

const transformResult = (url: string | null, axiosResponse: AxiosResponse, process: (axiosResponse: AxiosResponse) => Promise<any>) => {
    return new Promise<any>(async (resolve, reject) => {
        const result = new ApiResponse();
    
        const response = JSON.parse(axiosResponse.request?.response) || null;
        if (response?.error) {
            result.success = false;
            result.message = response.error.message;
            
        } else {

            if (axiosResponse.data?.result !== undefined) {
                axiosResponse.data = axiosResponse.data?.result;
            }

            result.success = true;
            result.data = await process(axiosResponse); 
        }
        
        resolve(result);
    })
}

export default transformResult;