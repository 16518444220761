import React from 'react';
// Components
import WatchListContainer from './container';
import WatchListHeader from './header';

const WatchList = () => {
    const filter = React.useState('');

    return (
        <div className="watchlist">
            <WatchListHeader useFilter={filter} />
            <WatchListContainer useFilter={filter} />
        </div>
    )
}

export default WatchList;