import IMAGES from "assets/images";

// <types>
export type SortedColumn = { type: SortType, key: string, isReversed: boolean } | null;
type SortType = 'number' | 'string';
type ChangeSortedColumnInput = {
    columnKey: string,
    columnType: SortType,
    sortStateGetter: SortedColumn,
    sortStateSetter: Function
}
type GetSortedDataInput = { 
    data: any[],  
    sortStateGetter: SortedColumn,
}
type RenderSortedColumnIconInput = {
    columnKey: string,
    sortStateGetter: SortedColumn,
}  
// </types>

class TableColumnSortHelper {
    /** Description: Changes the sorted column. */
    public changeSortedColumn = (input: ChangeSortedColumnInput) => {
        const { sortStateGetter: sortValue, sortStateSetter: setSortValue, columnKey: key, columnType: type } = input;

        if (!sortValue || sortValue.key !== key) {
            setSortValue({ key, type, isReversed: false });

        } else if (sortValue) {
            if (!sortValue.isReversed) {
                setSortValue({ key, type, isReversed: true });

            } else {
                setSortValue(null);

            }

        }     
    }

    /** Description: Returns the sorted data. */
    public getSortedData = (input: GetSortedDataInput) => {
        const { sortStateGetter: sortValue, data } = input;

        let sortedData = [...data];

        if (sortValue?.key) {
            sortedData = sortedData.sort((orderA, orderB) => {
                const sortKey = sortValue.key;

                if (sortValue.isReversed) {
                    return sortValue.type === 'number'? 
                        (orderA[sortKey] - orderB[sortKey]) : (orderB[sortKey].localeCompare(orderA[sortKey]));
                } else {
                    return sortValue.type === 'number'?
                        (orderB[sortKey] - orderA[sortKey]) : (orderA[sortKey].localeCompare(orderB[sortKey]));
                }
            })
        }

        return sortedData;
    }

    /** Description: Renders the sorted column icon. */
    public renderSortedColumnIconInput = (input: RenderSortedColumnIconInput) => {
        const { sortStateGetter: sortValue, columnKey } = input;

        if (columnKey === sortValue?.key && !sortValue?.isReversed) {
            return (
                <img src={IMAGES.ICON.SORT_UP} style={{ marginLeft: '5px', width: '10px' }} alt="" />
            )

        } else if (columnKey === sortValue?.key && sortValue?.isReversed) {
            return (
                <img src={IMAGES.ICON.SORT_DOWN} style={{ marginLeft: '5px', width: '10px' }} alt="" />
            )
        }

        return null;
    }

}

const tableColumnSortHelper = new TableColumnSortHelper();
export default tableColumnSortHelper;