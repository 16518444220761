import { toast } from "react-toastify";
import isNumber from "shared/utils/isNumber";

// todo: should be moved to constants folder
const OrderConstants = {
    INVALID_CMD: 'Cmd is not valid.',
    INVALID_AMOUNT: 'Amount is not valid.',
    INVALID_PRICE: 'Price is not valid.',
    INVALID_TP_SL: 'Take profit and stop loss values must be the number type.'
}

class OrderValidatorHelper {
    public validateCmd = (cmd: number) => {
        const isValid = cmd !== -1; 
        if (!isValid) {
            toast(OrderConstants.INVALID_CMD);
        }   

        return isValid;
    }

    public validateAmount = (amount: any) => {
        const isValid = isNumber(amount) && 0 < amount;
        if (!isValid) {
            toast(OrderConstants.INVALID_AMOUNT);
        }

        return isValid;
    }

    public validatePrice = (price: any) => {
        const isValid = isNumber(price) && price > 0 ;
        if (!isValid) {
            toast(OrderConstants.INVALID_PRICE);
        }

        return isValid;
    }

    public validateTakeProfitAndStopLoss = (takeProfit: any, stopLoss: any) => {
        let isValid = true;

        if (!(isNumber(takeProfit) && isNumber(stopLoss))) {
            isValid = false;
            toast(OrderConstants.INVALID_TP_SL)
        } // ...

        return isValid;
    }
}

export default OrderValidatorHelper;