import { EntityStatus } from "shared/global/enums";
import { StoreMiddlewareEntity } from "shared/global/entities";
import { AccountState } from "./types";

const accountState: AccountState = {
    traderNo: null,
    user: null,
    loginInformations: new StoreMiddlewareEntity({ data: null, status: EntityStatus.IDLE, updateTime: null }),
    tradingStatus: null,
}

export default accountState;