import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import AccordionListItem from '../src/item';
import { IndicatorLevel } from 'store/slices/indicator-slice/types';
import IMAGES from 'assets/images';
import { SymbolAnalysis } from 'store/slices/symbol-slice/types';
import useTranslation from 'modules/localization';

interface Props {
    data: IndicatorLevel,
    symbol: SymbolAnalysis,
}

const LevelItem = (props: Props) => {
    const { t } = useTranslation(); 

    const dailyHigh = props.data?.dailyHigh || '...';
    const weeklyHigh = props.data?.weeklyHigh || '...';
    const monthlyHigh = props.data?.monthlyHigh || '...';
    const dailyLow = props.data?.dailyLow || '...';
    const weeklyLow = props.data?.weeklyLow || '...';
    const monthlyLow = props.data?.monthlyLow || '...';
    const last50BarByIndicator = props.data?.last50BarByIndicator || '...';
    const last200BarByIndicator = props.data?.last200BarByIndicator || '...';
    const dailyRange = props.data?.dailyRange || '...';
    const yesterdayClose = props.data?.yesterdayClose || '...';
    const yesterdayHigh = props.data?.yesterdayHigh || '...';
    const yesterdayLow = props.data?.yesterdayLow || '...';

    return (
        <AccordionListItem id={'level'} title={t('LevelsTitle')} icon="icofont-listine-dots">
            <table className="level-table">
                <tbody>
                    <tr className="center-t">
                        <td colSpan={3}>{props.symbol}</td>
                    </tr>
                    <tr className="center-t">
                        <td colSpan={3}>
                            <div className="flex">
                                <span>{t('High')}</span>
                                <img src={IMAGES.ICON.UP} alt="" />
                            </div>
                        </td>
                    </tr>
                    <tr className="center-t">
                        <td>{dailyHigh}</td>
                        <td>{weeklyHigh}</td>
                        <td>{monthlyHigh}</td>
                    </tr>
                    <tr className="center-t"> 
                        <td>{t('Daily')}</td>
                        <td>{t('Weekly')}</td>
                        <td>{t('Monthly')}</td>
                    </tr>
                    <tr className="center-t">
                        <td>{dailyLow}</td>
                        <td>{weeklyLow}</td>
                        <td>{monthlyLow}</td>
                    </tr>
                    <tr className="center-t">
                        <td colSpan={3}>
                            <div className="flex">
                                <span>{t('Low')}</span>
                                <img src={IMAGES.ICON.DOWN} alt="" />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="right-t" colSpan={2}>{t('DaysAverage50')}</td>
                        <td className="center-t">{last50BarByIndicator}</td>
                    </tr>
                    <tr>
                        <td className="right-t" colSpan={2}>{t('DaysAverage200')}</td>
                        <td className="center-t">{last200BarByIndicator}</td>
                    </tr>
                    <tr>
                        <td className="right-t" colSpan={2}>{t('DailyRange')}</td>
                        <td className="center-t">{dailyRange}</td>
                    </tr>
                    <tr>
                        <td className="right-t" colSpan={2}>{t('YesterdayClose')}</td>
                        <td className="center-t">{yesterdayClose}</td>
                    </tr>
                    <tr>
                        <td className="right-t" colSpan={2}>{t('YesterdayHigh')}</td>
                        <td className="center-t">{yesterdayHigh}</td>
                    </tr>
                    <tr>
                        <td className="right-t" colSpan={2}>{t('YesterdayLow')}</td>
                        <td className="center-t">{yesterdayLow}</td>
                    </tr>
                </tbody>
            </table>
        </AccordionListItem>
    )
}

const mapStateToProps = (state: RootState) => ({
    data: state.indicator.level,
    symbol: state.symbol.analysis,
})

export default connect(mapStateToProps)(LevelItem);