import SymbolPrice from 'components/symbol-price';
import React from 'react';
import { OrderSides, OrderTypes, SymbolFeedDirections } from 'shared/symbol/constants';
import storeManager from 'store/manager';
import { WatchListItemProps } from './types';
import { default as sortHelper }  from './sort-helper';
import useTranslation from 'modules/localization';
import { chartService, indicatorService, symbolService } from 'services';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import storageManager from 'modules/storage-manager';

const WatchListItem = (props: WatchListItemProps) => {
    const { t } = useTranslation();
    const prevPrices = symbolService.getPrevPrices(props.symbol);
    const defaultAmount = storageManager.preferences.get()?.oneClickTradingValues?.[props.symbol] || '0.10'; 

    const [bidColor, setBidColor] = React.useState(SymbolFeedDirections.DEFAULT);
    const [askColor, setAskColor] = React.useState(SymbolFeedDirections.DEFAULT);
    const [amount, setAmount] = React.useState(defaultAmount);

    const openOrderCreateModal = (orderSide: OrderSides) => storeManager.modal.setOrderCreate({ visibility: true, data: {
        symbol: props.symbol,
        ask: props.ask,
        bid: props.bid,
        price: orderSide === OrderSides.SELL? props.bid.toFixed(props.digit) : props.ask.toFixed(props.digit),
        digit: props.digit,
        amount: amount,
        takeProfit: '0',
        stopLoss: '0',
        orderSide: orderSide,
        orderType: OrderTypes.MARKET,
    } });

    const openAlarmCreateModal = (orderData: WatchListItemProps) => storeManager.modal.setAlarmCreate({ visibility: true, data: {
        ...orderData,
    }})

    // <AmountInput>
    const changeAmount = (value: string) => {
        setAmount(value);

        let oneClickTradingValues: any = storageManager.preferences.get()?.oneClickTradingValues;
        if (!oneClickTradingValues) {
           oneClickTradingValues = {};
        }

        storageManager.preferences.update({ 
            oneClickTradingValues: {
                ...oneClickTradingValues,
                [props.symbol]: value
            }
        })
    }

    const addAmount = (value: number) => {
        let quantity = parseFloat(amount);
        quantity += value;

        const amountValue = quantity.toFixed(2);

        changeAmount(amountValue);
    }


    const subtractAmount = (value: number) => {
        let quantity = parseFloat(amount);
        quantity -= value;

        if (quantity <= 0.01) {
            quantity = 0.01;
        }
        
        const amountValue = quantity.toFixed(2);

        changeAmount(amountValue);
    }
    // </AmountInput>

    // <SortFeature>
    const handleItemMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        const target: Element = event.target as any;

        if (target.tagName !== 'INPUT') {
            sortHelper.handleItemMouseDown(props.symbol)
        }
    }

    const getSortStyles = () => {
        let styles: React.CSSProperties | undefined = {
            userSelect: 'none',
        };
        
        if (props.useSort[0] !== null && props.useSort[0] !== props.symbol) {
            styles.opacity = "0.15";
        }

        return styles;
    }

    React.useEffect(() => {
        const _amount = storageManager.preferences.get()?.oneClickTradingValues?.[props.symbol]; 
        if (_amount) {
            setAmount(_amount);
        }

    }, [props.symbol, props.useSort])
    // </SortFeature>

    /** Description: Updates the bid, ask colors */
    React.useEffect(() => {
        const updateDirectionColors = () => {
            let _bidColor = bidColor;
            let _askColor = askColor;
    
            if (prevPrices) {
                const prevBid = prevPrices.bid;
                const prevAsk = prevPrices.ask;

                // Bid color
                if (props.bid) {

                    if (props.bid > prevBid)
                        _bidColor = SymbolFeedDirections.UP;
    
                    else if (props.bid < prevBid)
                        _bidColor = SymbolFeedDirections.DOWN;

                    else {
                        if ((props.time - prevPrices.updatedTime) >= symbolService.MIN_COLOR_CHANGE_TIME && prevPrices.bid === props.bid) {
                            _bidColor = SymbolFeedDirections.DEFAULT;
                        }
                    }
                    
                }
        
                // Ask color
                if (props.ask) {
                    if (props.ask > prevAsk)
                        _askColor = SymbolFeedDirections.UP;
        
                    else if (props.ask < prevAsk)
                        _askColor = SymbolFeedDirections.DOWN;

                    else {
                        if ((props.time - prevPrices.updatedTime) >= symbolService.MIN_COLOR_CHANGE_TIME && prevPrices.ask === props.ask) {
                            _askColor = SymbolFeedDirections.DEFAULT;
                        }
                    }
                }
            }
            
            setBidColor(_bidColor);
            setAskColor(_askColor);
        }

        updateDirectionColors();
    }, [askColor, bidColor, prevPrices, props.ask, props.bid, props.time])

    return (
        <div className="watchlist-item" style={getSortStyles()} onMouseEnter={() => sortHelper.handleItemMouseEnter(props.symbol)} onMouseUp={sortHelper.handleItemMouseUp}>
            <div className="header" onMouseDown={handleItemMouseDown} >
                <div className="symbol-title">
                    {props.symbol}
                </div>
                <div className="buttons">
                    <div className="container">
                        <button onClick={() => openAlarmCreateModal(props)}>
                            <i className="icofont-alarm icon-button-color"></i>
                        </button>
                        <button onClick={() => chartService.changeSelectedTab(props.symbol)}>
                            <i className="icofont-chart-line-alt icon-button-color"></i>
                        </button>
                        <button className="analysis-button" onClick={() => indicatorService.setAnalysis(props.symbol)}>
                            <i className="icofont-rounded-double-right icon-button-color"></i>
                        </button>
                        <button className="btn-order" style={getSortStyles()} onClick={() => sortHelper.handleItemMouseEnter(props.symbol)}>
                            <i className="icofont-drag icon-button-color"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="body">
                <div className={'tm-sell column ' + (askColor)} onClick={() => openOrderCreateModal(OrderSides.SELL)}>
                    <div className="price">
                        <SymbolPrice price={props.bid} digit={props.digit} />
                    </div>
                    <div className="price-label tm-pl-sell">
                        {t('Sell')?.toUpperCase()}
                    </div>
                </div>
                <div className={'tm-buy column ' + (bidColor)} onClick={() => openOrderCreateModal(OrderSides.BUY)}>
                    <div className="price">
                        <SymbolPrice price={props.ask} digit={props.digit} />
                    </div>
                    <div className="price-label tm-pl-buy">
                        {t('Buy')?.toUpperCase()}
                    </div>
                </div>
                <div className="tm-one-click__trade">
                <button className="tm-oct__down" onClick={() => subtractAmount(0.1)}><i className="icofont-minus"></i></button>
                    <input className="amount-input" type="number" step="0.01" min="0.01" value={amount} onChange={(e) => changeAmount(e.currentTarget.value)} />
                    <button className="tm-oct__up" onClick={(e) => addAmount(0.1)}><i className="icofont-plus"></i></button>
                </div>
            </div>           
            <div className="footer">
                <div className="column">
                    {t('Low')?.toUpperCase()} : {props.low.toFixed(props.digit)}
                </div>
                <div className="column">
                    {props.high.toFixed(props.digit)} : {t('High')?.toUpperCase()}
                </div>
            </div>
        </div>
    )
}

const propsAreEqual = (prevProps: WatchListItemProps, nextProps: WatchListItemProps) => {
    const dependencies = ['digit', 'high', 'low', 'ask', 'bid', 'useSort', 'oneClickTrading'];
    const equals = dependencies.find((dependency) => (prevProps as any)[dependency] !== (nextProps as any)[dependency]);
    if (equals) 
        return false;
    
    return true;
}

const memoizedWatchListItem = React.memo(WatchListItem, propsAreEqual); 

const mapStateToProps = (state: RootState) => ({
    oneClickTrading: state.app.oneClickTrading
})

export default connect(mapStateToProps)(memoizedWatchListItem);