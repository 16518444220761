import { createAsyncThunk } from "@reduxjs/toolkit";
import Api, { ApiTypes } from "api";
import getAccountNo from "shared/utils/getAccountNo";
import getPlatformCode from "shared/utils/getPlatformCode";
import { ApiResponse } from "../../shared/api/constants";

class OrderThunkService {
    private readonly _orderService;

    constructor() {
        this._orderService = new Api.OrdersServiceProxy();
    }

    /** Description: Returns the order history. */
    public getOrderHistory = createAsyncThunk(
        'app/getOrderHistory',
        async (args: any, thankAPI) => {
            return new Promise(async (resolve, reject) => {
                const accountNo = getAccountNo();
                const platformCode = getPlatformCode();
    
                const inputDto = new Api.GetOrderHistoryInputDto({
                    accountNo: accountNo,
                    platformCode: platformCode,
                    startDate: args?.startDate,
                    endDate: args?.endDate
                })
    
                const response: ApiResponse<ApiTypes.GetOrderHistoryOutputDto> = await this._orderService.getOrderHistory(inputDto) as any;
                if (response.success) {
                    resolve(response.data.histories as any);
                } else {
                    reject(response.message);
                }
            })
        } 
    )
}

export default OrderThunkService;
