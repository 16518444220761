import React from 'react';
import IMAGES from 'assets/images';
import { RadioButtonProps } from './interfaces';

const RadioButton = (props: RadioButtonProps) => {
    return (
        <div className="radio-button" onClick={() => props.check!()}>
            <div className={"icon" + (props.selectedValue === props.value? ' active' : '')}>
                <img src={IMAGES.ELEMENTS.RADIO} alt="" draggable="false"/>
            </div>
            <span>
                {props.label}
            </span>
        </div>
    )
}

export default RadioButton;