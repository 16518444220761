import { StorageDecrypt, StorageEncrypt, StorageItem, StorageItemOptions, StorageName, StorageParseJson } from "./types";

class LocalStorageItem<T> implements StorageItem {
    private _storageName: StorageName;
    private _encrypt: StorageEncrypt | undefined;
    private _decrypt: StorageDecrypt | undefined;
    private _parseJson: StorageParseJson | undefined;

    constructor(options: StorageItemOptions) {
        this._storageName = options.storageName;
        this._parseJson = options?.parseJson;
        this._encrypt = options?.encrypt;
        this._decrypt = options?.decrypt;
    }

    public get(): T | null | undefined {
        try {
            let data = localStorage.getItem(this._storageName);
            if (data) {
                if (this._decrypt) {
                    data = this._decrypt();
                }
        
                if (this._parseJson) {
                    data = JSON.parse(data as string);
                }
        
                return data as T;
            }

            return null;

        } catch (error) {
            console.error('[LOCAL.STORAGE.MANAGER:GET] ' + error);
            return undefined;
        }
    }

    public set(data: T) {
        try {
            let storageData: T | string = data;

            if (this._parseJson) {
                storageData = JSON.stringify(storageData);
            }
    
            if (this._encrypt)  {
                storageData = this._encrypt();
            }
    
            localStorage.setItem(this._storageName, storageData as string);

            return true;

        } catch (error) {
            console.error('[LOCAL.STORAGE.MANAGER:SET] ' + error);
            return undefined;
        }
    }

    public update(data: T) {
        try {
            let storageData = this.get();
            
            if (!storageData) 
                storageData = {} as T;

            storageData = {
                ...storageData,
                ...data
            }
                
            const result = this.set(storageData);
            return result;

        } catch(error) {
            console.error('[LOCAL.STORAGE.MANAGER:UPDATE] ' + error);
            return undefined;
        }
    }

    public remove() {
        try {
            localStorage.removeItem(this._storageName);
            return true;

        } catch(error) {
            console.error('[LOCAL.STORAGE.MANAGER:REMOVE] ' + error);
            return undefined;
        }
    }
}

export default LocalStorageItem;