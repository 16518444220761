import React from 'react';
import socket from 'socket';
import { connect } from 'react-redux';
import { SymbolAnalysis, SymbolWatchList } from 'store/slices/symbol-slice/types';
import { RootState } from 'store/types';
import { indicatorService, symbolService } from 'services';
import { ChartTabs } from 'store/slices/chart-slice/types';
import getPlatformCode from 'shared/utils/getPlatformCode';
import { OrderOpen, OrderPending } from 'store/slices/order-slice/types';

interface Props {
    watchList: SymbolWatchList,
    analysis: SymbolAnalysis,
    chartTabs: ChartTabs,
    openOrders: OrderOpen,
    pendingOrders: OrderPending
}

const FeedHub = (props: Props) => {
    const [openOrders, setOpenOrders] = React.useState<string[]>([]);

    indicatorService.analysis = props.analysis;

    React.useEffect(() => {
        socket.createFeedSocket();

        return (() => {
            socket.feed.close();
        })
    }, [])

    React.useEffect(() => {
        const getOpenOrders = () => {
            const openOrderSymbols = props.openOrders?.map((order: any) => order.symbol); 
            const pendingOrderSymbols = props.pendingOrders?.map((order: any) => order.symbol); 

            const symbols = [...openOrderSymbols, ...pendingOrderSymbols].filter((symbol, index, self) => self.indexOf(symbol) === index);

            return symbols;
        }
        
        const orders = getOpenOrders();
        if (orders.length === openOrders.length) {
            const isNotEqual = openOrders.some((order) => !orders.includes(order))
            if (isNotEqual) {
                setOpenOrders(orders);
                
            }

        } else {
            setOpenOrders(orders);
        }
        
    }, [openOrders, props.openOrders, props.pendingOrders])

    React.useEffect(() => {
        if (socket.feed ) {
        
            const getSubscriptionList = () => {
                const platformCode = getPlatformCode();

                let subs = [...props.watchList, props.analysis, ...props.chartTabs, ...openOrders];
                subs = subs.filter((symbol, index, self) => self.indexOf(symbol) === index);
                
                const feedSubs = subs.map((symbol) => `${platformCode}_${symbol}_Feed`);
                subs = [...subs, ...feedSubs];
        
                return subs; 
            }
            
            const changeSubscription = () => {
                const subscriptions = getSubscriptionList();
                socket.feed.update(subscriptions);
            }    
    
            const connectionState = socket.feed.getStatus();
            if (!connectionState) {
                socket.feed.start()
                .then(() => {
                    changeSubscription();
                    symbolService.storeStorageValues();
                })
            } else {
                changeSubscription();
            }

        } else {
            console.error('ERROR! Feed socket is not connected.');
        }

    }, [props.analysis, props.watchList, props.chartTabs, openOrders])
    
    return null;
}

const mapStateToProps = (state: RootState) => ({
    watchList: state.symbol.watchList,
    analysis: state.symbol.analysis,
    chartTabs: state.chart.tabs,
    openOrders: state.order.open,
    pendingOrders: state.order.pending
})

export default connect(mapStateToProps)(FeedHub);