import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import useTranslation from 'modules/localization';

const OneClickTradingDisclaimerModal = () => {
    const [isAccepted, setIsAccepted] = React.useState(false);
    const { t } = useTranslation();

    const handleClose = () => {
        storeManager.modal.setOneClickTradingDisclaimer({
            visibility: false,
            data: null
        })
    }

    const handleConfirm = () => {
        handleClose();

        storeManager.modal.setSettings({ 
            visibility: true, 
            data: {
                isAccepted
            }
        });
    }

    return (
        <Modal title="Disclaimer" id="oneclicktrading-disclaimer-modal" close={handleClose}>
            <div className="content">
                <div className="text-item" dangerouslySetInnerHTML={{ __html: t('OneClickTradingDisclaimer') }}></div>
            </div>
            <div className="controls">
                <div className="check-terms">
                    <input type="checkbox" id="oneclicktrading-terms" checked={isAccepted} onChange={(e) => setIsAccepted(e.currentTarget.checked)} />
                    <label htmlFor="oneclicktrading-terms">{t('AcceptTermsAndConditions')}</label>
                </div>
                <div className="control-buttons">
                    <button className="confirm" onClick={handleConfirm}>{t('Confirm')}</button>
                    <button className="cancel" onClick={handleClose}>{t('Cancel')}</button>
                </div>
            </div>
        </Modal>
    )
}

export default OneClickTradingDisclaimerModal;