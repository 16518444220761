const IMAGES = {
    INDICATOR: {
        MAP: require('./indicators/indicator-map.svg'),
        MOMENTUM: [
            require('./indicators/indicator-momentum-0.svg'),
            require('./indicators/indicator-momentum-1.svg'),
            require('./indicators/indicator-momentum-2.svg'),
            require('./indicators/indicator-momentum-3.svg'),
            require('./indicators/indicator-momentum-4.svg'),
            require('./indicators/indicator-momentum-5.svg'),
            require('./indicators/indicator-momentum-6.svg'),
            require('./indicators/indicator-momentum-7.svg'),
            require('./indicators/indicator-momentum-8.svg'),
            require('./indicators/indicator-momentum-9.svg'),
        ],
        TREND: [
            require('./indicators/indicator-trend-0.svg'),
            require('./indicators/indicator-trend-1.svg'),
            require('./indicators/indicator-trend-2.svg'),
            require('./indicators/indicator-trend-3.svg'),
            require('./indicators/indicator-trend-4.svg'),
            require('./indicators/indicator-trend-5.svg'),
            require('./indicators/indicator-trend-6.svg'),
            require('./indicators/indicator-trend-7.svg'),
            require('./indicators/indicator-trend-8.svg'),
            require('./indicators/indicator-trend-9.svg'),
            require('./indicators/indicator-trend-10.svg'),
            require('./indicators/indicator-trend-11.svg'),
            require('./indicators/indicator-trend-12.svg'),
            require('./indicators/indicator-trend-13.svg'),
            require('./indicators/indicator-trend-14.svg'),
            require('./indicators/indicator-trend-15.svg'),
            require('./indicators/indicator-trend-16.svg'),

        ],
    },
    ICON: {
        UP: require('./icons/icon-up.svg').default,
        DOWN: require('./icons/icon-down.svg').default,
        SORT_UP: require('./icons/icon-sort-up.svg').default,
        SORT_DOWN: require('./icons/icon-sort-down.svg').default,
        TPSL_BUY: require('./icons/icon-tpsl-buy.svg').default,
        TPSL_SELL: require('./icons/icon-tpsl-sell.svg').default,
        LOGOUT: require('./icons/icon-logout.svg'),
        MAIL_CONFIRM: require('./icons/mail-confirm.png')
    },
    ELEMENTS: {
        CHECKBOX_CHECKED: require('./elements/checkbox-checked.svg').default,
        CHECKBOX_UNCHECKED: require('./elements/checkbox-unchecked.svg').default,
        RADIO: require('./elements/element-radio.png')
    },
    THEME: {
        ADVANCED: require('./_/theme-advanced.svg').default,
        BASIC: require('./_/theme-basic.svg').default,
    }
}

export default IMAGES;