import React from 'react';
import useTranslation from 'modules/localization';

const WatchListPassiveItem = (props: { symbol: string }) => {
    const { t } = useTranslation();
   
    return (
        <div className="watchlist-item" >
            <div className="header" >
                <div className="symbol-title">
                    {props.symbol}
                </div>
                <div className="description">
                    {t('NoData')}
                </div>
            </div>
        </div>
    )
}

export default WatchListPassiveItem;