import React from "react";
import { useSelector } from "react-redux";
import Api, { ApiTypes } from "../../../api";
import { appConfig } from "../../../app/config";
import useTranslation from "../../../modules/localization";
import { accountService } from "../../../services";
import getAccountNo from "../../../shared/utils/getAccountNo";
import getPlatformCode from "../../../shared/utils/getPlatformCode";
import storeManager from "../../../store/manager";
import { RootState } from "../../../store/types";
import Modal from "../../modal";

const TradingAccountsModal = () => {
    const logins = accountService.getLogins();
    const brokers: ApiTypes.BrokerSummaryDto[] = useSelector((state: RootState) => state.account.loginInformations.data.brokers);
    const allowTradingPlatformOperations = useSelector((state: RootState) => state.account.user?.allowTradingPlatformOperations); 
    const currentTradingNo = getAccountNo();
    const currentPlatformCode = getPlatformCode();
    const { t } = useTranslation();

    const handleClose = () => {
        storeManager.modal.setTradingAccounts({ visibility: false, data: null })
    }

    const handleLoginClick = (data: { platformCode: string, accountNo: number }) => {
        accountService.getTradingAccountInformations(data)
            .then(() => {
                storeManager.account.setTraderNo(data.accountNo);
                //storeManager.app.setLocation(appConfig.pages.trader.path);
            });

        handleClose();
    }


    return (
        <Modal title={t('TradingAccounts')} id="trading-accounts-modal" close={handleClose}>
            <div className="accounts">
                {
                    logins.length || allowTradingPlatformOperations ?
                        brokers.map((broker, brokerIndex) => {
                            return (
                                <div key={broker.name} className="broker">
                                    <div className="title">{broker.name}</div>
                                    {
                                        broker.tradingPlatforms!.map((tradingPlatform, tradingPlatformIndex) => {
                                            return (
                                                <div key={tradingPlatformIndex} className="platform">
                                                    {
                                                        (tradingPlatform.logins?.length || (tradingPlatform.serverType === Api.EnServerType.Demo && allowTradingPlatformOperations)) &&
                                                        <div className="code">{tradingPlatform.code}</div>
                                                    }
                                                    <ul>
                                                        {
                                                            tradingPlatform.logins!.map((accountNo) => {
                                                                const platformCode = tradingPlatform.code || '';
                                                                const loginButtonVisibility = tradingPlatform.code === currentPlatformCode && accountNo === currentTradingNo ? false : true;

                                                                return (
                                                                    <li key={accountNo}>
                                                                        <span><i className="icofont-user-alt-7"></i>{accountNo}</span>
                                                                        {
                                                                            loginButtonVisibility ? <button className="s-outline-button" onClick={() => handleLoginClick({ platformCode, accountNo })}>{t('Login')}</button> : <span className="selected-account"><i className="icofont-check-alt"></i></span>
                                                                        }
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            (tradingPlatform.serverType === Api.EnServerType.Demo && allowTradingPlatformOperations) &&
                                                            <li>
                                                                    <button className="p-outline-button" onClick={() => accountService.createDemoTradingAccount(brokerIndex, tradingPlatformIndex)}><i className="icofont-plus"></i> {t('CreateDemoAccount')}</button>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                        :
                        (
                            <span className='no-found'>
                                {t('AccountNotFound')}
                            </span>
                        )
                }
            </div>
        </Modal>    
    );
}

export default TradingAccountsModal;

