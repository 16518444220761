import storageManager from "modules/storage-manager";
import Dependencies from "shared/app/dependencies";
import { LayoutModes } from "shared/app/enums";
import isEnumMember from "shared/utils/isEnumMember";


class LayoutModeManager {
    private readonly _storeManager;
    private readonly _mode: LayoutModes = LayoutModes.ADVANCED; // default

    constructor(dependencies: Dependencies.StoreManager) {
        this._storeManager = dependencies.storeManager;    

        const storageValue = this.getStorageValue();
        if (storageValue) {
            this._mode = storageValue;
        }
    }

    /** Description: Returns the current app layout mode. */
    get() {
        const mode = this._storeManager.app.getMode();
        return mode;
    }

    /** Description: Changes the app layout mode. */
    set(mode: LayoutModes) {
        this._storeManager.app.setMode(mode);
        storageManager.preferences.update({ layoutMode: mode });
    }

    /** Description: Changes the app layout mode as default. */
    setAsDefault() {
        this.set(this._mode);
    }

    /** Description: Returns the stored value in local storage, if its exist. */
    private getStorageValue = () => {
        let result: LayoutModes | null = null;
        
        const storageValue = storageManager.preferences.get()?.layoutMode;
        if (storageValue) {
            const isMember = isEnumMember(LayoutModes, storageValue);
            if (isMember) {
                result = storageValue as LayoutModes;

            } else {
                storageManager.preferences.update({ layoutMode: undefined }) // deletes the unrecognized value
            } 
        }

        return result;
    }
}

export default LayoutModeManager; 