import storageItemBuilder from "./builder";
// Types
import { StorageItem } from "./builder/types";
import { IPreferencesStorageItem, StorageManager,  } from "./types";

class LocalStorageManager implements StorageManager {
    public user: StorageItem;
    public preferences: StorageItem<IPreferencesStorageItem>;

    constructor() {
        this.user = storageItemBuilder({ storageName: 'trademaster.user', parseJson: true })
        this.preferences = storageItemBuilder<IPreferencesStorageItem>({ storageName: 'trademaster.preferences', parseJson: true })
    }
}

const storageManager = new LocalStorageManager();
export default storageManager;