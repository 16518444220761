import React from 'react';
import useTranslation from 'modules/localization';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { TradingStatusProps } from './types';

const TradingAccountStatus = (props: TradingStatusProps) => {
    const { t } = useTranslation();
    
    const balance = props.tradingStatus?.balance?.toFixed(2) || '...';
    const equity = props.tradingStatus?.equity?.toFixed(2) || '...';
    const credit = props.tradingStatus?.credit?.toFixed(2) || '...';
    const margin = props.tradingStatus?.margin?.toFixed(2) || '...';
    const marginFree = props.tradingStatus?.marginFree?.toFixed(2) || '...';
    const marginLevel = props.tradingStatus? props.tradingStatus.marginLevel?.toFixed(2) + '%' : '...';
    const volume = props.tradingStatus?.volume?.toFixed(2) || '...';
    const profit = props.tradingStatus?.profit?.toFixed(2) || '...';
    
    return (
        <div className="trading-account-status">
            <div className="column">
                <div className="title">
                    {t('Balance')}
                </div>
                <div className="status">
                    {balance}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('Equity')}
                </div>
                <div className="status">
                    {equity}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('Credit')}
                </div>
                <div className="status">
                    {credit}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('Margin')}
                </div>
                <div className="status">
                    {margin}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('FreeMargin')}
                </div>
                <div className="status">
                    {marginFree}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('MarginLevel')} 
                </div>
                <div className="status">
                    {marginLevel}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('Volume')}
                </div>
                <div className="status">
                    {volume}
                </div>
            </div>
            <div className="column">
                <div className="title">
                    {t('ProfitAndLoss')}
                </div>
                <div className="status">
                    {profit}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    tradingStatus: state.account.tradingStatus,
})

export default connect(mapStateToProps)(TradingAccountStatus);