// source: https://www.tradinghours.com/
// open-close times of the global exchanges is the regular trading schedule
// updated Time: 20.07.2022

export enum MapStatuses {
    OPEN = 'map-green',
    CLOSE = 'map-red',
}

export enum MapLocations {
    NEW_YORK = 'newyork',
    LONDON = 'london',
    TOKYO = 'tokyo', 
}

export enum CountryCodes {
    NEW_ZEALAND = 'NZ',
    AUSTRALIA = 'AU',
    JAPAN = 'JP',
    CHINA = 'CN',
    UNITED_ARAB_EMIRATES = 'AE',
    RUSSIA = 'RU',
    SOUTH_AFRICA = 'ZA',
    TURKIYE = 'TR',
    GERMANY = 'GE',
    UNITED_KINGDOM = 'GB',
    BRAZIL = 'BR',
    UNITED_STATES = 'US',
    CANADA = 'CA',
}

export enum UtcOperations {
    POSITIVE = '+',
    NEGATIVE = '-'
}

export const countryUtcTimes = {
    [CountryCodes.NEW_ZEALAND]: {
        operation: UtcOperations.POSITIVE,
        value: 12,
    },
    [CountryCodes.AUSTRALIA]: {
        operation: UtcOperations.POSITIVE,
        value: 10,
    },
    [CountryCodes.JAPAN]: {
        operation: UtcOperations.POSITIVE,
        value: 9,
    },
    [CountryCodes.CHINA]: {
        operation: UtcOperations.POSITIVE,
        value: 8,
    },
    [CountryCodes.UNITED_ARAB_EMIRATES]: {
        operation: UtcOperations.POSITIVE,
        value: 4,
    },
    [CountryCodes.RUSSIA]: {
        operation: UtcOperations.POSITIVE,
        value: 3,
    },
    [CountryCodes.SOUTH_AFRICA]: {
        operation: UtcOperations.POSITIVE,
        value: 2,
    },
    [CountryCodes.TURKIYE]: {
        operation: UtcOperations.POSITIVE,
        value: 3,
    },
    [CountryCodes.GERMANY]: {
        operation: UtcOperations.POSITIVE,
        value: 2,
    },
    [CountryCodes.UNITED_KINGDOM]: {
        operation: UtcOperations.POSITIVE,
        value: 1,
    },
    [CountryCodes.BRAZIL]: {
        operation: UtcOperations.NEGATIVE,
        value: 3,
    },
    [CountryCodes.UNITED_STATES]: {
        operation: UtcOperations.NEGATIVE,
        value: 4,
    },
    [CountryCodes.CANADA]: {
        operation: UtcOperations.NEGATIVE,
        value: 4,
    },
}

export const globalStockExchanges = [
    {
        name: 'New Zealand Exchange (NZX)',
        micCode: 'XNZE',
        openTime: ['10.00'],
        closeTime: ['16.45'],
        countryCode: CountryCodes.NEW_ZEALAND,
    },
    {
        name: 'Australian Securities Exchange (ASX)',
        micCode: 'XASX',
        openTime: ['10.00'],
        closeTime: ['16.00'],
        countryCode: CountryCodes.AUSTRALIA,
    },
    {
        name: 'Tokyo Stock Exchange (JPX)',
        micCode: 'XJPX',
        openTime: ['09.0', '12.30'],
        closeTime: ['11.30', '15.00'],
        countryCode: CountryCodes.JAPAN,
    },
    {
        name: 'Hong Kong Exchange (HKEX)',
        micCode: 'XHKG',
        openTime: ['09.30', '13.00'],
        closeTime: ['12.00', '16.00'],
        countryCode: CountryCodes.CHINA,
    },
    {
        name: 'Shanghai Stock Exchange (SSE)',
        micCode: 'XSHG',
        openTime: ['09.30', '13.00'],
        closeTime: ['11.30', '15.00'],
        countryCode: CountryCodes.CHINA,
    },
    {
        name: 'Dubai International Stock Exchange (DFM)',
        micCode: 'XDFM',
        openTime: ['10.00'],
        closeTime: ['14.45'],
        countryCode: CountryCodes.UNITED_ARAB_EMIRATES,
    },
    {
        name: 'Moscow Exchange (MOEX)',
        micCode: 'MISX',
        openTime: ['09.50'],
        closeTime: ['18.39'],
        countryCode: CountryCodes.RUSSIA,
    },
    {
        name: 'Johannesburg Stock Exchange (JSE)',
        micCode: 'XJSE',
        openTime: ['09.00'],
        closeTime: ['17.00'],
        countryCode: CountryCodes.SOUTH_AFRICA,
    },
    {
        name: 'Borsa Istanbul Stock Exchange (BIST)',
        micCode: 'XIST',
        openTime: ['10.00'],
        closeTime: ['18.00'],
        countryCode: CountryCodes.TURKIYE,
    },
    {
        name: 'Frankfurt Stock Exchange (FSX)',
        micCode: 'XFRA',
        openTime: ['09.00'],
        closeTime: ['15.30'],
        countryCode: CountryCodes.GERMANY,
    },
    {
        name: 'London Stock Exchange (LSE)',
        micCode: 'XLON',
        openTime: ['08.00', '12.00'],
        closeTime: ['12.02', '16.30'],
        countryCode: CountryCodes.UNITED_KINGDOM,
    },
    {
        name: 'Brazil Stock Exchange (Bovespa)',
        micCode: 'BVMF',
        openTime: ['10.00'],
        closeTime: ['16.55'],
        countryCode: CountryCodes.BRAZIL,
    },
    {
        name: 'New York Stock Exchange (NYSE)',
        micCode: 'XNYS',
        openTime: ['09.30'],
        closeTime: ['16.00'],
        countryCode: CountryCodes.UNITED_STATES,
    },
    {
        name: 'Toronto Stock Exchange (TSX)',
        micCode: 'XTSE',
        openTime: ['09.30'],
        closeTime: ['16.00'],
        countryCode: CountryCodes.NEW_ZEALAND,
    },
]