import './styles.scss';
import React from 'react';
import Router from "router";
import storeManager from 'store/manager';
import { connect } from 'react-redux';
// Modules
import Authorization from 'modules/authorization';
// Components
import Socket from 'components/socket';
import Modal from 'components/modals/container';
// Types
import { AppProps, Language } from './types';
import { RootState } from 'store/types';
import { ToastContainer } from 'react-toastify';
import LocalizationContext from 'modules/localization/context';
import services, { appService, chartService } from 'services';
import pageLoaderHelper from 'helpers/page-loader-helper';
import { appConfig } from './config';
import { useLocation } from 'react-router-dom';


const App = (props: AppProps) => {

    const location = useLocation();
    const useLanguage = React.useState<Language>(null);

    React.useEffect(() => {
        if (location.pathname.toLowerCase() === appConfig.pages.login.path) {
            if (location.search) {
                services.injectDependencies({
                    dispatch: props.dispatch,
                    storeManager: storeManager,
                });

                const params = new URLSearchParams(location.search);
                const user = params.get('user') as string;
                const userFixed = user.replace('accessToken', 'token');

                localStorage.setItem('trademaster.user', userFixed);
                window.location.href = appConfig.pages.tradingAccounts.path;
            }
        }
        if (location.pathname.toLowerCase() === appConfig.pages.tvChart.path) {
            if (location.search) {
                services.injectDependencies({
                    dispatch: props.dispatch,
                    storeManager: storeManager,
                });

                const params = new URLSearchParams(location.search);
                const user = params.get('user') as string;
                const symbol = params.get('symbol') as string;
                
                localStorage.setItem('trademaster.user', user);
                chartService.changeSelectedTab(symbol);  
            } 
        }

        if (location.pathname.toLowerCase() === appConfig.pages.externalLogin.path) {
            if (location.search) {
                services.injectDependencies({
                    dispatch: props.dispatch,
                    storeManager: storeManager,
                });

                const params = new URLSearchParams(location.search);
                const user = params.get('user') as string;

                localStorage.setItem('trademaster.user', user);
            }
        }
    }, [])

    React.useEffect(() => {
        services.injectDependencies({
            dispatch: props.dispatch,
            storeManager: storeManager,
        });

        appService.onInit();
        appService.language.configure(useLanguage);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dispatch])


    const pageLoader = pageLoaderHelper.check()
    if (pageLoader) {
        return pageLoader
    }

    return (
        <div className="app">
            <LocalizationContext.Provider value={useLanguage}>
                <ToastContainer theme={"dark"} position={'top-center'} autoClose={1000} />

                <Socket />
                <Modal />
                <Authorization />
                {props.location && <Router />}
            </LocalizationContext.Provider>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    location: state.app.location,
    config: state.app.config,
    loginInformations: state.account.loginInformations,
})

export default connect(mapStateToProps)(App);