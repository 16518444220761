import { configureStore } from "@reduxjs/toolkit";
// Slices
import appSlice from "./slices/app-slice";
import modalSlice from "./slices/modal-slice";
import orderSlice from "./slices/order-slice";
import chartSlice from "./slices/chart-slice";
import symbolSlice from "./slices/symbol-slice";
import accountSlice from "./slices/account-slice";
import indicatorSlice from "./slices/indicator-slice";
// Types
import { RootReducer } from "./types";
import alarmSlice from "./slices/alarm-slice";

// Store reducers
const rootReducer: RootReducer = {
    [appSlice.name]: appSlice.reducer,
    [modalSlice.name]: modalSlice.reducer,
    [orderSlice.name]: orderSlice.reducer,
    [chartSlice.name]: chartSlice.reducer,
    [symbolSlice.name]: symbolSlice.reducer,
    [accountSlice.name]: accountSlice.reducer,
    [indicatorSlice.name]: indicatorSlice.reducer,
    [alarmSlice.name]: alarmSlice.reducer
}

// Context
const storeContext = configureStore({
    reducer: rootReducer,
    middleware(getDefaultMiddleware) {
        return getDefaultMiddleware({ thunk: true, serializableCheck: false })
    },
    devTools: true
})

export default storeContext;