import React from "react";
import { appService } from "services";
import storeManager from "store/manager";
import LocalizationContext from "./context";

const useTranslation = () => {
    const useLanguage = React.useContext(LocalizationContext);
    const [language, setLanguage] =  useLanguage || [null, () => null];

    const changeLanguage = (language: string) => {
        appService.language.setAsync(language)
        .finally(() => {
            setLanguage(language);
        });
    }; 
    
    const t = (key: string, ...args:any) => {
        const translation = storeManager.app.getConfig().data?.localization.Broker || {};

        let translatedText = translation[key] || '[' + key + ']';

        translatedText = translatedText.replace(/{(\d+)}/g, (match: any, number: any) => (

            typeof args[number] !== 'undefined'
                ? args[number]
                : match
        ));

        return translatedText;
    }; 
    return { t, language, changeLanguage };
}

export default useTranslation;


/*
 *
 const T = text => (ReactHtmlParser(File[text] || SetLanguageFile(text)));

const TranslateFormat = (text, ...args) => {
  const traslatedText = T(text)[0];

  const formatText = traslatedText.replace(/{(\d+)}/g, (match, number) => (
    typeof args[0][number] !== 'undefined'
      ? args[0][number]
      : match
  ));

  return formatText;
};

const TF = (text, ...args) => (TranslateFormat(text, args));

 * 
 * */