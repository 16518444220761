import React, { useEffect, useState } from 'react';
import useTranslation from 'modules/localization';
import Api from 'api';
import storeManager from 'store/manager';
import { appConfig } from 'app/config';

const ForgotPasswordForm = () => {

    const { t, changeLanguage } = useTranslation();

    const [inProgress, setInProgress] = useState(false);
    const [mailSent, setMailSent] = useState(false);

    const [validation, setValidation] = useState('');

    const [input, setInput] = useState(new Api.SendPasswordResetCodeInput({
        emailAddress: '',
    }) as any);

    const _accountServiceProxy = new Api.AccountServiceProxy();

    useEffect(() => {

        validateEmail(input.emailAddress);

    }, [input])

    const validateEmail = (emailAddress: string): boolean => {

        let newMessage = '';

        if (emailAddress === '') {
            newMessage = t('ValidationRequired', t('Email'));
        }
        else if (!/\S+@\S+\.\S+/.test(emailAddress)) {
            newMessage = t('ValidationValid', t('Email'));
        }
        else if (emailAddress.length > 256) {
            newMessage = t('ValidationMaxLength', t('Email'), 256);
        }

        setValidation(newMessage);

        return newMessage === '';
    }

    /** Description: Handles the form's "onSubmit" event. */
    const handleSubmitClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();

        let validateLoginResult: boolean = validateEmail(input.emailAddress);
        if (!validateLoginResult) { return; }

        setInProgress(true);
        setMailSent(false);

        _accountServiceProxy.sendPasswordResetCode(input)
            .then(() => {
                setMailSent(true);
                //TODO: Message changed
            })
            .catch((_error: any) => {
                console.log(_error);
            })
            .finally(() => {
                setInProgress(false);
            });
    }

    const handleCloseClick = () => {
        storeManager.app.setLocation(appConfig.pages.login.path);
    }

    return (
        <div className="forgot-password-form">
            <h2 className="tm-title">{t('ForgotPassword')}</h2>
            <form>
                <div className="description" >{t('SendPasswordResetLink_Information')}</div>

                <div className={`form-group${validation ? " has-validation" : ""}`}>
                    <label htmlFor="email">{t('EmailAddress')}</label>
                    <div className="textbox">
                        <div className="input-group">
                            <input type="text" id="email" value={input.emailAddress} onChange={(e) => { setInput({ ...input, emailAddress: e.currentTarget.value.trim() }); }} name="emailAddress" />
                            <i className="icofont-email"></i>
                        </div>
                        {validation && <div className="invalid-tooltip">
                            {validation}
                        </div>}
                    </div>
                </div>

                <div className="description" hidden={!mailSent}>{t('PasswordResetMailSentMessage')}</div>


                <button type="submit" className="submit" onClick={handleSubmitClick}>{!inProgress ? t('Submit') : t('Loading')}</button>
                <button type="button" className="submit btn-outline" onClick={handleCloseClick}><i className="icofont-double-left"></i>{t('Close')}</button>
            </form>
            <div className="language-selector">
                <div className="language" onClick={() => changeLanguage('en')}>
                    <span>English</span>
                    <i className="flag-icon flag-icon-gb"></i>
                </div>
                |
                <div className="language" onClick={() => changeLanguage('de')}>
                    <i className="flag-icon flag-icon-de"></i>
                    <span>Deutsch</span>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordForm;