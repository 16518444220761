import storageManager from 'modules/storage-manager';
import React from 'react';

interface Props {
    id?: string | number,
    title: string | JSX.Element,
    icon: string,
    children?: JSX.Element | JSX.Element[],
    isExpandable?: boolean,
}

const AccordionListItem = (props: Props) => {
    const [contentVisibility, setContentVisibility] = React.useState(false);

    const changeVisibility = () => {
        setContentVisibility(!contentVisibility);    

        // update the local storage
        try {
            if (props.id) {
                const storageData = storageManager.preferences.get()?.accordionList || {};
                storageManager.preferences.update({ 
                    accordionList: {
                        ...storageData,
                        [props.id]: !contentVisibility
                    }
                })
            }
            
        } catch (error) {}
    }

    React.useEffect(() => {
        if (props.id) {
            // set state with local storage value
            try {
                const accordionList = storageManager.preferences.get()?.accordionList;
                const storageContentVisibility = accordionList?.hasOwnProperty(props.id)? accordionList[props.id] : undefined;
                const changeVisibility = storageContentVisibility !== undefined? storageContentVisibility : true;

                setContentVisibility(changeVisibility);    

            } catch (error) {}

        } else {
            setContentVisibility(true); // default is true
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <li>
            <div className="head" onMouseDown={changeVisibility}>
                { props.title }
                { props.isExpandable && <i className={ contentVisibility? 'icofont-bubble-up' : 'icofont-bubble-down'} /> }
            </div>
            { 
                props.isExpandable && 
                    <div className={'body' + (contentVisibility? ' active': '')}>
                        <div>
                            <i className="icofont-listine-dots"></i>
                            {/* <i className="icofont-chart-bar-graph"></i> */}
                        </div>
                        <div>
                            { props.children }
                        </div>
                    </div>
            }
        </li>
    )
}

AccordionListItem.defaultProps = {
    isExpandable: true
} as Props

export default AccordionListItem;