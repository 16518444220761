import moment from "moment";
import { OrderHistoryPeriods } from "./constants";

class OrderHistoryHelper {
    getMomentDateValue = (date: moment.Moment) => {
        return date.format("YYYY-MM-DDTHH:mm:ss.SSS");
    }

    getCurrentEndDate = () => {
        const currentDate = new Date();
        let momentDate = moment(currentDate);

        momentDate = momentDate.set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999
        })

        return {
            date: momentDate.toDate(),
            value: this.getMomentDateValue(momentDate),
        }
    }

    // <Period>
    getLastYear = () => {
        let momentDate = moment().subtract(1, 'years');
        momentDate = momentDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })

        return {
            startDate: {
                date: momentDate.toDate(),
                value: this.getMomentDateValue(momentDate)
            },
            endDate: this.getCurrentEndDate()
        }
    }

    getLastMonth = () => {
        let momentDate = moment().subtract(1, 'months');
        momentDate = momentDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })

        return {
            startDate: {
                date: momentDate.toDate(),
                value: this.getMomentDateValue(momentDate)
            },
            endDate: this.getCurrentEndDate()
        }
    }

    getLastWeek = () => {
        let momentDate = moment().subtract(1, 'weeks');
        momentDate = momentDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })

        return {
            startDate: {
                date: momentDate.toDate(),
                value: this.getMomentDateValue(momentDate)
            },
            endDate: this.getCurrentEndDate()
        }

    }

    getLastThreeDays = () => {
        let momentDate = moment().subtract(3, 'days');
        momentDate = momentDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })

        return {
            startDate: {
                date: momentDate.toDate(),
                value: this.getMomentDateValue(momentDate)
            },
            endDate: this.getCurrentEndDate()
        }

    }

    getToday = () => {
        let momentDate = moment();
        momentDate = momentDate.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
        })

        return {
            startDate: {
                date: momentDate.toDate(),
                value: this.getMomentDateValue(momentDate)
            },
            endDate: this.getCurrentEndDate()
        }
    }
    // </Period>

    getPeriodDate = (period: OrderHistoryPeriods) => {
        switch (period) {
            case OrderHistoryPeriods.ALL_HISTORY:
                return this.getLastYear();
        
            case OrderHistoryPeriods.LAST_MONTH:
                return this.getLastMonth();
        
            case OrderHistoryPeriods.LAST_WEEK:
                return this.getLastWeek();
        
            case OrderHistoryPeriods.LAST_THREE_DAY:
                return this.getLastThreeDays();
        
            case OrderHistoryPeriods.TODAY:
                return this.getToday();
        
            default:
                return null;
        }
    }
}

const orderHistorHelper = new OrderHistoryHelper();
export default orderHistorHelper;