// Hubs
import FeedHub from "./hubs/feed-hub";
import TradeMasterHub from "./hubs/trademaster-hub";
import IndicatorHub from "./hubs/indicator-hub";
// Types
import { IFeedHub } from "./hubs/feed-hub/types";
import { ITradeMasterHub } from "./hubs/trademaster-hub/types";
import { IIndicatorHub } from "./hubs/indicator-hub/types";

class Socket {
    public feed!: IFeedHub;
    public tradeMaster!: ITradeMasterHub;
    public indicator!: IIndicatorHub;

    createFeedSocket() {
        if (this.feed === undefined) {
            this.feed = new FeedHub();
        }
    }

    createTradeMasterSocket() {
        if (this.tradeMaster === undefined) {
            this.tradeMaster = new TradeMasterHub();
        }
    }

    createIndicatorSocket() {
        if (this.indicator === undefined) {
            this.indicator = new IndicatorHub();
        }
    }
}

const socket = new Socket();
export default socket;