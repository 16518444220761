const isEnumMember = (type: Object, suspected: any) => {
    const enumValues: any = {...type};
    
    Object.keys(enumValues).forEach((typeKey) => {
        const value = enumValues[typeKey];

        if (!enumValues.hasOwnProperty(value)) {
            enumValues[value] = typeKey;
        }
    })

    return enumValues.hasOwnProperty(suspected); 
}

export default isEnumMember;