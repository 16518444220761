import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import useTranslation from 'modules/localization';

interface Props {
    modalData: any
}

const AutomaticSystemForwardingModal = (props: Props) => {
    const handleClose = () => storeManager.modal.setAutomaticSystemForwarding({ visibility: false, data: null });
    const openInformation = () => window.open(props.modalData.moreInfo, '_blank');
    const { t } = useTranslation();

    if (!props.modalData) {
        return null;
    }
    
    return (
        <Modal title="Strategies - Forwarding" id="automatic-system-forwarding-modal" close={handleClose}>
            <React.Fragment>
                {
                    props.modalData.forwardingWindow?.map((text: string) => {
                        return (
                            <div className="list-item-text">{text}</div>
                        )
                    })
                }
                <button className="more-information" onClick={openInformation}>{t('MoreInformation')}</button>
            </React.Fragment>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    modalData: state.modal.automaticSystemForwarding.data,
})

export default connect(mapStateToProps)(AutomaticSystemForwardingModal);