import React from 'react';
import storeManager from 'store/manager';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { RootState } from 'store/types';
import { appConfig } from 'app/config';
import getToken from 'shared/utils/getToken';
import authorizationHelper from 'helpers/authorization-helper';
import getAccountNo from 'shared/utils/getAccountNo';

const Authorization = () => {
    const appLocation = useSelector((state: RootState) => state.app.location);
    const setLocation = storeManager.app.setLocation;
    const location = useLocation().pathname;
    const navigate = useNavigate();
    const permissions: string[] = useSelector((state: RootState) => state.app.config.data?.permissions);

    React.useEffect(() => {
        /** Description: Validates the user permissions */
        const validate = (path: string) => {
            const result: { isValid: boolean, path: string | null } = { isValid: false, path: null };
            const page = Object.values(appConfig.pages).find((page) => page.path === path);

            if (page) { // is page exist?
                if (page.permission) { // is required permission for the page?
                    const checkTokenExpiration: boolean = authorizationHelper.checkTokenExpiration(); // 
                    
                    const isGranted = permissions.findIndex((_permission) => _permission === page?.permission) !== -1;
                    if (isGranted) {
                        const accountNo = getAccountNo();
                        // if account no is nout found, redirect to trading account page
                        if (!accountNo && (appLocation !== appConfig.pages.tradingAccounts.path && location !== appConfig.pages.tradingAccounts.path)) {
                            result.isValid = false;
                            result.path = appConfig.pages.tradingAccounts.path;

                        } else {
                            // else, keep going
                            result.isValid = true;
                            result.path = path;
                        }

                        
                    } else {
                        // there is required permission but user has not 
                        result.path = appConfig.pages.login.path;
                    }

                } else { // if page permission is null
                    const token = getToken();
                    if (token) { // if user has been logged in
                        result.path = appConfig.pages.trader.path;

                    } else {
                        result.isValid = true;
                        result.path = path;

                    }

                }
            } 

            return result;
        }

        /** Description: Manages the path validation relations. */
        const authorize = () => {
            if (appLocation === null) { // first viewing
                const validation = validate(location);
                if (validation.isValid) {
                    setLocation(location);   // TODO setState in UseEffet
                    
                } else { // if has not permission
                    navigate(validation.path as string);
                }
                
            } else {
                if (appLocation !== location) {
                    const validation = validate(appLocation);
                    
                    if (validation.isValid) {
                        navigate(validation.path as string);  
                        
                    } else { // if has not permission
                        setLocation(validation.path as string); // TODO setState in UseEffet
                    }
                }
            }
        }
        
        authorize();

    }, [appLocation, location, navigate, permissions, setLocation])

    return null;
}

export default Authorization;