import RegisterFormWithRecaptcha from '../../../components/forms/register-form/register-form-with-recaptcha';

const RegisterContainer = () => {

    return (
        <div className="register-container">
            <RegisterFormWithRecaptcha />
        </div>
    )
}

export default RegisterContainer;