import React from 'react';
import { connect } from 'react-redux';
import exposureToolHelper from './helper';
import ExposureToolTableToolBox from './toolbox';
// Types
import { RootState } from 'store/types';
import { ExposureToolData, ExposureToolTableProps } from './types';
import useTranslation from 'modules/localization';

const ExposureToolTable = (props: ExposureToolTableProps) => {
    const [data, setData] = React.useState<{[property: string]: ExposureToolData}>({});
    const { t } = useTranslation();
    const dataSymbols = Object.values(data).map((order: ExposureToolData) => order.symbol);

    React.useEffect(() => {
        const exposureData = exposureToolHelper.getData(props.openOrders);
        setData(exposureData);

    }, [props.openOrders])

    return (
        <div className="exposure-tool-table">
            <ExposureToolTableToolBox symbols={dataSymbols} />
            <table>
                <thead>
                    <tr>
                        <th>{t('Exposure')}</th>
                        <th colSpan={4} className="diff-color">{t('BuyOrders')}</th>
                        <th colSpan={4}>{t('SellOrders')}</th>
                        <th colSpan={2} className="diff-color">{t('TotalOrders')}</th>
                    </tr>
                    <tr>
                        <th>{t('Symbol')}</th>
                        <th className="diff-color">{t('Deals')}</th>
                        <th className="diff-color">{t('Volume')}</th>
                        <th className="diff-color">{t('Price')}</th>
                        <th className="diff-color">{t('ProfitAndLoss')}</th>
                        <th>{t('Deals')}</th>
                        <th>{t('Volume')}</th>
                        <th>{t('Price')}</th>
                        <th>{t('ProfitAndLoss')}</th>
                        <th className="diff-color">{t('Deals')}</th>
                        <th className="diff-color">{t('ProfitAndLoss')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.values(data).map((order) => {
                            const { symbol, digit} = order;                            
                            const buyDeals = order?.buyDeals || 0; 
                            const buyLots = order?.buyLots?.toFixed(2) || 0; 
                            const buyPrice = order?.buyPrice?.toFixed(digit) || 0; 
                            const buyProfit = order?.buyProfit?.toFixed(2) || 0; 
                            const sellDeals = order?.sellDeals || 0; 
                            const sellLots = order?.sellLots?.toFixed(2) || 0; 
                            const sellPrice = order?.sellPrice?.toFixed(digit) || 0; 
                            const sellProfit = order?.sellProfit?.toFixed(2) || 0;

                            const totalDeals = buyDeals + sellDeals;
                            const totalProfit = ((order.buyProfit || 0) + (order.buyProfit || 0)).toFixed(2);

                            return (
                                <tr key={order.symbol}>
                                    <td>{symbol}</td>
                                    <td className="diff-color">{buyDeals}</td>
                                    <td className="diff-color">{buyLots}</td>
                                    <td className="diff-color">{buyPrice}</td>
                                    <td className="diff-color">{buyProfit}</td>
                                    <td>{sellDeals}</td>
                                    <td>{sellLots}</td>
                                    <td>{sellPrice}</td>
                                    <td>{sellProfit}</td>
                                    <td className="diff-color">{totalDeals}</td>
                                    <td className="diff-color">{totalProfit}</td>
                                </tr>
                            )
                        })
                    }
                    
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    openOrders: state.order.open,
})

export default connect(mapStateToProps)(ExposureToolTable);