import ChartContent from './content';

const StandaloneChart = () => {
    return (
        <div className="chart">
            <ChartContent />
        </div>
    )
}

export default StandaloneChart;