import { createSlice } from "@reduxjs/toolkit";
// State
import alarmState from "./state";
// Types
import { AlarmReducers } from "./types";

const alarmSlice = createSlice({
    name: 'alarm',
    initialState: alarmState,
    reducers: {
        setData(state, action) {
            state.data = action.payload;
        }
    } as AlarmReducers
})

export default alarmSlice;