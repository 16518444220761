import { Slice } from "@reduxjs/toolkit";
import CameltoPascalCase from "shared/utils/toPascalCase";
// Types
import { Store } from "store/types";

type Getters<Type> = {
    [Property in keyof Type as `get${Capitalize<string & Property>}`]: () => Type[Property]
};

class StoreManagerHelper {
    private readonly _store: Store;
    
    constructor(store: Store) {
        this._store = store;
    }

    // Description: Creates a slice dispatcher, provides to change the store. Example usage: storeManager.app.setLanguage('TR'); 
    public createDispatcher(actions: any) {
        const dispatch: any = this._store.dispatch;
        const dispatchers: any = {};

        if (Object.keys(actions as any).length) {
            Object.entries(actions as any).forEach((actionEntries) => {
                const [actionKey, action]: [string, any] = actionEntries;
                
                dispatchers[actionKey] = (value: any) => dispatch(action(value));
            })
        }

        return dispatchers;
    }

    private createSelector<S>(state: any, name: string) {
        const selectors: any = {};
        const stateKeys = Object.keys(state);
        const getStoreState: any = () => this._store.getState();
        
        stateKeys.forEach((stateKey) => {
            const actionKey = 'get' + CameltoPascalCase(stateKey);
            selectors[actionKey] = () => getStoreState()[name][stateKey];
        })

        return selectors as Getters<S>;
    }

    public createManager = <State, Actions>(slice: Slice<State>) => {
        const dispatcher: Actions = this.createDispatcher(slice.actions) as any;
        const selector = this.createSelector<State>(slice.getInitialState(), slice.name);

        return {
            ...dispatcher,
            ...selector,
        };
    }
}

export default StoreManagerHelper;