import useTranslation from 'modules/localization';
import React from 'react';
import { connect } from 'react-redux';
import { indicatorService } from 'services';
import { SymbolAnalysis, SymbolWatchList } from 'store/slices/symbol-slice/types';
import { RootState } from 'store/types';
import AccordionListItem from '../src/item';

interface Props {
    analysis: SymbolAnalysis,
    watchList: SymbolWatchList,
}

const SymbolSelectorItem = (props: Props) => {
    const [listVisibility, setListVisibility] = React.useState(false);
    const { t } = useTranslation(); 

    const changeAnalysisSymbol = (symbol: string) => {
        setListVisibility(false);
        indicatorService.setAnalysis(symbol);
    }

    const title = (
        <React.Fragment>
            <span>{t('AnalyzesSymbolTitle')}</span>
            <div className="symbol-selector">
                <div className="analysis-symbol" onClick={() => setListVisibility(!listVisibility)}>
                    <span>{props.analysis}</span> 
                    <i className="icofont-rounded-down"></i> 
                </div>
                <div style={{ display: listVisibility ? 'block' : 'none' }} className="symbol-list">
                    {
                        props.watchList.map((symbol) => {
                            return (
                                <div key={symbol} onClick={() => changeAnalysisSymbol(symbol)}>{symbol}</div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    );
    
    return (
        <AccordionListItem title={title} isExpandable={false} />
    )
}

const mapStateToProps = (state: RootState) => ({
    analysis: state.symbol.analysis,
    watchList: state.symbol.watchList,
})

export default connect(mapStateToProps)(SymbolSelectorItem);