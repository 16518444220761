import Api from "api"

export const exposureToolSymbolType = {
    NONE: { TEXT: 'SelectSymbol', VALUE: null },
    ALL: { TEXT: 'AllSymbols', VALUE: 'ALL' }
}

export const exposureToolCloseType = {
    NONE: { TEXT: 'SelectCloseType', VALUE: null },
    CLOSE_ALL_ORDERS: { TEXT: 'CloseAllOrders', VALUE: Api.EnCloseType.CloseAllOrder},
    CLOSE_ALL_BUY_ORDERS: { TEXT: 'CloseAllBuyOrders', VALUE: Api.EnCloseType.CloseAllBuyOrder },
    CLOSE_ALL_SELL_ORDERS: { TEXT: 'CloseAllSellOrders', VALUE: Api.EnCloseType.CloseAllSellOrder },
    CLOSE_ALL_POSITIVE_ORDERS: { TEXT: 'CloseAllPositiveOrders', VALUE: Api.EnCloseType.CloseAllPositiveOrder },
    CLOSE_ALL_NEGATIVE_ORDERS: { TEXT: 'CloseAllNegativeOrders', VALUE: Api.EnCloseType.CloseAllNegativeOrder }
}
