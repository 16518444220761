import React from 'react';
import Modal from 'components/modal';
import storeManager from 'store/manager';
import ReactDatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { RootState } from 'store/types';
import { alarmService } from 'services';
import useTranslation from 'modules/localization';
import getSymbolDigit from 'shared/utils/getSymbolDigit';

interface Props {
    alarmData: any,
}

const AlarmModifyModal = (props: Props) => {
    const { t } = useTranslation();

    // tpdo - should be moved to shared folder
    const inputKeys = {
        NAME: 'alarmName',
        DESCRIPTION: 'description',
        PRICE: 'price',
        CONDITION: 'condition',
        EXPIRATION: 'expiration',
        MAX_ITERATIONS: 'maxIterations',
    }

    const symbol = props.alarmData?.['symbol']; 
    const digit = getSymbolDigit(symbol);
    const defaultName =  props.alarmData?.[inputKeys.NAME] || `${symbol} Alarm`; 
    const defaultPrice =  props.alarmData?.[inputKeys.PRICE]; 
    const defaultCondition =  props.alarmData?.[inputKeys.CONDITION] || 0; 
    const defaultExpiration =  new Date(props.alarmData?.[inputKeys.EXPIRATION]); 
    const defaultDescription =  props.alarmData?.[inputKeys.DESCRIPTION] || 'No Description'; 
    
    const [name, setName] = React.useState(defaultName);
    const [price, setPrice] = React.useState(parseFloat(defaultPrice).toFixed(digit));
    const [condition, setCondition] = React.useState(defaultCondition);
    const [expiration, setExpiration] = React.useState<any>(defaultExpiration);
    const [description, setDescription] = React.useState(defaultDescription);
    const [serviceAvailable, setServiceAvailable] = React.useState(true);

    const handleClose = () => storeManager.modal.setAlarmModify({ visibility: false, data: null });

    const handleSubmit = () => {
        const modalData = storeManager.modal.getAlarmModify();
        
        storeManager.modal.setAlarmModify({
            visibility: modalData.visibility,
            data: {
                ...modalData.data,
                alarmName: name,
                description: description,
                price: price,
                condition: condition,
                expiration: expiration,
            }
        })

        if (!serviceAvailable) 
            return;

        setServiceAvailable(false);
        alarmService.edit()
            .finally(() => setServiceAvailable(true));
    }

    return (
        <Modal title="Modify Alarm" id="modify-alarm-modal" close={handleClose}>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="symbol">
                    <span>{symbol}</span>
                </div>
                
                <div>
                    <label htmlFor="alarm-modify-name">{t('Name')}</label>
                    <input type="text" id="alarm-modify-name" step="0.01" min="0.01" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                </div>
                <div>
                    <label htmlFor="alarm-modify-price">{t('Price')}</label>
                    <input type="number" id="alarm-modify-price" step="0.01" value={price} onChange={(e) => setPrice(e.currentTarget.value)} />
                </div>
                <div>
                    <label htmlFor="alarm-modify-condition">{t('Condition')}</label>
                    <select id="alarm-modify-condition" value={condition} onChange={(e) => setCondition(e.currentTarget.value)} >
                        <option value="0">{t('BidHigherThan')}</option>
                        <option value="1">{t('BidLowerThan')}</option>
                        <option value="2">{t('AskHigherThan')}</option>
                        <option value="3">{t('AskLowerThan')}</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="alarm-modify-expiration">{t('Expiration')}</label>
                    <div>
                        <ReactDatePicker id="alarm-modify-expiration" selected={expiration} onChange={(date) => setExpiration(date)} />
                    </div>
                </div>
                <div>
                    <label htmlFor="alarm-modify-description">{t('Description')}</label>
                    <input type="text" id="alarm-modify-description" value={description} onChange={(e) => setDescription(e.currentTarget.value)} />
                </div>

                <button type="submit" className="submit-button" onClick={handleSubmit}>
                    {
                        serviceAvailable? 
                            t('Modify') : <React.Fragment>{t('Loading')} <i className="icofont-spinner"></i></React.Fragment>
                    }
                </button>
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: RootState) => ({
    alarmData: state.modal.alarmModify.data
})

export default connect(mapStateToProps)(AlarmModifyModal);